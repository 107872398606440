import { Button, ButtonProps } from '@mantine/core';
import { forwardRef, MouseEventHandler } from 'react';

import { Permission } from '@/core/types/user-permissions.types';

import { PermissionOverlay } from '../permission-overlay/permission-overlay';

export interface PermissionedButtonProps extends ButtonProps {
  isLoading?: boolean;
  permissions?: Permission;
  hideOnDisabled?: boolean;
  // TODO: Unsure why this is not flowing through from ButtonProps
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const PermissionedButton = forwardRef(
  (
    { hideOnDisabled, permissions, onClick, ...props }: PermissionedButtonProps,
    ref?: React.Ref<HTMLButtonElement>
  ) => {
    // Computed
    const isDisabled = permissions && !permissions.allowed;

    // Render
    if (hideOnDisabled && isDisabled) {
      return null;
    }

    if (isDisabled) {
      return (
        <PermissionOverlay
          allowed={permissions.allowed}
          message={permissions.message}
        >
          <Button
            {...props}
            disabled
            className='cursor-pointer'
            ref={ref}
            onClick={undefined}
          />
        </PermissionOverlay>
      );
    }

    return <Button {...props} ref={ref} onClick={onClick} />;
  }
);
