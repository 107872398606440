import { Modals } from '@/core/hooks/use-modals/use-modals';
import ObserveExportModal from '@/observe/modals/observe-export-modal/observe-export-modal';
import ObserveHelpModal from '@/observe/modals/observe-help-modal/observe-help-modal';

import BulkHumanFeedbackModal from './bulk-human-feedback-modal/bulk-human-feedback-modal';

export const modals = {
  [Modals.OBSERVE_BULK_HUMAN_FEEDBACK]: BulkHumanFeedbackModal,
  [Modals.OBSERVE_EXPORT]: ObserveExportModal,
  [Modals.OBSERVE_HELP]: ObserveHelpModal
};
