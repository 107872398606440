import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

export const GET_SPLITS_KEY = '/projects/{project_id}/runs/{run_id}/splits';

export const useSplits = () => {
  const { runId, projectId } = usePathParameters();
  const comparedTo = useParametersStore((state) => state.comparedTo);

  const enabled = Boolean(projectId && runId);

  const fetchSplits = async () => {
    const res = await api.GET(GET_SPLITS_KEY, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string
        }
      }
    });

    return res?.data;
  };

  const response = useQuery({
    queryKey: [GET_SPLITS_KEY, { projectId, runId }],
    queryFn: fetchSplits,
    enabled
  });

  return {
    ...response,
    withDrifted: comparedTo && response?.data?.splits?.includes('training'),
    comparisonSplits: response?.data?.splits?.includes('training')
      ? [
          { label: 'None', value: '' },
          { label: 'Training', value: 'training' }
        ]
      : [{ label: 'None', value: '' }]
  };
};
