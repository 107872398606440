import { ActionIcon, Group, Loader } from '@mantine/core';
import {
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled
} from '@tabler/icons-react';

import { Permission } from '@/core/types/user-permissions.types';

interface ThumbRaterProps {
  isLoading?: boolean;
  readOnly?: boolean;
  permissions?: Permission;
  onChange: (rating: boolean) => void;
  value: true | false | undefined;
  size?: number;
}

export const ThumbRater = ({
  isLoading,
  permissions,
  onChange,
  size,
  value
}: ThumbRaterProps) => {
  // Computed
  const isThumbsUp = value === true;
  const isThumbsDown = value === false;

  const thumbsUp = isThumbsUp ? (
    <IconThumbUpFilled size={size} />
  ) : (
    <IconThumbUp size={size} />
  );
  const thumbsDown = isThumbsDown ? (
    <IconThumbDownFilled size={size} />
  ) : (
    <IconThumbDown size={size} />
  );

  if (isLoading) {
    return <Loader data-testid='thumb-rater-loader' type='dots' />;
  }

  return (
    <Group data-testid='thumb-rater' gap={2}>
      <ActionIcon
        c='gray.6'
        className='cursor-pointer'
        disabled={!permissions?.allowed}
        size={size}
        variant='subtle'
        onClick={(e) => {
          e.stopPropagation();
          onChange(true);
        }}
      >
        {thumbsUp}
      </ActionIcon>
      <ActionIcon
        c={isThumbsDown ? 'red.6' : 'gray.6'}
        className='cursor-pointer'
        disabled={!permissions?.allowed}
        size={size}
        variant='subtle'
        onClick={(e) => {
          e.stopPropagation();
          onChange(false);
        }}
      >
        {thumbsDown}
      </ActionIcon>
    </Group>
  );
};
