import { Checkbox, Group, Text, Tooltip } from '@mantine/core';
import React, { useEffect, useState } from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import MetricsGroupLabel from '@/core/components/atoms/metrics-group-label/metrics-group-label';
import ListCard, {
  ListCardProps
} from '@/core/components/molecules/list-card/list-card';
import { ModalLauncher } from '@/core/components/molecules/modal-launcher/modal-launcher';
import { useProjectPermissions } from '@/core/hooks/use-permissions/use-project-permissions';
import { ConfigureRatingsModal } from '@/core/modals/configure-ratings-modal/configure-ratings-modal';
import { FeedbackType } from '@/core/types/human-feedback.types';

import HumanFeedbackExplanation from '../human-feedback-explanation/human-feedback-explanation';
import HumanFeedbackInput, {
  HumanFeedbackInputProps
} from '../human-feedback-input/human-feedback-input';
import HumanFeedbackLabel from '../human-feedback-label/human-feedback-label';

interface BulkHumanFeedbackCardProps extends Omit<ListCardProps, 'children'> {
  checkedFeedbackIds: string[];
  feedbackMetrics: FeedbackTemplate<FeedbackType>[];
  feedbackMetricIdsWithMultipleValues: string[];
  onChange: (
    metric: FeedbackTemplate<FeedbackType>,
    metrics: FeedbackTemplate<FeedbackType>[]
  ) => void;
  onCheck: (metricId: string | undefined, checked: boolean) => void;
  canConfigureTemplates?: boolean;
}

export const BulkHumanFeedbackCard = ({
  checkedFeedbackIds,
  feedbackMetrics = [],
  feedbackMetricIdsWithMultipleValues,
  onChange,
  onCheck,
  canConfigureTemplates = true,
  ...props
}: BulkHumanFeedbackCardProps) => {
  // Local State
  const [cachedMetrics, setCachedMetrics] =
    useState<FeedbackTemplate<FeedbackType>[]>(feedbackMetrics);

  // Permissions
  const { currentProjectPermissions } = useProjectPermissions();
  const { configure_human_feedback, record_human_feedback } =
    currentProjectPermissions;

  // Handlers
  const handleRatingChange = (metric: FeedbackTemplate<FeedbackType>) => {
    const index = cachedMetrics.findIndex((m) => m.name === metric.name);
    const newMetrics = [...cachedMetrics];
    newMetrics[index] = metric;
    setCachedMetrics(newMetrics);
    onChange(metric, newMetrics);
  };

  // Effects
  useEffect(() => {
    setCachedMetrics(feedbackMetrics);
  }, [feedbackMetrics]);

  const title = canConfigureTemplates ? (
    <Group justify='space-between'>
      <MetricsGroupLabel groupId='human_ratings' />
      <ModalLauncher
        color='brand.5'
        modal={ConfigureRatingsModal}
        p={0}
        permissions={configure_human_feedback}
        size='compact-sm'
        style={{ fontWeight: 600 }}
        variant='transparent'
      >
        Configure
      </ModalLauncher>
    </Group>
  ) : null;
  return (
    <ListCard title={title} {...props}>
      {cachedMetrics.map((metric) => {
        return (
          <BulkRatingCardItem
            hasMultipleValues={
              metric.id != null
                ? feedbackMetricIdsWithMultipleValues.includes(metric.id)
                : false
            }
            isChecked={
              metric.id != null ? checkedFeedbackIds.includes(metric.id) : false
            }
            key={`${metric.getKey()}-${metric.id}`}
            metric={metric}
            permissions={record_human_feedback}
            onChange={handleRatingChange}
            onCheck={onCheck}
          />
        );
      })}
    </ListCard>
  );
};

interface BulkRatingCardItemProps extends HumanFeedbackInputProps {
  isChecked: boolean;
  hasMultipleValues: boolean;
  onCheck: (metricId: string | undefined, checked: boolean) => void;
}

const BulkRatingCardItem: React.FC<BulkRatingCardItemProps> = ({
  isChecked,
  metric,
  hasMultipleValues,
  permissions,
  onChange,
  onCheck
}) => {
  // Handlers
  const handleToggleChecked = (checked: boolean) => {
    if (!checked) {
      metric.resetToInitialValues();
      onChange(metric);
    }

    onCheck(metric.id, checked);
  };

  const isIndeterminate =
    isChecked && !hasMultipleValues && !metric.isModified();
  const isDeleting = isChecked && !isIndeterminate && metric.value == null;
  const showMultipleLabel = !isChecked && hasMultipleValues;

  let tooltip = 'Annotation will not be changed';
  let color;
  if (isIndeterminate) {
    tooltip = 'No changes detected.';
    color = 'gray.5';
  } else if (isDeleting) {
    tooltip = 'Annotation will be deleted. Click to undo';
    color = 'red.5';
  } else if (isChecked) {
    tooltip = 'Annotation will be added. Click to undo';
    color = 'green.5';
  }

  return (
    <ListCard.Item data-testid={`${metric?.constraints?.feedback_type}-item`}>
      <Group justify='space-between' w='100%'>
        <Group gap='sm'>
          <Tooltip label={tooltip}>
            <Checkbox
              checked={isChecked}
              color={color}
              indeterminate={isIndeterminate}
              variant={isIndeterminate ? 'outline' : undefined}
              onChange={() => handleToggleChecked(!isChecked)}
            />
          </Tooltip>
          <HumanFeedbackLabel metric={metric} />
        </Group>
        {showMultipleLabel ? (
          <Tooltip
            multiline
            label='Multiple values exist on the selected rows for this annotation. Click to edit.'
            w='200'
          >
            <Text
              c='gray.5'
              style={{ cursor: 'pointer' }}
              onClick={() => handleToggleChecked(true)}
            >
              Multiple
            </Text>
          </Tooltip>
        ) : (
          <HumanFeedbackInput
            metric={metric}
            permissions={permissions}
            onChange={onChange}
          />
        )}
      </Group>
      <HumanFeedbackExplanation
        boxProps={{ mt: 'sm' }}
        metric={metric}
        permissions={permissions}
        onChange={onChange}
      />
    </ListCard.Item>
  );
};
