import { Modals } from '@/core/hooks/use-modals/use-modals';

import BulkHumanFeedbackModal from './bulk-human-feedback-modal/bulk-human-feedback-modal';
import PromptExportModal from './prompt-export-modal/prompt-export-modal';
import TemplateCodeModal from './template-code-modal/template-code-modal';

export const modals = {
  [Modals.EVALUATE_BULK_HUMAN_FEEDBACK]: BulkHumanFeedbackModal,
  [Modals.PROMPT_EXPORT]: PromptExportModal,
  [Modals.TEMPLATE_CODE]: TemplateCodeModal
};
