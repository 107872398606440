import { Box, BoxProps, MultiSelect, TagsInput, Text } from '@mantine/core';
import { IconSelector } from '@tabler/icons-react';

import { Z_INDEX } from '@/core/constants/z-index.constants';
import { Permission } from '@/core/types/user-permissions.types';

interface TagsRaterProps extends BoxProps {
  canCreate?: boolean;
  permissions?: Permission;
  placeholder?: string;
  tags: string[];
  value?: string[];
  onChange?: (value: string[]) => void;
}
export const TagsRater = ({
  canCreate,
  permissions,
  tags,
  value,
  ...props
}: TagsRaterProps) => {
  const defaultPlaceholder = canCreate
    ? 'Select or add a new category'
    : 'Select a category';
  const placeholder = props?.placeholder ?? defaultPlaceholder;

  if (!permissions?.allowed) {
    return (
      <Box
        bg='gray.1'
        data-testid='tags-rater'
        p='xs'
        style={{ borderRadius: 6 }}
      >
        {(value == null || value.length === 0) && (
          <Text c='gray.6'>{placeholder}</Text>
        )}
        {value != null && value?.length > 0 && (
          <Text c='gray.6'>{value.join(', ')}</Text>
        )}
      </Box>
    );
  }

  if (canCreate) {
    return (
      <TagsInput
        data={tags ?? []}
        data-testid='tags-rater'
        placeholder={placeholder}
        rightSection={<IconSelector size='1rem' />}
        style={{ zIndex: Z_INDEX.TOOLTIPS }}
        styles={{ dropdown: { zIndex: Z_INDEX.TOOLTIPS } }}
        value={value ?? []}
        {...props}
      />
    );
  }

  // MultiSelect doesn't allow creating new values
  return (
    <MultiSelect
      data={tags ?? []}
      data-testid='tags-rater'
      placeholder={placeholder}
      style={{ zIndex: Z_INDEX.TOOLTIPS }}
      styles={{ dropdown: { zIndex: Z_INDEX.TOOLTIPS } }}
      value={value ?? []}
      {...props}
    />
  );
};
