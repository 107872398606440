import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';

// Path
export const GET_USER_BY_ID = '/users/{user_id}';

export const useUserById = (userId: string) => {
  const fetchUser = async () => {
    const res = await api.GET(GET_USER_BY_ID, {
      params: {
        path: {
          user_id: userId
        }
      }
    });

    return res.data;
  };

  const enabled = Boolean(userId);
  const queryKey = [GET_USER_BY_ID, { userId }];

  const response = useQuery({ queryKey, queryFn: fetchUser, enabled });

  return response;
};
