import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Group,
  Kbd,
  Select,
  Text,
  TextInput,
  ThemeIcon,
  Tooltip
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useListState } from '@mantine/hooks';
import { ContextModalProps } from '@mantine/modals';
import { IconCornerDownLeft, IconPlus, IconX } from '@tabler/icons-react';
import chroma from 'chroma-js';
import { useEffect, useState } from 'react';

import { LabelsStudioIcon } from '@/core/constants/brands.icons.constants';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import Button from '@/fine-tune/components/button/button';
import { useLabels } from '@/fine-tune/hooks/query-hooks/use-labels/use-labels';
import { useLabelstudioExport } from '@/fine-tune/hooks/query-hooks/use-labelstudio-export/use-labelstudio-export';
import { useLabelstudioProjects } from '@/fine-tune/hooks/query-hooks/use-labelstudio-projects/use-labelstudio-projects';
import useStore from '@/fine-tune/stores/store';

interface FormValues {
  project: string;
  labels: string[];
}

/**
 * SendToLabelerModal
 *
 *
 *
 * @returns {React.Component} SendToLabelerModal
 */

type Project = {
  value: string;
  label: string;
  isNew?: boolean;
};

const SendToLabelerModal = ({ id }: ContextModalProps) => {
  const labelstudioProjects = useLabelstudioProjects();
  const labelstudioExport = useLabelstudioExport();

  const { data: labelsData } = useLabels();
  const defaultLabelsNames = labelsData?.labels || [];

  const [projects, handlers] = useListState<Project>([]);
  const [addedLabelColorMap, setAddedLabelColorMap] = useState<
    Record<string, string>
  >({});

  const colorMap = useStore((s) => s.colorMap);

  const [isAddingLabels, setIsAddingLabel] = useState(false);

  const { closeModal } = useModals();

  useEffect(() => {
    if (labelstudioProjects.data?.length) {
      handlers.setState(
        labelstudioProjects.data?.map((p) => ({ label: p.name, value: p.name }))
      );
    }
  }, [labelstudioProjects.data]);

  const form = useForm({
    initialValues: {
      project: '',
      labels: labelsData?.labels || []
    }
  });

  const handleSendToLabeler = (values: FormValues) => {
    labelstudioExport.mutate(values, { onSuccess: () => closeModal(id) });
  };

  const handleRemoveLabel = (label: string) => {
    form.setFieldValue(
      'labels',
      form.values.labels.filter((l) => l !== label)
    );
  };

  const isNewProject = projects.find(
    (p) => p.value === form.values.project
  )?.isNew;

  return (
    <form
      data-testid='send-to-labeler-form'
      onSubmit={form.onSubmit(handleSendToLabeler)}
    >
      <Select
        searchable
        comboboxProps={{ withinPortal: false }}
        data={projects}
        label='Project'
        mb='xs'
        placeholder='Select a project'
        {...form.getInputProps('project')}
      />

      {isNewProject && (
        <>
          <Text fw={600} size='sm'>
            Template
          </Text>
          <Text mb='xs' size='xs'>
            Create or remove labels here with our UI or using HTML editor
          </Text>

          <Card withBorder mb='xs' padding='xs'>
            <Box>
              {form.values.labels?.map((label) => {
                const color =
                  colorMap[label]?.background || addedLabelColorMap[label];

                return (
                  <Badge
                    key={label}
                    m={4}
                    px='xs'
                    radius='sm'
                    // Only show the clear button for new labels
                    rightSection={
                      !defaultLabelsNames.includes(label) ? (
                        <ActionIcon
                          data-testid='label-clear-btn'
                          radius='xl'
                          size='xs'
                          style={{
                            color
                          }}
                          variant='transparent'
                          onClick={() => handleRemoveLabel(label)}
                        >
                          <IconX size={16} />
                        </ActionIcon>
                      ) : null
                    }
                    size='lg'
                    style={() => ({
                      borderColor: color,
                      color,
                      textTransform: 'none'
                    })}
                    variant='outline'
                  >
                    {label}
                  </Badge>
                );
              })}
            </Box>

            <Box mt='xs'>
              {isAddingLabels ? (
                <TextInput
                  autoFocus
                  description='Add semicolon delineated list of labels to send to labeler'
                  rightSection={
                    <Tooltip
                      withArrow
                      withinPortal
                      className='flex'
                      label='Click Enter to add labels'
                    >
                      <Kbd className='flex' mr={2} style={{ lineHeight: 1.25 }}>
                        <IconCornerDownLeft size={16} />
                      </Kbd>
                    </Tooltip>
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // Ensure there are no spaces added to the start of labels
                      const addedLabels = e.currentTarget.value
                        .replaceAll('; ', ';')
                        .split(';');

                      const randomColorMap = addedLabels.reduce((acc, curr) => {
                        return { ...acc, [curr]: chroma.random().hex() };
                      }, {});

                      setAddedLabelColorMap(randomColorMap);

                      // Add new labels to existing
                      const merged = [...form.values.labels, ...addedLabels];

                      // De-dupe labels and set to form
                      form.setFieldValue('labels', [...new Set(merged)]);

                      // Show add labels button
                      setIsAddingLabel(false);
                    }
                  }}
                />
              ) : (
                <Button
                  copy='Add new label'
                  leftSection={<IconPlus size={16} />}
                  variant='subtle'
                  onClick={() => setIsAddingLabel(true)}
                />
              )}
            </Box>
          </Card>
        </>
      )}

      <Group justify='space-between' mt='xs'>
        <Box className='align-center'>
          <Text c='contrast.2' component='span' size='sm'>
            Sending to
          </Text>
          <ThemeIcon color='white' mb={2} ml={4} style={{ width: '50%' }}>
            <LabelsStudioIcon />
          </ThemeIcon>
        </Box>
        <Box>
          <Button
            color='gray'
            copy='Cancel'
            mr='xs'
            variant='outline'
            onClick={() => closeModal(id)}
          />
          <Button
            copy='Send'
            isLoading={labelstudioExport.isPending}
            type='submit'
          />
        </Box>
      </Group>
    </form>
  );
};

export default SendToLabelerModal;
