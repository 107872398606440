import {
  Box,
  BoxProps,
  Card,
  CardProps,
  ScrollArea,
  Stack,
  useMantineTheme
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import React, { CSSProperties } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

export interface ListCardItemProps extends BoxProps {}

const ListCardItem = ({
  children,
  ...props
}: React.PropsWithChildren<ListCardItemProps>) => {
  const { colors } = useMantineTheme();

  const ITEM_STYLE = {
    borderBottom: `1px dashed ${colors.gray[2]}`
  };

  return (
    <Box
      data-testid='list-card-item'
      px='sm'
      py='xs'
      style={ITEM_STYLE}
      {...props}
    >
      {children}
    </Box>
  );
};

export interface ListCardTitleProps extends BoxProps {
  children: React.ReactNode;
  isSticky?: boolean;
}

const ListCardTitle = ({
  children,
  isSticky,
  ...props
}: ListCardTitleProps) => {
  const { colors } = useMantineTheme();

  const TITLE_STYLE = {
    borderBottom: `1px dashed ${colors.gray[2]}`
  };

  return (
    <Box
      pos={isSticky ? 'sticky' : 'relative'}
      px='sm'
      py='sm'
      style={TITLE_STYLE}
      top={0}
      {...props}
    >
      {children}
    </Box>
  );
};

export interface ListCardProps extends CardProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  style?: CSSProperties;
  cardHeight?: number | 'auto';
  maxCardHeight?: number | 'auto';
  position?: 'absolute' | 'relative';
}

const ListCard = ({
  title,
  children,
  cardHeight = 'auto',
  maxCardHeight = 'auto',
  position = 'relative',
  style,
  ...props
}: ListCardProps) => {
  const CardStyle: CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    border: '1px solid #E9E8ED',
    boxShadow: '0px 1px 1px 0px rgba(28, 27, 34, 0.1)',
    position: position,
    width: '100%',
    height: cardHeight,
    maxHeight: maxCardHeight,
    zIndex: Z_INDEX.DROPDOWNS,
    ...style
  };

  const CardWrapperStyle: CSSProperties = {
    width: '100%',
    position: 'relative',
    height: maxCardHeight
  };

  const { ref, height } = useElementSize();

  return (
    <Box data-testid='list-card-wrapper' style={CardWrapperStyle}>
      <Card data-testid='list-card' style={CardStyle} {...props}>
        {title && (
          <Card.Section ref={ref}>
            <ListCard.Title>{title}</ListCard.Title>
          </Card.Section>
        )}
        <Card.Section>
          <ScrollArea.Autosize
            mah={
              maxCardHeight === 'auto'
                ? maxCardHeight
                : maxCardHeight - (height ?? 0)
            }
          >
            <Stack gap={0}>{children}</Stack>
          </ScrollArea.Autosize>
        </Card.Section>
      </Card>
    </Box>
  );
};

ListCard.Item = ListCardItem;
ListCard.Title = ListCardTitle;

export default ListCard;
