import {
  Anchor,
  Box,
  Grid,
  Paper,
  Select,
  Skeleton,
  Text,
  TextInput
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPuzzleOff } from '@tabler/icons-react';

import { INTEGRATION_CARDS } from '@/core/constants/integrations.constants';
import { useDatabricksClusters } from '@/core/hooks/query-hooks/use-databricks-clusters/use-databricks-clusters';
import { useDatabricksDatabases } from '@/core/hooks/query-hooks/use-databricks-databases/use-databricks-databases';
import { useIntegration } from '@/core/hooks/query-hooks/use-integration/use-integration';
import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';

interface FormValues {
  cluster: string;
  databaseName: string;
  tableName: string;
}

const DatabricksExportForm = ({
  form
}: {
  form: UseFormReturnType<FormValues>;
}) => {
  const { data, isPending } = useIntegration('databricks');
  const dataBricksClusters = useDatabricksClusters();
  const dataBricksDBs = useDatabricksDatabases(form.values.cluster);
  const { openModal } = useModals();

  if (isPending) {
    return <Skeleton data-testid='form-skeleton' height={40} mt='xs' />;
  }
  const formattedClusters =
    dataBricksClusters.data?.map(({ name, id, status }) => ({
      label: `${name}${status !== 'RUNNING' ? ' (Not Running)' : ''}`,
      value: id,
      disabled: status !== 'RUNNING'
    })) || [];
  const config = INTEGRATION_CARDS.find((i) => i.id === 'databricks');

  return (
    <Box data-testid='databricks-form'>
      {data?.name ? (
        <>
          <Select
            required
            searchable
            comboboxProps={{ withinPortal: false }}
            data={formattedClusters}
            label='Cluster'
            {...form.getInputProps('cluster')}
          />
          {dataBricksDBs.isLoading && form.values.cluster ? (
            <Skeleton height={40} mt='xs' />
          ) : (
            <Select
              required
              searchable
              comboboxProps={{ withinPortal: false }}
              data={dataBricksDBs.data || []}
              disabled={!form.values.cluster}
              label='Database name'
              mt={6}
              {...form.getInputProps('databaseName')}
            />
          )}
          <TextInput
            autoFocus
            required
            label='Table Name'
            mt={6}
            {...form.getInputProps('tableName')}
          />
        </>
      ) : (
        <Paper
          mt='xs'
          p='xs'
          style={{
            background: 'var(--mantine-color-contrast-8)'
          }}
        >
          <Grid columns={24}>
            <Grid.Col span={3}>
              <Paper
                className='justify-center'
                p='xs'
                radius='md'
                style={{
                  background: 'var(--mantine-color-red-2)',
                  width: 48,
                  height: 48
                }}
              >
                <IconPuzzleOff color='red' width={20} />
              </Paper>
            </Grid.Col>
            <Grid.Col span={21}>
              <>
                <Text fw={600}>Please set up the integration</Text>
                <Text c='gray.6' size='sm'>
                  You must set up the integration before using it.
                  <Anchor
                    c='brand.6'
                    fw={600}
                    mx={4}
                    size='sm'
                    onClick={() => openModal(Modals.INTEGRATIONS, { config })}
                  >
                    Click here
                  </Anchor>
                  to set up Databricks
                </Text>
              </>
            </Grid.Col>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default DatabricksExportForm;
