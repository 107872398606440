import { Box, Button, Group, Text } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import { useState } from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { BulkHumanFeedbackCard } from '@/core/components/molecules/bulk-human-feedback-card/bulk-human-feedback-card';
import { FeedbackType } from '@/core/types/human-feedback.types';
import { MetricsRow } from '@/core/types/metrics-table.types';
import {
  convertFeedbackToCreateOperation,
  convertFeedbackToDeleteOperation
} from '@/core/utils/convert-feedback-operations/convert-feedback-operations';
import { useBulkEditFeedbackRatings } from '@/evaluate/hooks/query-hooks/use-feedback-ratings/use-bulk-edit-feedback-ratings';

const BulkHumanFeedbackModal = ({
  id,
  innerProps: {
    commonFeedbackMetrics,
    feedbackMetricIdsWithMultipleValues,
    selectedRows
  }
}: ContextModalProps<{
  commonFeedbackMetrics: FeedbackTemplate<FeedbackType>[];
  feedbackMetricIdsWithMultipleValues: string[];
  selectedRows: MetricsRow[];
}>) => {
  const { closeModal } = useModals();

  const [isSaving, setIsSaving] = useState(false);

  const [checkedFeedbackIds, setCheckedFeedbackIds] = useState<string[]>([]);
  const [nextFeedbackMetrics, setNextFeedbackMetrics] = useState<
    FeedbackTemplate<FeedbackType>[]
  >(commonFeedbackMetrics);

  const bulkEditFeedbackRatings = useBulkEditFeedbackRatings();

  // Convert feedback to operations
  const operations = nextFeedbackMetrics
    .filter((feedbackMetric) => {
      const isDeletingMultipleValues =
        feedbackMetric.id != null
          ? checkedFeedbackIds.includes(feedbackMetric.id) &&
            feedbackMetricIdsWithMultipleValues.includes(feedbackMetric.id)
          : false;

      return feedbackMetric.isModified() || isDeletingMultipleValues;
    })
    .map((feedbackMetric) =>
      feedbackMetric.value == null
        ? convertFeedbackToDeleteOperation(feedbackMetric)
        : convertFeedbackToCreateOperation(feedbackMetric)
    );

  const handleEditFeedbackRatingChange = (
    metric: FeedbackTemplate<FeedbackType>,
    metrics: FeedbackTemplate<FeedbackType>[]
  ) => {
    // Keep the checked state in sync when the feedback changes
    handleCheckFeedbackId(metric.id, metric.isModified());

    setNextFeedbackMetrics(metrics);
  };

  const handleCheckFeedbackId = (
    metricId: string | undefined,
    checked: boolean
  ) => {
    if (metricId != null) {
      setCheckedFeedbackIds((state) => {
        const nextState = state.filter((id) => id !== metricId);
        if (checked) {
          nextState.push(metricId);
        }
        return nextState;
      });
    }
  };

  const handleProvideFeedback = () => {
    // Show spinner
    setIsSaving(true);

    // Bulk edit / delete
    bulkEditFeedbackRatings
      .mutateAsync({
        operations,
        indexes: selectedRows.map((row) => parseInt(row.id))
      })
      .then(() => {
        setIsSaving(false);
        closeModal(id);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const hasNoHumanRatingsConfigured = nextFeedbackMetrics.length === 0;

  const hasNoChanges = operations.length === 0;

  const label = hasNoChanges
    ? 'Save Annotations'
    : `Save Annotations (${operations.length})`;

  const numDeletions = operations.filter(
    (op) => op.operation_type === 'delete'
  ).length;
  const numAdditions = operations.filter(
    (op) => op.operation_type === 'create'
  ).length;

  return (
    <Box data-testid='bulk-human-feedback-modal'>
      <Box p='lg'>
        <Text fw={600} mb='sm' size='sm'>
          <MessageSpan
            numAdditions={numAdditions}
            numDeletions={numDeletions}
            numRows={selectedRows.length}
          />
        </Text>
        {hasNoHumanRatingsConfigured ? (
          <Text c='gray' size='sm' ta='center'>
            No annotations configured
          </Text>
        ) : (
          <BulkHumanFeedbackCard
            canConfigureTemplates={false}
            checkedFeedbackIds={checkedFeedbackIds}
            feedbackMetricIdsWithMultipleValues={
              feedbackMetricIdsWithMultipleValues
            }
            feedbackMetrics={nextFeedbackMetrics}
            onChange={handleEditFeedbackRatingChange}
            onCheck={handleCheckFeedbackId}
          />
        )}
      </Box>
      <Box
        bg='gray.1'
        p='lg'
        style={{ borderTop: '1px solid var(--mantine-color-gray-2)' }}
      >
        <Group justify='flex-end'>
          <Button variant='default' onClick={() => closeModal(id)}>
            Cancel
          </Button>
          <Button
            disabled={hasNoHumanRatingsConfigured || hasNoChanges}
            loading={isSaving}
            onClick={handleProvideFeedback}
          >
            {label}
          </Button>
        </Group>
      </Box>
    </Box>
  );
};

const MessageSpan = ({
  numDeletions,
  numAdditions,
  numRows
}: {
  numDeletions: number;
  numAdditions: number;
  numRows: number;
}) => {
  const deletionsNoun = `annotation${numDeletions === 1 ? '' : 's'}`;
  const additionsNoun = `annotation${numAdditions === 1 ? '' : 's'}`;
  const rowsNoun = `row${numRows === 1 ? '' : 's'}`;

  if (numDeletions > 0 && numAdditions > 0) {
    return (
      <span>
        <b style={{ color: 'var(--mantine-color-red-5)' }}>
          Deleting {numDeletions} {deletionsNoun}
        </b>{' '}
        and{' '}
        <b style={{ color: 'var(--mantine-color-green-5)' }}>
          adding {numAdditions} {additionsNoun}
        </b>{' '}
        to {numRows} {rowsNoun}.
      </span>
    );
  } else if (numDeletions > 0) {
    return (
      <span>
        <b style={{ color: 'var(--mantine-color-red-5)' }}>
          Deleting {numDeletions} {deletionsNoun}
        </b>{' '}
        on {numRows} {rowsNoun}.
      </span>
    );
  } else if (numAdditions > 0) {
    return (
      <span>
        <b style={{ color: 'var(--mantine-color-green-5)' }}>
          Adding {numAdditions} {additionsNoun}
        </b>{' '}
        to {numRows} {rowsNoun}.
      </span>
    );
  }
  return (
    <span>
      Select one or more annotations to change on {numRows} {rowsNoun}.
    </span>
  );
};

export default BulkHumanFeedbackModal;
