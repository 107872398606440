import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { paths } from '@/core/types/api';

export const GET_INTEGRATION = '/integrations/{name}';

type Name = paths[typeof GET_INTEGRATION]['get']['parameters']['path']['name'];

export const useIntegration = (name: Name) => {
  const fetcher = async () => {
    const resp = await api.GET(GET_INTEGRATION, {
      params: { path: { name } }
    });

    return resp.data;
  };

  const key = [GET_INTEGRATION, name];
  const response = useQuery({
    queryKey: key,
    queryFn: fetcher,
    enabled: Boolean(name)
  });

  return response;
};
