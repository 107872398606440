import {
  FeedbackType,
  FeedbackTypeToConstraintMap,
  FeedbackValueMap
} from '@/core/types/human-feedback.types';
import { generateUUID } from '@/core/utils/generate-uuid/generate-uuid';

export interface FeedbackTemplateInputs<T extends FeedbackType> {
  accessor?: string;
  name?: string;
  constraints?: FeedbackTypeToConstraintMap[T];
  createdAt?: string;
  createdBy?: string;
  criteria?: string;
  explanation?: string;
  id?: string;
  withExplanation?: boolean;
  metricValue?: FeedbackValueMap[T] | undefined;
  value?: FeedbackValueMap[T] | undefined;
}

/**
 * Represents a template for creating a rating configuaration.
 * @template T - The type of feedback.
 */
export default class FeedbackTemplate<T extends FeedbackType> {
  // Unique key for the template. Handles the case where multiple templates are created in the same session.
  private key: string = generateUUID();

  public name?: string;
  public explanation?: string;
  public withExplanation?: boolean = false;
  public criteria?: string;
  public constraints?: FeedbackTypeToConstraintMap[T];
  public id?: string;
  public createdAt: string = new Date().toISOString();
  public createdBy?: string;
  public value?: FeedbackValueMap[T] | undefined;

  private initialValues: FeedbackTemplateInputs<T> | undefined;

  /**
   * Creates an instance of FeedbackTemplate.
   * @param {FeedbackTemplateInputs<T>} options - The options to initialize the template.
   */
  constructor(inputs?: FeedbackTemplateInputs<T>) {
    if (inputs) {
      this.constraints = inputs.constraints;
      this.createdAt = inputs.createdAt ?? new Date().toISOString();
      this.createdBy = inputs.createdBy;
      this.criteria = inputs.criteria;
      this.explanation = inputs.explanation;
      this.id = inputs.id;
      this.name = inputs.name;
      this.withExplanation = inputs.withExplanation;
      this.value = inputs?.value;

      this.initialValues = {
        name: this.name,
        criteria: this.criteria,
        constraints: this.constraints,
        id: this.id,
        createdAt: this.createdAt,
        createdBy: this.createdBy,
        withExplanation: this.withExplanation,
        value: this.value,
        explanation: this.explanation
      };

      // Make a deep copy of the initial values for immutability.
      this.initialValues = JSON.parse(JSON.stringify(this.initialValues));
    }
  }

  /**
   * Ingests inputs and sets values. Useful for cloning.
   * @param {FeedbackTemplateInputs<T> input} - The inputs to ingest.
   */
  public from(input: FeedbackTemplateInputs<T> | FeedbackTemplate<T>) {
    this.constraints = input.constraints;
    this.createdAt = input.createdAt ?? new Date().toISOString();
    this.createdBy = input.createdBy;
    this.criteria = input.criteria;
    this.explanation = input.explanation;
    this.id = input.id;
    this.name = input.name;
    this.withExplanation = input.withExplanation;
    this.value = input?.value;

    this.initialValues = {
      name: this.name,
      criteria: this.criteria,
      constraints: this.constraints,
      id: this.id,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      withExplanation: this.withExplanation,
      value: this.value,
      explanation: this.explanation
    };

    // Make a deep copy of the initial values for immutability.
    this.initialValues = JSON.parse(JSON.stringify(this.initialValues));

    return this;
  }

  /**
   * Retrieves the initial values of the template.
   * @returns {FeedbackTemplateInputs<T>} The initial values of the template.
   */
  public getInitialValues(): FeedbackTemplateInputs<T> {
    if (this.initialValues == null) {
      this.throw(
        'Cannot retrieve initial values before initializing the template'
      );
      return {};
    }

    return this.initialValues;
  }

  public getKey(): string {
    return this.key;
  }

  public isInProduction(): boolean {
    return this.id != null;
  }

  public isModified(): boolean {
    if (this.initialValues == null) {
      this.throw('Cannot check if modified before initializing the template');
      return false;
    }

    const currentValues = [
      this.name,
      this.constraints,
      this.criteria,
      this.withExplanation,
      this.value,
      this.explanation
    ];
    const initialValues = [
      this.initialValues.name,
      this.initialValues.constraints,
      this.initialValues.criteria,
      this.initialValues.withExplanation,
      this.initialValues.value,
      this.initialValues.explanation
    ];

    return JSON.stringify(currentValues) !== JSON.stringify(initialValues);
  }

  /**
   * Resets modified values back to initial values.
   * @returns {void}
   */
  public resetToInitialValues(): void {
    if (this.initialValues == null) {
      this.throw(
        'Cannot reset to initial values before initializing the template'
      );
      return;
    }

    this.name = this.initialValues.name;
    this.constraints = this.initialValues.constraints;
    this.createdAt = this.initialValues.createdAt ?? new Date().toISOString();
    this.createdBy = this.initialValues.createdBy;
    this.criteria = this.initialValues.criteria;
    this.id = this.initialValues.id;
    this.withExplanation = this.initialValues.withExplanation;
    this.value = this.initialValues.value;
    this.explanation = this.initialValues.explanation;
  }

  public setConstraints(constraints: Partial<FeedbackTypeToConstraintMap[T]>) {
    if (this.constraints == null) {
      this.throw(`Cannot set null constraints`);
      return;
    }

    const newConstraints = { ...this.constraints, ...constraints };
    this.constraints = newConstraints;
  }

  public setKey(key: string) {
    this.key = key;
  }

  private throw(msg: string) {
    // eslint-disable-next-line no-console
    return console.warn(msg);
  }
}
