// Used for filterable options
// TODO: long term fix to have API return these values for us
export const PII_VALUES: string[] = [
  'address',
  'credit_card_info',
  'date_of_birth',
  'email',
  'name',
  'network_info',
  'password',
  'phone_number',
  'ssn',
  'username'
];

// Used for filterable options
// TODO: short term fix driven by Prompt Injection detection model: https://github.com/rungalileo/wizard/blob/6fe709567bacadde717ff56ad36918344873f42f/models/prompt_injection_pipeline/README.md?plain=1#L14-L18
// TODO: long term fix to have API return these values for us
export const PROMPT_INJECTION_VALUES: string[] = [
  'impersonation',
  'obfuscation',
  'simple_instruction',
  'few_shot',
  'new_context'
];

// TODO: long term have API return these values for us
export const TONE_VALUES: string[] = [
  'neutral',
  'joy',
  'love',
  'fear',
  'surprise',
  'sadness',
  'anger',
  'annoyance',
  'confusion'
];

export const PROTECT_OPTIONS = [
  'ERROR',
  'TRIGGERED',
  'TIMEOUT',
  'NOT_TRIGGERED'
];
