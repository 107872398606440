import { QueryClient } from '@tanstack/react-query';

import {
  EVALUATE_CHAINS_ROWS_PATH_FULL,
  EVALUATE_CHAINS_ROWS_PATH_PARTIAL,
  EVALUATE_COLUMNAR_RUN_PATH,
  EVALUATE_METRICS_PATH,
  EVALUATE_RUN_PATH,
  EVALUATE_RUNS_PATH,
  PROJECT_RUN_METRICS_PATH,
  PROJECT_RUN_PATH
} from '@/core/constants/api.constants';

/** Invalidates all queries that are dependent on changes to feedback ratings */
export function useInvalidateFeedbackQueries({
  queryClient
}: {
  queryClient: QueryClient;
}) {
  return () => {
    queryClient.invalidateQueries({ queryKey: [PROJECT_RUN_METRICS_PATH] });
    queryClient.invalidateQueries({ queryKey: [EVALUATE_METRICS_PATH] });
    queryClient.invalidateQueries({ queryKey: [PROJECT_RUN_PATH] });
    queryClient.invalidateQueries({ queryKey: [EVALUATE_RUN_PATH] });
    queryClient.invalidateQueries({ queryKey: [EVALUATE_RUNS_PATH] });
    queryClient.invalidateQueries({
      queryKey: [EVALUATE_CHAINS_ROWS_PATH_FULL]
    });
    queryClient.invalidateQueries({
      queryKey: [EVALUATE_CHAINS_ROWS_PATH_PARTIAL]
    });
    queryClient.invalidateQueries({
      queryKey: [EVALUATE_COLUMNAR_RUN_PATH]
    });
  };
}
