import { Box, Button, Group, Text } from '@mantine/core';
import { ContextModalProps, useModals } from '@mantine/modals';
import { useState } from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { HumanFeedbackCard } from '@/core/components/molecules/human-feedback-card/human-feedback-card';
import { FeedbackType } from '@/core/types/human-feedback.types';
import { useObserveStore } from '@/observe/stores/observe.store';

const BulkHumanFeedbackModal = ({
  id,
  innerProps: { templates }
}: ContextModalProps<{ templates: FeedbackTemplate<any>[] }>) => {
  const { closeModal } = useModals();

  const selectedMetricsRows = useObserveStore((s) => s.selectedMetricsRows);

  // TODO we need to gather the feedback metrics from all selected rows
  // - then this state should hold only the common values between all rows
  const [nextFeedbackMetrics, setNextFeedbackMetrics] =
    useState<FeedbackTemplate<FeedbackType>[]>(templates);

  const handleEditFeedbackRatingChange = (
    _metric: FeedbackTemplate<FeedbackType>,
    metrics: FeedbackTemplate<FeedbackType>[]
  ) => {
    setNextFeedbackMetrics(metrics);
  };

  const handleProvideFeedback = () => {
    // TODO use bulk endpoint to apply feedback to selected rows
  };

  const hasNoHumanRatingsConfigured = nextFeedbackMetrics.length === 0;

  return (
    <Box data-testid='bulk-human-feedback-modal'>
      <Box p='lg'>
        <Group justify='space-between' mb='sm'>
          <Text c='gray.5' fw={400} size='sm'>
            Annotating
          </Text>
          <Text size='sm'>
            {selectedMetricsRows != null && selectedMetricsRows.length > 0
              ? 'Selected rows'
              : 'All rows in time range'}
          </Text>
        </Group>
        {hasNoHumanRatingsConfigured ? (
          <Text c='gray' size='sm' ta='center'>
            No annotations configured
          </Text>
        ) : (
          <HumanFeedbackCard
            canConfigureTemplates={false}
            feedbackMetrics={nextFeedbackMetrics}
            onChange={handleEditFeedbackRatingChange}
          />
        )}
      </Box>
      <Box
        bg='gray.1'
        p='lg'
        style={{ borderTop: '1px solid var(--mantine-color-gray-2)' }}
      >
        <Group justify='flex-end'>
          <Button variant='default' onClick={() => closeModal(id)}>
            Cancel
          </Button>
          <Button
            disabled={hasNoHumanRatingsConfigured}
            loading={false}
            onClick={handleProvideFeedback}
          >
            Save Annotations
          </Button>
        </Group>
      </Box>
    </Box>
  );
};

export default BulkHumanFeedbackModal;
