import { Box } from '@mantine/core';
import React from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { ScoreRater } from '@/core/components/molecules/score-rating/score-rater/score-rater';
import { StarRater } from '@/core/components/molecules/star-rating/star-rater/star-rater';
import { TagsRater } from '@/core/components/molecules/tags-rating/tags-rater/tags-rater';
import { TextRater } from '@/core/components/molecules/text-rating/text-rater/text-rater';
import { ThumbRater } from '@/core/components/molecules/thumb-rating/thumb-rater/thumb-rater';
import { FeedbackType } from '@/core/types/human-feedback.types';
import { Permission } from '@/core/types/user-permissions.types';

export interface HumanFeedbackInputProps {
  metric: FeedbackTemplate<FeedbackType>;
  permissions?: Permission;
  onChange: (metric: FeedbackTemplate<FeedbackType>) => void;
}

const HumanFeedbackInput = ({
  metric,
  permissions,
  onChange
}: HumanFeedbackInputProps) => {
  // Computed
  const feedbackType = metric.constraints?.feedback_type;

  const handleKeydown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation();
    }
  };

  switch (feedbackType) {
    case FeedbackType.LikeDislike: {
      const handleThumbChange = (value: boolean) => {
        // Reset value if it is the same
        if (metric.value === value) {
          metric.value = undefined;
        } else {
          metric.value = value;
        }

        onChange(metric);
      };

      return (
        <ThumbRater
          permissions={permissions}
          size={20}
          value={(metric as FeedbackTemplate<FeedbackType.LikeDislike>).value}
          onChange={handleThumbChange}
        />
      );
    }
    case FeedbackType.Score: {
      const handleScoreChange = (value: number | string | undefined) => {
        const parsedValue =
          value != null && value !== '' && !Number.isNaN(Number(value))
            ? Number(value)
            : undefined;

        // Only call onChange if the values are different
        if (metric.value !== parsedValue) {
          metric.value = parsedValue;

          onChange(metric);
        }
      };

      return (
        <ScoreRater
          max={metric?.constraints?.max || 10}
          min={metric?.constraints?.min || 0}
          permissions={permissions}
          value={(metric as FeedbackTemplate<FeedbackType.Score>).value}
          onChange={handleScoreChange}
          onKeyDown={handleKeydown}
        />
      );
    }
    case FeedbackType.Star: {
      const handleStarChange = (value: number) => {
        // Reset value if it is the same
        if (value === metric.value) {
          metric.value = undefined;
          return onChange(metric);
        }

        // Set value if it is different
        if (typeof value === 'number') {
          metric.value = value;
          return onChange(metric);
        }
      };

      return (
        <StarRater
          permissions={permissions}
          value={(metric as FeedbackTemplate<FeedbackType.Star>).value}
          onChange={handleStarChange}
        />
      );
    }
    case FeedbackType.Tags: {
      const handleTagChange = (value: string[] | null) => {
        if (Array.isArray(value) && value.length > 0) {
          metric.value = value;
        } else {
          metric.value = undefined;
        }

        onChange(metric);
      };

      return (
        <Box w='100%'>
          <TagsRater
            canCreate={metric?.constraints?.allow_other}
            permissions={permissions}
            tags={metric?.constraints?.tags ?? []}
            value={
              (metric as FeedbackTemplate<FeedbackType.Tags>).value ?? undefined
            }
            onChange={handleTagChange}
          />
        </Box>
      );
    }
    case FeedbackType.Text: {
      const handleTextChange = (value: string | undefined) => {
        if (typeof value === 'string' && value.length === 0) {
          value = undefined;
        }

        // Only call onChange if the values are different
        if (metric.value !== value) {
          metric.value = value;

          onChange(metric);
        }
      };

      return (
        <Box w='100%'>
          <TextRater
            permissions={permissions}
            value={(metric as FeedbackTemplate<FeedbackType.Text>)?.value ?? ''}
            onChange={handleTextChange}
            onKeyDown={handleKeydown}
          />
        </Box>
      );
    }
    default:
      return null;
  }
};

export default HumanFeedbackInput;
