import { Badge, Group, Text } from '@mantine/core';
import {
  ChartEvent,
  ChartOptions,
  LegendElement,
  LegendItem,
  TooltipItem
} from 'chart.js';

import ProtectStatusMetric from '@/core/components/atoms/protect-status-metric/protect-status-metric';
import TextSection from '@/core/components/atoms/text-section/text-section';
import ArrayMetric from '@/core/components/molecules/array-metric/array-metric';
import BasicMetric from '@/core/components/molecules/basic-metric/basic-metric';
import MetricStatus from '@/core/components/molecules/metric-status/metric-status';
import ScoreMetric from '@/core/components/molecules/score-metric/score-metric';
import {
  PROMPT_INJECTION_NONE_MESSAGE,
  STRING_PLACEHOLDER
} from '@/core/constants/strings.constants';
import {
  METRICS_GROUP_ID,
  MetricsGroupId
} from '@/core/hooks/use-metrics-group/use-metrics-group';
import { getScoreColorAndLabel } from '@/core/utils/get-score-color-and-label/get-score-color-and-label';
import { isSuccessMetricStatus } from '@/core/utils/is-metric-status/is-metric-status';
import { UnitFormatMap } from '@/core/utils/unit-conversions/unit-format-mapping';
import { ChunksOutputChip } from '@/observe/components/chunks-output-chip/chunks-output-chip';
import TagsMetric from '@/observe/page-components/tags-metric/tags-metric';
import {
  CHART_ID,
  ChartConfig,
  ChartId,
  COLUMN_ID,
  ColumnConfig,
  ColumnId,
  METRIC_ID,
  MetricConfig,
  MetricId
} from '@/observe/types/observe-store.types';
import { getChunksFromMetricsRow } from '@/observe/utils/get-chunks-from-row/get-chunks-from-row';

export const NOT_AVAILABLE_ROW_ERROR_EXPLANATION =
  "This metric was not computed due to an error in the model's output.";
export const NOT_AVAILABLE_ROW_NODE_TYPE_EXPLANATION =
  'This metric is only computed for retriever node types';

export const OBSERVE_HELP_MODAL_KEY = 'observe-show-help-modal';

/** Local util to modify click behavior to only show target dataset and hide the rest */
function onClickLegendItem(
  _event: ChartEvent,
  targetLegendItem: LegendItem,
  legend: LegendElement<'line'> | LegendElement<'bar'>
) {
  const targetDatasetIndex = targetLegendItem.datasetIndex;
  if (targetDatasetIndex == null) {
    return;
  }

  const allLegendItems = legend.legendItems ?? [];
  const everyDatasetIsVisible = allLegendItems.every(
    ({ hidden }) => hidden !== true
  );

  if (
    everyDatasetIsVisible ||
    !legend.chart.isDatasetVisible(targetDatasetIndex)
  ) {
    // Show target dataset
    legend.chart.show(targetDatasetIndex);
    targetLegendItem.hidden = false;

    // Hide all other datasets
    for (const legendItem of allLegendItems ?? []) {
      if (
        legendItem.datasetIndex != null &&
        legendItem.datasetIndex !== targetDatasetIndex
      ) {
        legend.chart.hide(legendItem.datasetIndex);
        legendItem.hidden = true;
      }
    }
  } else {
    // show all datasets
    for (const legendItem of allLegendItems ?? []) {
      if (legendItem.datasetIndex != null) {
        legend.chart.show(legendItem.datasetIndex);
        legendItem.hidden = false;
      }
    }
  }
}

export const DEFAULT_LINE_CHART_OPTIONS: ChartOptions<'line'> = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        usePointStyle: true
      },
      onClick: onClickLegendItem
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      bodyColor: '#000000',
      bodySpacing: 6,
      borderColor: '#EEEEEE',
      borderWidth: 1,
      boxPadding: 10,
      bodyFont: {
        // Ensures tooltip labels align on equal character size
        family: 'monospace'
      },
      caretPadding: 10,
      caretSize: 0,
      padding: 10,
      // Sort items descending by value
      itemSort: (a: TooltipItem<'line'>, b: TooltipItem<'line'>) =>
        b.parsed.y - a.parsed.y,
      titleColor: '#000000',
      usePointStyle: true
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        // Get timezone short name
        text: `Time (${
          new Date()
            .toLocaleTimeString('en-us', {
              timeZoneName: 'short'
            })
            .split(' ')[2]
        })`
      },
      bounds: 'data',
      grid: {
        drawTicks: false
      },
      // Note: cannot use `reverse` with crosshair plugin
      type: 'time',
      ticks: {
        autoSkipPadding: 25
      }
    },
    y: {
      grid: {
        drawTicks: false
      },
      min: 0
    }
  }
};

export const DEFAULT_BAR_CHART_OPTIONS: ChartOptions<'bar'> = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      maxHeight: 200,
      align: 'start',
      labels: {
        boxWidth: 12,
        boxHeight: 12
      },
      onClick: onClickLegendItem
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      bodyColor: '#000000',
      bodySpacing: 6,
      borderColor: '#EEEEEE',
      borderWidth: 1,
      boxPadding: 10,
      bodyFont: {
        // Ensures tooltip labels align on equal character size
        family: 'monospace'
      },
      caretPadding: 10,
      caretSize: 0,
      padding: 10,
      // Sort items descending by value
      itemSort: (a: TooltipItem<'bar'>, b: TooltipItem<'bar'>) =>
        b.parsed.y - a.parsed.y,
      titleColor: '#000000',
      usePointStyle: false
    }
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        // Get timezone short name
        text: `Time (${
          new Date()
            .toLocaleTimeString('en-us', {
              timeZoneName: 'short'
            })
            .split(' ')[2]
        })`
      },
      bounds: 'data',
      grid: {
        drawTicks: false
      },
      // Note: cannot use `reverse` with crosshair plugin
      type: 'time',
      ticks: {
        autoSkipPadding: 25
      }
    },
    y: {
      stacked: true,
      grid: {
        drawTicks: false
      },
      min: 0
    }
  }
};

export const DEFAULT_METRICS_GROUP_ID_ORDER: MetricsGroupId[] = [
  METRICS_GROUP_ID.RAG_QUALITY_METRICS,
  METRICS_GROUP_ID.PROTECT,
  METRICS_GROUP_ID.INPUT_METRICS,
  METRICS_GROUP_ID.OUTPUT_METRICS,
  METRICS_GROUP_ID.SYSTEM_METRICS,
  METRICS_GROUP_ID.SAFETY_METRICS,
  METRICS_GROUP_ID.CONFIG,
  METRICS_GROUP_ID.METADATA,
  METRICS_GROUP_ID.CUSTOM_METRICS,
  METRICS_GROUP_ID.HUMAN_RATINGS
];

export const METRICS_CONFIG: {
  [key in MetricId]: MetricConfig;
} = {
  [METRIC_ID.COMPLETENESS_GPT]: {
    metricId: METRIC_ID.COMPLETENESS_GPT,
    metricsGroupId: METRICS_GROUP_ID.RAG_QUALITY_METRICS,
    label: 'Completeness',
    projectSettingAccessor: 'completeness_gpt',
    nliProjectSettingAccessor: 'completeness_nli',
    requiresIntegration: ['openai', 'azure']
  },
  [METRIC_ID.CONTEXT_ADHERENCE]: {
    metricId: METRIC_ID.CONTEXT_ADHERENCE,
    metricsGroupId: METRICS_GROUP_ID.RAG_QUALITY_METRICS,
    label: 'Context Adherence',
    projectSettingAccessor: 'groundedness',
    nliProjectSettingAccessor: 'adherence_nli',
    requiresIntegration: ['openai', 'azure']
  },
  [METRIC_ID.CORRECTNESS]: {
    metricId: METRIC_ID.CORRECTNESS,
    metricsGroupId: METRICS_GROUP_ID.OUTPUT_METRICS,
    label: 'Correctness',
    projectSettingAccessor: 'factuality',
    description:
      "Requires access to 'gpt-3.5-turbo'. Will incur additional API calls.",
    requiresIntegration: ['openai', 'azure']
  },
  [METRIC_ID.INPUT_PII]: {
    metricId: METRIC_ID.INPUT_PII,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Input PII',
    projectSettingAccessor: 'input_pii'
  },
  [METRIC_ID.INPUT_SEXIST]: {
    metricId: METRIC_ID.INPUT_SEXIST,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Input Sexism',
    projectSettingAccessor: 'input_sexist'
  },
  [METRIC_ID.INPUT_TONE]: {
    metricId: METRIC_ID.INPUT_TONE,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Input Tone',
    projectSettingAccessor: 'input_tone'
  },
  [METRIC_ID.INPUT_TOXICITY]: {
    metricId: METRIC_ID.INPUT_TOXICITY,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Input Toxicity',
    projectSettingAccessor: 'input_toxicity'
  },
  [METRIC_ID.INSTRUCTION_ADHERENCE]: {
    metricId: METRIC_ID.INSTRUCTION_ADHERENCE,
    metricsGroupId: METRICS_GROUP_ID.OUTPUT_METRICS,
    label: 'Instruction Adherence',
    projectSettingAccessor: 'instruction_adherence',
    description:
      "Requires access to 'gpt-3.5-turbo'. Will incur additional API calls.",
    requiresIntegration: ['openai', 'azure']
  },
  [METRIC_ID.PII]: {
    metricId: METRIC_ID.PII,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Output PII',
    projectSettingAccessor: 'pii'
  },
  [METRIC_ID.PROMPT_INJECTION]: {
    metricId: METRIC_ID.PROMPT_INJECTION,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Prompt Injection',
    projectSettingAccessor: 'prompt_injection'
  },
  [METRIC_ID.PROMPT_PERPLEXITY]: {
    metricId: METRIC_ID.PROMPT_PERPLEXITY,
    metricsGroupId: METRICS_GROUP_ID.INPUT_METRICS,
    label: 'Prompt Perplexity',
    projectSettingAccessor: 'prompt_perplexity',
    description:
      "Requires access to 'text-davinci-002'. Will incur additional API calls"
  },
  [METRIC_ID.RETRIEVER_ATTRIBUTION]: {
    metricId: METRIC_ID.RETRIEVER_ATTRIBUTION,
    metricsGroupId: METRICS_GROUP_ID.RAG_QUALITY_METRICS,
    label: 'Chunk Attribution',
    projectSettingAccessor: 'chunk_attribution_utilization_gpt',
    nliProjectSettingAccessor: 'chunk_attribution_utilization_nli',
    requiresIntegration: ['openai', 'azure']
  },
  [METRIC_ID.RETRIEVER_UTILIZATION]: {
    metricId: METRIC_ID.RETRIEVER_UTILIZATION,
    metricsGroupId: METRICS_GROUP_ID.RAG_QUALITY_METRICS,
    label: 'Chunk Utilization',
    projectSettingAccessor: 'chunk_attribution_utilization_gpt',
    nliProjectSettingAccessor: 'chunk_attribution_utilization_nli',
    requiresIntegration: ['openai', 'azure']
  },
  [METRIC_ID.SEXIST]: {
    metricId: METRIC_ID.SEXIST,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Output Sexism',
    projectSettingAccessor: 'sexist'
  },
  [METRIC_ID.TONE]: {
    metricId: METRIC_ID.TONE,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Output Tone',
    projectSettingAccessor: 'tone'
  },
  [METRIC_ID.TOXICITY]: {
    metricId: METRIC_ID.TOXICITY,
    metricsGroupId: METRICS_GROUP_ID.SAFETY_METRICS,
    label: 'Output Toxicity',
    projectSettingAccessor: 'toxicity'
  },
  [METRIC_ID.UNCERTAINTY]: {
    metricId: METRIC_ID.UNCERTAINTY,
    metricsGroupId: METRICS_GROUP_ID.OUTPUT_METRICS,
    label: 'Uncertainty',
    projectSettingAccessor: 'uncertainty',
    description:
      "Requires access to 'text-davinci-03' or 'text-curie-01'. Might incur additional API calls to fetch log probabilities.",
    requiresIntegration: ['openai', 'azure']
  }
};

export const CHARTS_CONFIG: {
  [key in ChartId]: ChartConfig;
} = {
  [CHART_ID.COST]: {
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    type: 'line',
    label: 'Cost',
    aggregateMetric: {
      label: 'total',
      accessor: 'total_cost',
      formatValue: (value) => (
        <Text fw='bold' size='md'>
          {UnitFormatMap['parsed__dollars'](value)}
        </Text>
      )
    },
    chartAccessor: 'total_cost',
    projectSettingAccessor: undefined,
    yAxis: {
      label: 'Total Cost',
      units: 'parsed__dollars'
    }
  },
  [CHART_ID.LATENCY]: {
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    type: 'line',
    label: 'Latency',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_latency',
      formatValue: (value) => (
        <Text fw='bold' size='md'>
          {UnitFormatMap['parsed__milliseconds'](value)}
        </Text>
      )
    },
    chartAccessor: 'average_latency',
    projectSettingAccessor: undefined,
    yAxis: {
      label: 'Average Latency (ms)',
      units: 'parsed__milliseconds'
    }
  },
  [CHART_ID.REQUESTS]: {
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    type: 'line',
    label: 'Requests',
    aggregateMetric: {
      label: 'total',
      accessor: 'requests_count',
      formatValue: (value) => (
        <Text fw='bold' size='md'>
          {UnitFormatMap['parsed__whole_number'](value)}
        </Text>
      )
    },
    chartAccessor: 'requests_count',
    projectSettingAccessor: undefined,
    yAxis: {
      label: 'Total Requests',
      units: 'parsed__whole_number'
    }
  },
  [CHART_ID.FAILURES]: {
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    type: 'line',
    label: 'API Failures',
    aggregateMetric: {
      label: 'total',
      accessor: 'failures_count',
      formatValue: (value) => (
        <Text fw='bold' size='md'>
          {UnitFormatMap['parsed__whole_number'](value)}
        </Text>
      )
    },
    chartAccessor: 'failures_count',
    projectSettingAccessor: undefined,
    yAxis: {
      label: 'Total Failures',
      units: 'parsed__whole_number'
    }
  },
  [CHART_ID.PROMPT_PERPLEXITY]: {
    ...METRICS_CONFIG[METRIC_ID.PROMPT_PERPLEXITY],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_prompt_perplexity',
      formatValue: (value) => (
        <Text fw='bold' size='md'>
          {UnitFormatMap['parsed__score'](value)}
        </Text>
      )
    },
    chartAccessor: 'average_prompt_perplexity',
    yAxis: {
      label: 'Prompt Perplexity Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.UNCERTAINTY]: {
    ...METRICS_CONFIG[METRIC_ID.UNCERTAINTY],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_uncertainty',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average uncertainty'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.25,
            highIsBad: true,
            warningThreshold: 0.15
          })}
        />
      )
    },
    chartAccessor: 'average_uncertainty',
    yAxis: {
      label: 'Uncertainty Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.CORRECTNESS]: {
    ...METRICS_CONFIG[METRIC_ID.CORRECTNESS],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_factuality',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average correctness'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.5,
            warningThreshold: 0.8
          })}
        />
      )
    },
    chartAccessor: 'average_factuality',
    yAxis: {
      label: 'Correctness Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.INSTRUCTION_ADHERENCE]: {
    ...METRICS_CONFIG[METRIC_ID.INSTRUCTION_ADHERENCE],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_instruction_adherence',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average adherence'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.4,
            showThresholdLabel: true,
            warningIsNeutral: true,
            warningThreshold: 0.7
          })}
        />
      )
    },
    chartAccessor: 'average_instruction_adherence',
    yAxis: {
      label: 'Adherence Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.INPUT_TOXICITY]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_TOXICITY],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_input_toxicity',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average input toxicity'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.5,
            highIsBad: true
          })}
        />
      )
    },
    chartAccessor: 'average_input_toxicity',
    yAxis: {
      label: 'Toxicity Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.TOXICITY]: {
    ...METRICS_CONFIG[METRIC_ID.TOXICITY],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_toxicity',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average response toxicity'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.5,
            highIsBad: true
          })}
        />
      )
    },
    chartAccessor: 'average_toxicity',
    yAxis: {
      label: 'Toxicity Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.INPUT_PII]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_PII],
    type: 'bar',
    chartAccessor: 'input_pii_values_count',
    yAxis: {
      label: 'Total PII',
      units: 'parsed__whole_number'
    },
    // Helps to keep the alignment of chart legend label to theme color with other usage PII values
    sortData: true
  },
  [CHART_ID.PII]: {
    ...METRICS_CONFIG[METRIC_ID.PII],
    type: 'bar',
    chartAccessor: 'pii_values_count',
    yAxis: {
      label: 'Total PII',
      units: 'parsed__whole_number'
    },
    // Helps to keep the alignment of chart legend label to theme color with other usage PII values
    sortData: true
  },
  [CHART_ID.CONTEXT_ADHERENCE]: {
    ...METRICS_CONFIG[METRIC_ID.CONTEXT_ADHERENCE],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_groundedness',
      nliAccessor: 'average_rag_nli_adherence',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average adherence'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.4,
            showThresholdLabel: true,
            warningIsNeutral: true,
            warningThreshold: 0.7
          })}
        />
      )
    },
    chartAccessor: 'average_groundedness',
    nliChartAccessor: 'average_rag_nli_adherence',
    yAxis: {
      label: 'Adherence Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.RETRIEVER_ATTRIBUTION]: {
    ...METRICS_CONFIG[METRIC_ID.RETRIEVER_ATTRIBUTION],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_retriever_attribution',
      nliAccessor: 'average_rag_nli_retriever_attribution',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average attribution'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.3,
            showThresholdLabel: true,
            warningIsNeutral: true,
            warningThreshold: 0.75
          })}
        />
      )
    },
    chartAccessor: 'average_retriever_attribution',
    nliChartAccessor: 'average_rag_nli_retriever_attribution',
    yAxis: {
      label: 'Attribution Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.COMPLETENESS_GPT]: {
    ...METRICS_CONFIG[METRIC_ID.COMPLETENESS_GPT],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_completeness_gpt',
      nliAccessor: 'average_rag_nli_completeness',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average completeness'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.3,
            showThresholdLabel: true,
            warningIsNeutral: true,
            warningThreshold: 0.75
          })}
        />
      )
    },
    chartAccessor: 'average_completeness_gpt',
    nliChartAccessor: 'average_rag_nli_completeness',
    yAxis: {
      label: 'Completeness Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.RETRIEVER_UTILIZATION]: {
    ...METRICS_CONFIG[METRIC_ID.RETRIEVER_UTILIZATION],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_retriever_utilization',
      nliAccessor: 'average_rag_nli_retriever_utilization',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average utilization'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.3,
            showThresholdLabel: true,
            warningIsNeutral: true,
            warningThreshold: 0.75
          })}
        />
      )
    },
    chartAccessor: 'average_retriever_utilization',
    nliChartAccessor: 'average_rag_nli_retriever_utilization',
    yAxis: {
      label: 'Utilization Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.INPUT_SEXIST]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_SEXIST],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_input_sexist',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average input sexism'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.75,
            highIsBad: true,
            warningThreshold: 0.5
          })}
        />
      )
    },
    chartAccessor: 'average_input_sexist',
    yAxis: {
      label: 'Sexism Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.SEXIST]: {
    ...METRICS_CONFIG[METRIC_ID.SEXIST],
    type: 'line',
    aggregateMetric: {
      label: 'average',
      accessor: 'average_sexist',
      formatValue: (value) => (
        <ScoreMetric
          explanation='Average output sexism'
          size='md'
          value={value}
          {...getScoreColorAndLabel({
            value,
            dangerThreshold: 0.75,
            highIsBad: true,
            warningThreshold: 0.5
          })}
        />
      )
    },
    chartAccessor: 'average_sexist',
    yAxis: {
      label: 'Sexism Score',
      units: 'parsed__score'
    }
  },
  [CHART_ID.INPUT_TONE]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_TONE],
    type: 'bar',
    chartAccessor: 'input_tone_values_count',
    yAxis: {
      label: 'Tone Counts',
      units: 'parsed__whole_number'
    }
  },
  [CHART_ID.TONE]: {
    ...METRICS_CONFIG[METRIC_ID.TONE],
    type: 'bar',
    chartAccessor: 'tone_values_count',
    yAxis: {
      label: 'Tone Counts',
      units: 'parsed__whole_number'
    }
  },
  [CHART_ID.PROMPT_INJECTION]: {
    ...METRICS_CONFIG[METRIC_ID.PROMPT_INJECTION],
    type: 'bar',
    chartAccessor: 'prompt_injection_values_count',
    yAxis: {
      label: 'Total Prompt Injections',
      units: 'parsed__whole_number'
    },
    // Helps to keep the alignment of chart legend label to theme color with other usage values
    sortData: true
  }
};

export const COLUMNS_CONFIG: {
  [key in ColumnId]: ColumnConfig;
} = {
  [COLUMN_ID.NODE_TYPE]: {
    label: 'Node Type',
    accessor: 'node_type',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: undefined,
    filterType: 'node-type',
    alertConditionType: undefined
  },
  [COLUMN_ID.SYSTEM_VERSION]: {
    label: 'System Version',
    accessor: 'version',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: undefined,
    format: ({ value }) => (
      <Group justify='center'>
        <Badge
          radius={4}
          size='lg'
          styles={{
            root: {
              background: '#F4F4F6',
              color: '#383645'
            },
            label: {
              textTransform: 'none'
            }
          }}
        >
          {value ?? STRING_PLACEHOLDER}
        </Badge>
      </Group>
    ),
    filterType: 'multi-chooser',
    alertConditionType: undefined
  },
  [COLUMN_ID.CREATED_AT]: {
    label: 'Created At',
    accessor: 'created_at',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: undefined,
    format: ({ value }) => (
      <Text truncate size='sm' ta='center'>
        {UnitFormatMap['timestamp'](value)}
      </Text>
    ),
    filterType: undefined,
    alertConditionType: undefined
  },
  [COLUMN_ID.INPUT_TEXT]: {
    label: 'Input Text',
    accessor: 'input_text',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: undefined,
    format: ({ value }) =>
      value != null && value !== '' ? (
        <TextSection
          isTruncated={true}
          splitLineBreaks={true}
          value={value as string}
        />
      ) : (
        <Text size='sm' ta='center'>
          {STRING_PLACEHOLDER}
        </Text>
      ),
    filterType: 'string',
    alertConditionType: undefined
  },
  [COLUMN_ID.OUTPUT_TEXT]: {
    label: 'Output Text',
    accessor: 'output_text',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: undefined,
    format: ({ value, row }) => {
      if (value == null || value === '') {
        return (
          <Text size='sm' ta='center'>
            {STRING_PLACEHOLDER}
          </Text>
        );
      } else if (row?.node_type === 'retriever') {
        const chunks = getChunksFromMetricsRow(row);
        return <ChunksOutputChip chunks={chunks} />;
      } else {
        return (
          <TextSection
            isTruncated={true}
            splitLineBreaks={true}
            value={value as string}
          />
        );
      }
    },
    filterType: 'string',
    alertConditionType: undefined
  },
  [COLUMN_ID.FINISH_REASON]: {
    label: 'Finish reason',
    accessor: 'finish_reason',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: undefined,
    format: ({ value }) => (
      <Text size='sm' ta='center'>
        {value}
      </Text>
    ),
    filterType: 'string',
    alertConditionType: undefined
  },
  [COLUMN_ID.LATENCY]: {
    label: 'Latency',
    accessor: 'latency_ms',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    format: ({ value }) => (
      <Text truncate c='gray.6' fw={700} px={10} py={3} size='sm' ta='center'>
        {UnitFormatMap['parsed__milliseconds'](value)}
      </Text>
    ),
    filterType: 'milliseconds',
    alertConditionType: 'root/numeric/1'
  },
  [COLUMN_ID.PROTECT_STATUS]: {
    label: 'Protect Status',
    accessor: 'protect_status',
    objectAccessor: 'metrics',
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.PROTECT,
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ProtectStatusMetric}
        successMetricStatusProps={{}}
      />
    ),
    filterType: 'multi-chooser',
    alertConditionType: 'metric/string/1'
  },
  [COLUMN_ID.STATUS_CODE]: {
    label: 'Status Code',
    accessor: 'status_code',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    format: ({ value }) => {
      // NOTE: will convert this over to metrics table formatters
      const valueAsNumber =
        typeof value === 'string'
          ? parseFloat(value)
          : typeof value === 'number'
            ? value
            : NaN;
      return (
        <Text
          truncate
          c={isNaN(valueAsNumber) || valueAsNumber >= 300 ? 'red' : 'gray.6'}
          fw={700}
          px={10}
          py={3}
          size='sm'
          ta='center'
        >
          {value ?? STRING_PLACEHOLDER}
        </Text>
      );
    },
    filterType: 'whole-number',
    alertConditionType: 'root/numeric/1'
  },
  [COLUMN_ID.COST]: {
    label: 'Cost',
    accessor: 'cost',
    objectAccessor: 'metrics',
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={BasicMetric}
        successMetricStatusProps={{
          formatValue: (value) => (
            <Text fw={700}>{UnitFormatMap['parsed__dollars'](value)}</Text>
          )
        }}
      />
    ),
    filterType: 'dollars',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.INPUT_TOKENS]: {
    label: 'Input Tokens',
    accessor: 'num_input_tokens',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    format: ({ value }) => (
      <Text truncate size='sm' ta='center'>
        {UnitFormatMap['parsed__whole_number'](value)}
      </Text>
    ),
    filterType: 'whole-number',
    alertConditionType: 'root/numeric/1'
  },
  [COLUMN_ID.OUTPUT_TOKENS]: {
    label: 'Output Tokens',
    accessor: 'num_output_tokens',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.SYSTEM_METRICS,
    format: ({ value }) => (
      <Text truncate size='sm' ta='center'>
        {UnitFormatMap['parsed__whole_number'](value)}
      </Text>
    ),
    filterType: 'whole-number',
    alertConditionType: 'root/numeric/1'
  },
  [COLUMN_ID.CONTEXT_ADHERENCE]: {
    ...METRICS_CONFIG[METRIC_ID.CONTEXT_ADHERENCE],
    accessor: 'groundedness',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.4,
            showThresholdLabel: false,
            warningIsNeutral: true,
            warningThreshold: 0.7
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.RETRIEVER_ATTRIBUTION]: {
    ...METRICS_CONFIG[METRIC_ID.RETRIEVER_ATTRIBUTION],
    accessor: 'retriever_attribution',
    objectAccessor: 'metrics',
    format: ({ value }) => {
      let attributed: number | null | undefined = null;
      let total: number | null | undefined = null;
      if (isSuccessMetricStatus(value) && Array.isArray(value.value)) {
        attributed = value.value[0] as number | null | undefined;
        total = value.value[1] as number | null | undefined;
      }
      const attributionValue =
        attributed != null && total != null && total > 0
          ? attributed / total
          : null;
      return (
        <MetricStatus
          // Special case were we pass through the job status with our parsed value
          metricStatus={
            isSuccessMetricStatus(value)
              ? { ...value, value: attributionValue }
              : value
          }
          SuccessMetricStatusComponent={ScoreMetric}
          successMetricStatusProps={{
            ...getScoreColorAndLabel({
              value: attributionValue,
              dangerThreshold: 0.3,
              showThresholdLabel: false,
              warningIsNeutral: true,
              warningThreshold: 0.75
            }),
            label:
              attributed != null && total != null
                ? `${attributed} of ${total}`
                : undefined
          }}
        />
      );
    },
    filterType: undefined,
    alertConditionType: undefined,
    sortable: false
  },
  [COLUMN_ID.COMPLETENESS_GPT]: {
    ...METRICS_CONFIG[METRIC_ID.COMPLETENESS_GPT],
    accessor: 'completeness_gpt',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.3,
            showThresholdLabel: false,
            warningIsNeutral: true,
            warningThreshold: 0.75
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.RETRIEVER_UTILIZATION]: {
    ...METRICS_CONFIG[METRIC_ID.RETRIEVER_UTILIZATION],
    accessor: 'retriever_utilization',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.3,
            showThresholdLabel: false,
            warningIsNeutral: true,
            warningThreshold: 0.75
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.PROMPT_PERPLEXITY]: {
    ...METRICS_CONFIG[METRIC_ID.PROMPT_PERPLEXITY],
    accessor: 'prompt_perplexity',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={BasicMetric}
        successMetricStatusProps={{
          formatValue: (value) => (
            <Text fw={700}>{UnitFormatMap['parsed__score'](value)}</Text>
          )
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.UNCERTAINTY]: {
    ...METRICS_CONFIG[METRIC_ID.UNCERTAINTY],
    accessor: 'uncertainty',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.25,
            highIsBad: true,
            warningThreshold: 0.15
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.CORRECTNESS]: {
    ...METRICS_CONFIG[METRIC_ID.CORRECTNESS],
    accessor: 'factuality',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.5,
            warningIsNeutral: true,
            warningThreshold: 0.8
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.INSTRUCTION_ADHERENCE]: {
    ...METRICS_CONFIG[METRIC_ID.INSTRUCTION_ADHERENCE],
    accessor: 'instruction_adherence',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.4,
            showThresholdLabel: false,
            warningIsNeutral: true,
            warningThreshold: 0.7
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.INPUT_TOXICITY]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_TOXICITY],
    accessor: 'input_toxicity',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.5,
            highIsBad: true
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.TOXICITY]: {
    ...METRICS_CONFIG[METRIC_ID.TOXICITY],
    accessor: 'toxicity',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.5,
            highIsBad: true
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.INPUT_PII]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_PII],
    accessor: 'input_pii',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ArrayMetric}
        successMetricStatusProps={{
          columnTypeLabel: 'PII',
          formatLabel: (value) => UnitFormatMap['parsed__startCase'](value),
          noneMessage: PROMPT_INJECTION_NONE_MESSAGE
        }}
      />
    ),
    filterType: 'multi-chooser-metric-array',
    alertConditionType: 'metric/array_string/1'
  },
  [COLUMN_ID.PII]: {
    ...METRICS_CONFIG[METRIC_ID.PII],
    accessor: 'pii',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ArrayMetric}
        successMetricStatusProps={{
          columnTypeLabel: 'PII',
          formatLabel: (value) => UnitFormatMap['parsed__startCase'](value),
          noneMessage: PROMPT_INJECTION_NONE_MESSAGE
        }}
      />
    ),
    filterType: 'multi-chooser-metric-array',
    alertConditionType: 'metric/array_string/1'
  },
  [COLUMN_ID.INPUT_TONE]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_TONE],
    accessor: 'input_tone',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={BasicMetric}
        successMetricStatusProps={{
          formatValue: (value) => (
            <Text truncate fw='700'>
              {UnitFormatMap['parsed__startCase'](value ?? 'unknown')}
            </Text>
          )
        }}
      />
    ),
    filterType: 'multi-chooser',
    alertConditionType: 'metric/string/1'
  },
  [COLUMN_ID.TONE]: {
    ...METRICS_CONFIG[METRIC_ID.TONE],
    accessor: 'tone',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={BasicMetric}
        successMetricStatusProps={{
          formatValue: (value) => (
            <Text truncate fw='700'>
              {UnitFormatMap['parsed__startCase'](value ?? 'unknown')}
            </Text>
          )
        }}
      />
    ),
    filterType: 'multi-chooser',
    alertConditionType: 'metric/string/1'
  },
  [COLUMN_ID.INPUT_SEXIST]: {
    ...METRICS_CONFIG[METRIC_ID.INPUT_SEXIST],
    accessor: 'input_sexist',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.75,
            highIsBad: true,
            warningThreshold: 0.5
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.SEXIST]: {
    ...METRICS_CONFIG[METRIC_ID.SEXIST],
    accessor: 'sexist',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ScoreMetric}
        successMetricStatusProps={{
          ...getScoreColorAndLabel({
            value: value?.value,
            dangerThreshold: 0.75,
            highIsBad: true,
            warningThreshold: 0.5
          })
        }}
      />
    ),
    filterType: 'score',
    alertConditionType: 'metric/numeric/1'
  },
  [COLUMN_ID.PROMPT_INJECTION]: {
    ...METRICS_CONFIG[METRIC_ID.PROMPT_INJECTION],
    accessor: 'prompt_injection',
    objectAccessor: 'metrics',
    format: ({ value }) => (
      <MetricStatus
        metricStatus={value}
        SuccessMetricStatusComponent={ArrayMetric}
        successMetricStatusProps={{
          columnTypeLabel: 'Prompt Injection',
          formatLabel: (value) => UnitFormatMap['parsed__startCase'](value),
          noneMessage: PROMPT_INJECTION_NONE_MESSAGE
        }}
      />
    ),
    filterType: 'multi-chooser-metric-array',
    alertConditionType: 'metric/array_string/1'
  },
  [COLUMN_ID.TEMPERATURE]: {
    label: 'Temperature',
    accessor: 'temperature',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.CONFIG,
    format: ({ value }) => (
      <Text truncate c='gray.6' fw={700} px={10} py={3} size='sm' ta='center'>
        {UnitFormatMap['parsed__score'](value)}
      </Text>
    ),
    filterType: 'score',
    // NOTE: intentionally not an alert since it's just a model setting and not a metric
    alertConditionType: undefined
  },
  [COLUMN_ID.MODEL]: {
    label: 'Model',
    accessor: 'model',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.CONFIG,
    format: ({ value }) => (
      <Text truncate c='gray.6' fw={700} px={10} py={3} size='sm' ta='left'>
        {value ?? STRING_PLACEHOLDER}
      </Text>
    ),
    filterType: 'multi-chooser',
    alertConditionType: undefined
  },
  [COLUMN_ID.TAGS]: {
    label: 'Tags',
    accessor: 'tags',
    objectAccessor: undefined,
    projectSettingAccessor: undefined,
    metricsGroupId: METRICS_GROUP_ID.METADATA,
    format: ({ value }) => <TagsMetric value={value} />,
    filterType: 'tags',
    alertConditionType: undefined
  }
};
