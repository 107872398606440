import { Rating, RatingProps } from '@mantine/core';

import { Permission } from '@/core/types/user-permissions.types';

interface StarRaterProps extends RatingProps {
  permissions?: Permission;
}

// Exporting this component for consistency with other rater components
export const StarRater = ({ permissions, value, ...props }: StarRaterProps) => {
  return (
    <Rating
      data-testid='star-rater'
      readOnly={!permissions?.allowed}
      // This forces value to change when setting it to `undefined`
      value={value ?? 0}
      {...props}
    />
  );
};
