import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';

import { useIntegration } from '../use-integration/use-integration';

export const GET_DATABRICKS_CLUSTERS = '/integrations/databricks/clusters';

export const useDatabricksClusters = () => {
  const { data } = useIntegration('databricks');

  const fetchClusters = async () => {
    const resp = await api.GET('/integrations/databricks/clusters');

    return resp?.data;
  };

  const response = useQuery({
    queryKey: [GET_DATABRICKS_CLUSTERS],
    queryFn: fetchClusters,
    enabled: !!data?.name
  });

  return response;
};
