import { ButtonVariant as BV, MantineColor, MantineSize } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';

import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';
import Button from '@/fine-tune/components/button/button';
import { useExportTo } from '@/fine-tune/hooks/query-hooks/use-export-to/use-export-to';

export type ButtonVariant = BV;
interface ExportButtonProps {
  fromEditsCart?: boolean;
  variant?: ButtonVariant;
  color?: MantineColor;
  size?: MantineSize;
  disabled?: boolean;
}

const ExportButton = ({
  fromEditsCart,
  variant = 'outline',
  color = 'gray',
  size = 'xs',
  disabled = false
}: ExportButtonProps) => {
  const exportTo = useExportTo();

  const { openModal } = useModals();

  return (
    <Button
      color={color}
      copy='Export'
      disabled={disabled}
      id='export-button'
      isLoading={exportTo.isPending}
      leftSection={<IconDownload height={16} width={16} />}
      size={size}
      variant={variant}
      onClick={() => {
        openModal(Modals.EXPORTS, {
          fromEditsCart: Boolean(fromEditsCart)
        });
      }}
    />
  );
};

export default ExportButton;
