import {
  Box,
  Group,
  Modal,
  ModalRootProps,
  Text,
  useMantineTheme
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconAlertTriangle, IconMessageCog } from '@tabler/icons-react';
import Gleap from 'gleap';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import SkeletonLoader from '@/core/components/atoms/skeleton-loader/skeleton-loader';
import { PanelGroup } from '@/core/components/organisms/panel-group/panel-group';
import { RatingConfigurationStack } from '@/core/components/organisms/rating-configuration-stack/rating-configuration-stack';
import { Z_INDEX } from '@/core/constants/z-index.constants';
import {
  templateToPostBody,
  useCreateFeedbackTemplate
} from '@/core/hooks/query-hooks/use-feedback-templates/use-create-feedback-template';
import { useEditFeedbackTemplate } from '@/core/hooks/query-hooks/use-feedback-templates/use-edit-feedback-template';
import {
  toFeedbackTemplateInputs,
  useGetFeedbackTemplates
} from '@/core/hooks/query-hooks/use-feedback-templates/use-get-feedback-templates';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { FeedbackType } from '@/core/types/human-feedback.types';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { ConfigureRatingsTemplateForm } from './configure-ratings-template-form';

interface ConfigureRatingsModalProps extends ModalRootProps {}

export const ConfigureRatingsModal = ({
  ...props
}: ConfigureRatingsModalProps) => {
  // Local state
  const [isFirstRender, setIsFirstRender] = useState(true);

  // QueryParams
  const { projectId } = usePathParameters();

  // Hooks
  const { colors } = useMantineTheme();
  const router = useRouter();

  const isObserve = router.pathname?.startsWith('/observe');
  const projectTypeMessage = isObserve ? 'outputs' : 'runs';

  const message = (
    <Text>
      You can provide configured annotations on {projectTypeMessage} in the
      Annotation tab.
    </Text>
  );

  const { data: templatesData, isPending: isTemplatesLoading } =
    useGetFeedbackTemplates();

  const templates = templatesData?.templates ?? [];

  const createRatingConfiguration = useCreateFeedbackTemplate();
  const editRatingConfiguration = useEditFeedbackTemplate();

  // Local State
  const [cachedTemplates, setCachedTemplates] = useState<
    FeedbackTemplate<FeedbackType>[]
  >([]);

  const [selectedTemplate, setSelectedTemplate] = useState<
    FeedbackTemplate<FeedbackType> | undefined
  >();
  const [savingKeys, setSavingKeys] = useState<string[]>([]);

  // Computed
  const scrollAreaHeight = 'calc(80vh - 160px)'; // 160 is the height of the header + padding;

  const templatesWithChanges = cachedTemplates.filter((template) =>
    template.isModified()
  );

  const hasUnsavedChanges = templatesWithChanges.length > 0;

  // Effects
  useEffect(() => {
    setIsFirstRender(true);

    // Clears cache and selected project on project switch and new templates
    if (isFirstRender) {
      setCachedTemplates([]);
      setSelectedTemplate(undefined);
    }
  }, [props.opened, projectId, isTemplatesLoading]);

  useEffect(() => {
    if (props.opened) {
      if (isTemplatesLoading || !isFirstRender) {
        return;
      }

      // If there are no templates from the API, but there are cached templates,
      // set isFirstRender = false and return.
      if (templates.length === 0 && cachedTemplates.length > 0) {
        setIsFirstRender(false);
        return;
      }

      // Only set the cached templates on the first render if there are templates from the API.
      // Subsequent renders will have state managed locally.
      if (templates.length > 0) {
        setIsFirstRender(false);
        setCachedTemplates(templates);
        setSelectedTemplate(templates[0]);
      }
    }
  }, [
    props.opened,
    isTemplatesLoading,
    templates,
    cachedTemplates.length,
    projectId
  ]);

  // Handlers
  const handleClose = () => {
    if (hasUnsavedChanges) {
      openConfirmModal({
        zIndex: Z_INDEX.TOOLTIPS,
        title: (
          <Group>
            <IconAlertTriangle color='red' />
            <Text c='gray.7' fw={700}>
              You have unsaved changes
            </Text>
          </Group>
        ),
        variant: 'filled',
        children: (
          <Text c='gray.6' fw={500}>
            Are you sure you want to leave this page? Changes you made will not
            be saved.
          </Text>
        ),
        cancelProps: {
          color: 'red.2',
          c: 'red.6',
          autoContrast: false,
          variant: 'filled'
        },
        labels: {
          cancel: 'Close without saving',
          confirm: 'Save all & close'
        },
        onConfirm: async () => {
          await handleSaveAllTemplates().then(() => {
            props.onClose();
          });
        },
        onCancel: async () => {
          props.onClose();
        }
      });
    } else {
      props.onClose();
    }
    setIsFirstRender(true);
  };

  const handleSaveTemplate = async (
    template: FeedbackTemplate<FeedbackType>
  ) => {
    if (template.isInProduction()) {
      if (template.name != null && template.id != null) {
        setSavingKeys([...savingKeys, template.getKey()]);
        await editRatingConfiguration
          .mutateAsync({
            templateId: template.id,
            name: template.name,
            criteria:
              typeof template.criteria === 'string' &&
              template.criteria.length > 0
                ? template.criteria
                : null
          })
          .then((res) => {
            if (res) {
              const newInputs = toFeedbackTemplateInputs(res);
              template.from(newInputs);
            }

            setSavingKeys(savingKeys.filter((x) => x !== template.getKey()));

            // If there are no more templates in draft state, close the modal.
            if (!cachedTemplates.some((template) => template.isModified())) {
              props.onClose();

              showNotification({
                title: 'Annotations configuration has been saved',
                message,
                type: 'success'
              });
            }

            Gleap.trackEvent('configured feedback template');
          });
      }
    } else {
      const values = templateToPostBody(template);
      if (values != null) {
        setSavingKeys([...savingKeys, template.getKey()]);
        await createRatingConfiguration.mutateAsync(values).then((res) => {
          if (res) {
            const newInputs = toFeedbackTemplateInputs(res);
            template.from(newInputs);
          }

          setSavingKeys(savingKeys.filter((x) => x !== template.getKey()));

          // If there are no more templates in draft state, close the modal.
          if (!cachedTemplates.some((template) => template.isModified())) {
            props.onClose();
          }
        });
      }
    }
  };

  const handleTemplateChange = (template: FeedbackTemplate<any>) => {
    const configs = [...cachedTemplates];
    const index = configs.findIndex((x) => x.getKey() === template.getKey());
    configs[index] = template;
    setCachedTemplates(configs);
  };

  const handleSaveAllTemplates = async () => {
    const templates = cachedTemplates.filter((template) =>
      template.isModified()
    );
    setSavingKeys(templates.map((template) => template.getKey()));
    await Promise.all(
      templates.map((template) => handleSaveTemplate(template))
    ).then(() => {
      setSavingKeys([]);
      props.onClose();
    });
  };

  const templateNames = cachedTemplates
    .filter((template) => template.id !== selectedTemplate?.id)
    .filter((template) => template.name !== undefined)
    .map((template) => template.name?.toLowerCase() as string);

  return (
    <Modal.Root
      centered
      withinPortal
      className='no-padding-modal'
      data-testid='configure-ratings-modal'
      m={0}
      padding={0}
      size={800}
      zIndex={Z_INDEX.MODALS}
      {...props}
      keepMounted={false}
      onClose={handleClose}
    >
      <Modal.Overlay />
      <Modal.Content className='overflow-hidden' h='80vh'>
        <Modal.Header style={{ borderBottom: `1px solid ${colors.gray[2]}` }}>
          <Modal.Title p='md'>
            <Group gap={8}>
              <IconMessageCog size={18} />
              <Text c='gray.6' fw={600} size='sm'>
                Configure Annotations
              </Text>
            </Group>
          </Modal.Title>
          <Modal.CloseButton
            aria-label='Close annotations modal'
            c='gray.4'
            m='sm'
            size='md'
          />
        </Modal.Header>
        <Modal.Body p={0}>
          <PanelGroup mih='75vh' widths={[30, 70]}>
            <PanelGroup.Panel
              resizeRange={[25, 45]}
              style={{ borderRight: '1px solid var(--mantine-color-gray-2)' }}
            >
              <Box p='lg'>
                {isTemplatesLoading && (
                  <SkeletonLoader height={20} length={5} width={120} />
                )}
                {!isTemplatesLoading && (
                  <RatingConfigurationStack
                    configurations={cachedTemplates}
                    maxHeight={scrollAreaHeight}
                    savingKeys={savingKeys}
                    selectedConfiguration={selectedTemplate}
                    setConfigurations={setCachedTemplates}
                    setSavingKeys={setSavingKeys}
                    setSelectedConfiguration={setSelectedTemplate}
                  />
                )}
              </Box>
            </PanelGroup.Panel>
            <PanelGroup.Panel bg='gray.0' h='100%'>
              {isTemplatesLoading && (
                <SkeletonLoader height={20} length={5} width={120} />
              )}
              {!isTemplatesLoading && !selectedTemplate && (
                <Text c='gray.8' m='lg'>
                  Click on &quot;+ New Annotation Type&quot; to set up your
                  annotations.
                </Text>
              )}
              {!isTemplatesLoading && selectedTemplate && (
                <ConfigureRatingsTemplateForm
                  configuration={selectedTemplate}
                  maxHeight={scrollAreaHeight}
                  templateNames={templateNames}
                  onChange={handleTemplateChange}
                  onSave={() => handleSaveTemplate(selectedTemplate)}
                />
              )}
            </PanelGroup.Panel>
          </PanelGroup>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
