import { components } from './api';

export enum FeedbackType {
  LikeDislike = 'like_dislike',
  Score = 'score',
  Star = 'star',
  Tags = 'tags',
  Text = 'text'
}

export enum FeedbackTypeLabel {
  like_dislike = 'Thumbs Up & Down',
  score = 'Score',
  star = 'Star',
  tags = 'Categories',
  text = 'Text'
}

export type FeedbackTypeToConstraintMap = {
  [FeedbackType.LikeDislike]: components['schemas']['LikeDislikeConstraints'];
  [FeedbackType.Score]: components['schemas']['ScoreConstraints'];
  [FeedbackType.Star]: components['schemas']['StarConstraints'];
  [FeedbackType.Tags]: components['schemas']['TagsConstraints'];
  [FeedbackType.Text]: components['schemas']['TextConstraints'];
};

export type FeedbackValueMap = {
  [FeedbackType.LikeDislike]: boolean;
  [FeedbackType.Score]: number;
  [FeedbackType.Star]: 1 | 2 | 3 | 4 | 5;
  [FeedbackType.Tags]: string[];
  [FeedbackType.Text]: string;
};

export type RatingInputOption =
  | {
      feedback_type: FeedbackType.LikeDislike;
      value: boolean;
    }
  | {
      feedback_type: FeedbackType.Star;
      value: number;
    }
  | {
      feedback_type: FeedbackType.Text;
      value: string;
    }
  | {
      feedback_type: FeedbackType.Score;
      value: number;
    }
  | {
      feedback_type: FeedbackType.Tags;
      value: string[];
    };
