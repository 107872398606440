import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { GuardrailMetric } from '@/evaluate/types/prompt.types';

import { PromptStoreState } from './prompt.store.types';

const defaultMetrics = [
  'factuality',
  'groundedness',
  'toxicity',
  'context_relevance',
  'pii',
  'tone',
  'sexist',
  'prompt_perplexity',
  'latency'
] as GuardrailMetric[];

const defaultValues = {
  datasetId: undefined,
  isReRun: false,
  jobId: undefined,
  selectedGuardrailMetrics: [...defaultMetrics],
  customizedScorersConfiguration: [],
  selectedCustomMetrics: [],
  templateId: '',
  templateVersionId: '',
  templateVersionNum: 0,
  activeTable: {
    runId: undefined,
    ungroupedColumns: [],
    groupedColumns: [],
    activeColumns: [],
    selectedRowIds: []
  }
};

export const usePromptStore = createWithEqualityFn<PromptStoreState>()(
  (set) => ({
    ...defaultValues,

    actions: {
      resetPromptStore: () => set(() => ({ ...defaultValues })),
      setSelectedGuardrailMetrics: (metrics) =>
        set(() => ({ selectedGuardrailMetrics: metrics })),
      setCustomizedScorersConfiguration: (configs) =>
        set(() => ({ customizedScorersConfiguration: configs })),
      setSelectedCustomMetrics: (metrics) =>
        set(() => ({ selectedCustomMetrics: metrics })),
      setIsReRun: (bool) => set(() => ({ isReRun: bool })),
      setJobId: (id) => set(() => ({ jobId: id })),
      setActiveTable: (activeTable) =>
        set(() => ({
          activeTable
        })),
      setActiveTableActiveColumns: (activeColumns) =>
        set((s) => ({
          activeTable: {
            ...s.activeTable,
            activeColumns
          }
        })),
      setActiveTableSelectedRowIds: (selectedRowIds) =>
        set((s) => ({
          activeTable: {
            ...s.activeTable,
            selectedRowIds
          }
        }))
    },

    computed: {}
  }),
  shallow
);
