import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { ChainpollContinuousLearningStore } from './chainpoll-continuous-learning.store.types';

export const useChainpollContinuousLearningStore =
  createWithEqualityFn<ChainpollContinuousLearningStore>()(
    (set) => ({
      critiques: {},

      actions: {
        addCritique: (metric, critique) => {
          set((state) => {
            const currentCritiques = state.critiques[metric] || [];
            return {
              critiques: {
                ...state.critiques,
                [metric]: [...currentCritiques, critique]
              }
            };
          });
        }
      }
    }),
    shallow
  );
