import { Box, Text, Textarea, TextareaProps } from '@mantine/core';
import React, { useEffect } from 'react';

import { Permission } from '@/core/types/user-permissions.types';

interface TextRaterProps extends Omit<TextareaProps, 'onChange'> {
  permissions?: Permission;
  onChange: (value: string | undefined) => void;
}

export const TextRater = ({
  permissions,
  onChange,
  ...props
}: TextRaterProps) => {
  const [value, setValue] = React.useState<string | undefined>(
    (props?.value as string) ?? ''
  );

  const defaultPlaceholder = 'Enter your text annotation';

  const handleSetValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue((props?.value as string) ?? '');
  }, [props?.value]);

  if (!permissions?.allowed) {
    let readOnlyValue = value;
    if (readOnlyValue == null || readOnlyValue === '') {
      readOnlyValue = props.placeholder ?? defaultPlaceholder;
    }

    return (
      <Box
        bg='gray.1'
        data-testid='text-rater'
        p='xs'
        style={{ borderRadius: 6 }}
      >
        <Text c='gray.6' fw={500} size='sm'>
          {readOnlyValue}
        </Text>
      </Box>
    );
  }

  return (
    <Textarea
      data-testid='text-rater'
      placeholder={props?.placeholder ?? defaultPlaceholder}
      {...props}
      value={value}
      onBlur={() => onChange(value)}
      onChange={handleSetValue}
    />
  );
};
