import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

import { GET_EDIT } from '../use-edit-by-id/use-edit-by-id';
import { GET_EDITS } from '../use-edits/use-edits';

export const POST_EDIT_REVIEW = '/edits/{edit_id}/review';

/**
 * useEditReview
 *
 */
export const useEditReview = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);

  const queryClient = useQueryClient();

  const handleReviewEdit = async (editId: string) => {
    const res = await api.POST(POST_EDIT_REVIEW, {
      params: {
        path: { edit_id: editId }
      },
      // @ts-expect-error
      body: {}
    });

    return res?.data;
  };

  return useMutation({
    mutationFn: handleReviewEdit,
    onSuccess: (_, editId) => {
      queryClient.invalidateQueries({ queryKey: [GET_EDITS] });
      queryClient.invalidateQueries({
        queryKey: [GET_EDIT, { projectId, runId, split, editId }]
      });
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error approving edit')
  });
};
