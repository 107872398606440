import {
  Box,
  Group,
  Menu,
  MenuProps,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import React, { useRef, useState } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

export type PopoverMenuItem = {
  color?: string;
  label: string;
  icon?: React.ReactNode;
  hidden?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  withDivider?: boolean;
  tooltip?: string;
};

interface PopoverMenuProps extends MenuProps {
  data: PopoverMenuItem[];
  menuLabel?: string;
  target: React.ReactNode;
}

export const PopoverMenu = ({
  data = [],
  menuLabel,
  target,
  ...others
}: PopoverMenuProps) => {
  // Local State
  const [opened, setOpened] = useState(false);

  // Hooks
  const { width } = useViewportSize();
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Computed
  const { right } = buttonRef?.current?.getBoundingClientRect?.() || {
    right: 0
  };
  const flipMenuPosition = right > width - 30;
  const position = flipMenuPosition ? 'bottom-end' : 'bottom-start';

  // Handlers
  const handleItemClick = (e: React.MouseEvent, item: PopoverMenuItem) => {
    e.preventDefault();
    e.stopPropagation();
    item?.onClick?.();
  };

  const handleOpenMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    !opened && setOpened(true);
  };

  return (
    <Menu
      withinPortal
      opened={opened}
      position={position}
      shadow='md'
      zIndex={Z_INDEX.MODALS}
      onClose={() => setOpened(false)}
      {...others}
    >
      <Menu.Target>
        <UnstyledButton
          aria-label='Open popover menu'
          data-testid='popover-menu-trigger'
          ref={buttonRef}
          onClick={handleOpenMenu}
        >
          {target}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {menuLabel && <Menu.Label>{menuLabel}</Menu.Label>}
        {data.map((item) => {
          if (item?.hidden) {
            return;
          }

          const color = item.color || 'gray.9';
          return (
            <React.Fragment key={item.label}>
              <Tooltip
                disabled={!item.tooltip}
                label={item.tooltip}
                zIndex={Z_INDEX.TOOLTIPS}
              >
                <Box>
                  <Menu.Item
                    closeMenuOnClick={true}
                    disabled={item.disabled}
                    onClick={(e) => handleItemClick(e, item)}
                  >
                    <Group gap={0} justify='flex-start' pr='sm'>
                      {item.icon}
                      <Text
                        c={item.disabled ? 'contrast.5' : color}
                        fw={500}
                        size='sm'
                      >
                        {item.label}
                      </Text>
                    </Group>
                  </Menu.Item>
                </Box>
              </Tooltip>
              {item?.withDivider && <Menu.Divider />}
            </React.Fragment>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
