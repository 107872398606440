import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';
import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';

export const RUN_LABELS = 'RUN_LABELS';

// API types labels as unknown[], which is more annoying than useful. Replace it with string[]
export type RunLabelsResponse = Omit<
  components['schemas']['LabelResponse'],
  'labels'
> & {
  labels: string[];
};

export const useLabels = () => {
  const { projectId, runId } = usePathParameters();

  const { setChangeLabelTo } = useDataEditsStore((s) => s.actions);

  let isEnabled = Boolean(projectId && runId);

  const fetcher = async () => {
    const res = await api.GET('/projects/{project_id}/runs/{run_id}/labels', {
      params: { path: { project_id: projectId!, run_id: runId! } }
    });

    // API types labels as unknown[], which is more annoying than useful.
    const typedResp = res.data as RunLabelsResponse;

    if (typedResp?.labels) {
      // Handle MLTC labels resonse
      if (Array.isArray(res?.data?.labels?.[0])) {
        typedResp.labels = typedResp.labels.flat(2);
      }

      setChangeLabelTo(typedResp.labels?.[0]);
    }

    return typedResp;
  };

  const response = useQuery({
    queryKey: [RUN_LABELS, { projectId, runId }],
    queryFn: fetcher,
    enabled: isEnabled
  });

  return response;
};
