export const Z_INDEX = {
  NEGATIVE: -1,
  ZERO: 0,
  BASE: 1,
  DROPDOWNS: 100,
  SUB_MODAL: 1000,
  DRAWERS: 9997,
  MODALS: 9998,
  TOOLTIPS: 9999
} as const;
