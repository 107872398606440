import { Box, Divider, Group, Rating, Stack, Text } from '@mantine/core';

import Metric from '@/core/components/atoms/metric/metric';
import { MetricPopover } from '@/core/components/molecules/metric-popover/metric-popover';
import { NumberUnit } from '@/core/utils/unit-conversions';

interface AggregateStarRatingProps {
  average: number;
  counts: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
    unrated: number;
  };
  explanation?: string;
  singular?: boolean;
  title?: string;
}

export const AggregateStarRating = ({
  average,
  counts = {
    unrated: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0
  },
  explanation,
  singular,
  title
}: AggregateStarRatingProps) => {
  // Computed
  const total = Object.values(counts).reduce((a, b) => a + b, 0) ?? 0;
  const ratios = {
    0: counts?.unrated / total ?? 0,
    1: counts?.['1'] / total ?? 0,
    2: counts?.['2'] / total ?? 0,
    3: counts?.['3'] / total ?? 0,
    4: counts?.['4'] / total ?? 0,
    5: counts?.['5'] / total ?? 0
  };

  // Utils
  const formatRatio = (ratio: number) => {
    return new NumberUnit(ratio)
      .to('percentage', { places: 2, asDecimal: true })
      .toString({
        style: 'percent',
        maximumFractionDigits: 2
      });
  };

  return (
    <MetricPopover
      body={
        <Box>
          <Box p='sm'>
            <Text c='gray.2' fw={600} pb='sm' size='sm'>
              {title ?? 'Star Annotation'}
            </Text>
            <Stack gap='xs'>
              {Object.entries(ratios).map(([key, value]) => {
                const formattedValue = formatRatio(value);
                return (
                  <Group justify='space-between' key={key}>
                    <Rating readOnly value={parseInt(key)} />
                    <Metric
                      color='dark'
                      value={formattedValue}
                      variant='filled'
                    />
                  </Group>
                );
              })}
            </Stack>
          </Box>
          {explanation && (
            <>
              <Divider />
              <Box p='sm'>
                <Text c='gray.2' size='sm'>
                  {explanation}
                </Text>
              </Box>
            </>
          )}
        </Box>
      }
    >
      <Group wrap='nowrap'>
        <Rating
          readOnly
          count={singular ? 1 : 5}
          fractions={5}
          value={singular ? 1 : average}
        />
        <Text c='gray.6' data-testid='aggregate-star-rating' fw={600} size='sm'>
          {new NumberUnit(average ?? 0, 'number').toString({
            maximumFractionDigits: 1
          })}
        </Text>
      </Group>
    </MetricPopover>
  );
};
