import { useMutation, useQueryClient } from '@tanstack/react-query';
import Gleap from 'gleap';

import api from '@/core/api';
import { BULK_HUMAN_FEEDBACK_RATING_BY_RUN_ID_PATH } from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { useInvalidateFeedbackQueries } from './use-invalidate-feedback-queries';

interface BulkEditFeedbackRatings {
  operations: (
    | components['schemas']['BulkFeedbackRatingCreate']
    | components['schemas']['BulkFeedbackRatingDelete']
  )[];
  indexes: number[];
}

export const useBulkEditFeedbackRatings = (id?: string) => {
  const queryClient = useQueryClient();

  // Parameters
  let { projectId, runId } = usePathParameters();

  if (id) {
    runId = id;
  }

  const handleBulkEditFeedbackRatings = async ({
    operations,
    indexes
  }: BulkEditFeedbackRatings) => {
    return api
      .POST(BULK_HUMAN_FEEDBACK_RATING_BY_RUN_ID_PATH, {
        params: {
          path: {
            project_id: projectId!,
            run_id: runId!
          }
        },
        body: {
          operations,
          selector: {
            selector_type: 'indexes',
            indexes
          }
        }
      })
      .finally(() => {
        Gleap.trackEvent('bulk edited feedback ratings');
      });
  };

  const invalidateQueries = useInvalidateFeedbackQueries({ queryClient });

  const mutation = useMutation({
    mutationFn: handleBulkEditFeedbackRatings,
    onSuccess: () => {
      setTimeout(() => {
        invalidateQueries();
      }, 250);
    },
    onError: () => {
      showNotification({
        title: 'Error',
        message: 'Ratings could not be updated',
        type: 'error'
      });
    }
  });

  return mutation;
};
