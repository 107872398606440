import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { components } from '@/core/types/api';
import {
  FeedbackType,
  RatingInputOption
} from '@/core/types/human-feedback.types';

export function convertFeedbackToCreateOperation(
  feedbackMetric: FeedbackTemplate<FeedbackType>
): components['schemas']['BulkFeedbackRatingCreate'] {
  return {
    explanation: feedbackMetric.explanation,
    feedback_template_id: feedbackMetric.id!,
    operation_type: 'create',
    rating: {
      feedback_type: feedbackMetric.constraints?.feedback_type,
      value: feedbackMetric.value
    } as RatingInputOption
  };
}

export function convertFeedbackToDeleteOperation(
  feedbackMetric: FeedbackTemplate<FeedbackType>
): components['schemas']['BulkFeedbackRatingDelete'] {
  return {
    feedback_template_id: feedbackMetric.id!,
    operation_type: 'delete'
  };
}
