import {
  IconDiamond,
  IconFileSearch,
  IconGauge,
  IconLayoutList,
  IconMessage,
  IconMoodSearch,
  IconPuzzle,
  IconReportSearch,
  IconShieldHalfFilled,
  IconShieldLock,
  IconTag,
  IconTool
} from '@tabler/icons-react';

import { TablerIconsProps } from '@/core/types/icons.types';

export const METRICS_GROUP_ID = {
  CONFIG: 'config',
  CONTENT_TONE_METRICS: 'content_tone_metrics',
  CUSTOM_METRICS: 'custom_metrics',
  HUMAN_RATING: 'human_rating',
  HUMAN_RATINGS: 'human_ratings',
  INPUT_METRICS: 'input_metrics',
  INPUT_QUALITY: 'input_quality',
  LLM_PARAMETERS: 'llm_parameters',
  METADATA: 'metadata',
  OUTPUT_METRICS: 'output_metrics',
  OUTPUT_QUALITY: 'output_quality',
  PROTECT: 'protect',
  RAG_METRICS: 'rag_quality',
  RAG_PARAMETERS: 'rag_parameters',
  RAG_QUALITY_METRICS: 'rag_quality_metrics',
  SAFETY_METRICS: 'safety_metrics',
  SECURITY: 'security',
  SYSTEM_METRICS: 'system_metrics'
} as const;

export type MetricsGroupId =
  (typeof METRICS_GROUP_ID)[keyof typeof METRICS_GROUP_ID];

export const useMetricsGroup = (
  groupId: MetricsGroupId | 'default' | null | undefined
): { icon: (props: TablerIconsProps) => React.ReactNode; label: string } => {
  switch (groupId) {
    case METRICS_GROUP_ID.CONFIG:
      return {
        icon: IconTool,
        label: 'Config'
      };
    case METRICS_GROUP_ID.CONTENT_TONE_METRICS:
      return {
        icon: IconMoodSearch,
        label: 'Content Tone Metrics'
      };
    case METRICS_GROUP_ID.CUSTOM_METRICS:
      return {
        icon: IconPuzzle,
        label: 'Custom Metrics'
      };
    case METRICS_GROUP_ID.HUMAN_RATING:
    case METRICS_GROUP_ID.HUMAN_RATINGS:
      return {
        icon: IconMessage,
        label: 'Annotations'
      };
    case METRICS_GROUP_ID.INPUT_METRICS:
    case METRICS_GROUP_ID.INPUT_QUALITY:
      return {
        icon: IconDiamond,
        label: 'Input Metrics'
      };
    case METRICS_GROUP_ID.LLM_PARAMETERS:
      return {
        icon: IconLayoutList,
        label: 'LLM Parameters'
      };
    case METRICS_GROUP_ID.METADATA:
      return {
        icon: IconTag,
        label: 'Metadata'
      };
    case METRICS_GROUP_ID.OUTPUT_METRICS:
    case METRICS_GROUP_ID.OUTPUT_QUALITY:
      return {
        icon: IconDiamond,
        label: 'Output Metrics'
      };
    case METRICS_GROUP_ID.PROTECT:
      return {
        icon: IconShieldLock,
        label: 'Protect'
      };
    case METRICS_GROUP_ID.RAG_PARAMETERS:
      return {
        icon: IconFileSearch,
        label: 'RAG Parameters'
      };
    case METRICS_GROUP_ID.RAG_QUALITY_METRICS:
    case METRICS_GROUP_ID.RAG_METRICS:
      return {
        icon: IconReportSearch,
        label: 'RAG Quality Metrics'
      };
    case METRICS_GROUP_ID.SAFETY_METRICS:
      return {
        icon: IconShieldHalfFilled,
        label: 'Safety Metrics'
      };
    case METRICS_GROUP_ID.SECURITY:
      return {
        icon: IconShieldLock,
        label: 'Security Metrics'
      };
    case METRICS_GROUP_ID.SYSTEM_METRICS:
      return {
        icon: IconGauge,
        label: 'System Metrics'
      };
    default:
      return {
        icon: IconPuzzle,
        label: 'Unknown'
      };
  }
};
