import Router from 'next/router';
import { getSession } from 'next-auth/react';
import { Middleware } from 'openapi-fetch';

import { Paths } from '@/core/constants/routes.constants';
import { TASK_TYPE } from '@/core/constants/tasks-and-frameworks.constants';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

import { cspHeader } from '../../../content-security-policy';

export const CSPMiddleware: Middleware = {
  async onRequest({ request }) {
    request.headers.set('Content-Security-Policy', cspHeader);

    return request;
  }
};

export const authMiddleware: Middleware = {
  async onRequest({ request }) {
    // Fetch session data
    const session = await getSession();

    request.headers.set('Authorization', `Bearer ${session?.accessToken}`);

    return request;
  }
};

export const NlpRequestMiddleware: Middleware = {
  async onRequest({ request }) {
    const isNLP = Router.pathname === Paths.INSIGHTS;
    const isMltc = Router?.query?.taskType === TASK_TYPE.MLTC;
    const inferenceName = useParametersStore.getState().inferenceName;

    const isGetOrDelete =
      request.method === 'GET' || request.method === 'DELETE';

    const isProjectShare =
      (request.url.includes('/users') || request.url.includes('/groups')) &&
      ['PATCH', 'POST', 'PUT', 'DELETE'].includes(request.method);

    if (!isNLP || (isGetOrDelete && !inferenceName) || isProjectShare) {
      return undefined;
    }

    const _body = isGetOrDelete ? null : await request.clone().json().catch();

    if (isMltc && _body != null) {
      const task = useParametersStore.getState().task;
      const _req = request.clone();
      const body = _body ? JSON.stringify({ ..._body, task }) : undefined;

      const newReq = new Request(_req, {
        body
      });

      return newReq;
    }

    if (inferenceName) {
      const url = new URL(request.url);
      url.searchParams.set('inference_name', inferenceName);
      const body = _body ? JSON.stringify(_body) : undefined;
      const newReq = new Request(url, {
        body,
        method: request.method,
        headers: request.headers,
        credentials: request.credentials,
        redirect: request.redirect,
        referrerPolicy: request.referrerPolicy,
        mode: request.mode,
        cache: request.cache,
        integrity: request.integrity,
        keepalive: request.keepalive,
        signal: request.signal
      });

      return newReq;
    }

    return request;
  },
  async onResponse({ response }) {
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(response);
    }

    return response;
  }
};
