import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { getProcDataMetrics } from '@/fine-tune/data-parsers/get-proc-data-metrics/get-proc-data-metrics';
import { useFilterParams } from '@/fine-tune/hooks/use-filters-params/use-filter-params';
import { useDataEditsStore } from '@/fine-tune/stores/data-edits-store/data-edits.store';
import {
  useComputedParameters,
  useParametersStore
} from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';
import { getTotalSamplesIds } from '@/fine-tune/utils/get-total-samples-ids/get-total-samples-ids';

import { useInsightsRows } from '../use-insights-rows/use-insights-rows';

export const POST_DATA_EDITS =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/summary';
export const POST_DATA_EDITS_ID =
  '/projects/{project_id}/runs/{run_id}/split/{split}/insights/ids';

export const useDataEdits = () => {
  const isNer = useComputedParameters('isNer');

  const { projectId, runId } = usePathParameters();
  const split = useParametersStore((s) => s.split);

  const searchTerm = useDataEditsStore((state) => state.searchTerm);
  const isRegexSearch = useDataEditsStore((state) => state.isRegexSearch);
  const currentTab = useDataEditsStore((state) => state.currentTab);

  const selectedSpans = useStore((s) => s.selectedSpans);

  const filter_params = useFilterParams();
  const insightsRows = useInsightsRows();

  // @ts-expect-error - FIXME
  const { data } = getProcDataMetrics(insightsRows);
  const selectedSpansIds = selectedSpans.map(({ id }) => id);

  const selectedRows = useStore((state) => state.selectedRows);

  const selectedIds = getTotalSamplesIds(
    data || [],
    selectedRows,
    selectedSpansIds
  );

  const enabled = Boolean(projectId && runId && split);

  const handleGetSampleIds = async () => {
    const body = {
      include_emb: false,
      hard_easy_threshold: false,
      filter_params: {
        ...filter_params,
        ids: selectedIds,
        meta_filter: [{ name: 'is_gold', isin: [true] }]
      }
    };

    const res = await api.POST(POST_DATA_EDITS_ID, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      },
      body
    });

    return res?.data;
  };

  const ids = useQuery({
    queryKey: [
      POST_DATA_EDITS_ID,
      {
        selectedRows,
        isNer,
        projectId,
        runId,
        currentTab,
        ...filter_params
      }
    ],
    queryFn: handleGetSampleIds,
    enabled
  });

  interface QueryArgs {
    queryKey: [
      string,
      {
        idList: number[];
        searchTerm: string;
        isRegexSearch: boolean;
        selectedRows: number[];
        selectedSpans: SpanHighlightData[];
      }
    ];
  }

  const handleGetSampleValues = async (queryArgs: QueryArgs) => {
    const [, { idList, searchTerm, isRegexSearch }] = queryArgs?.queryKey || [];
    const body = {
      page_num: 0,
      include_emb: false,
      hard_easy_threshold: false,
      filter_params: {
        ids: selectedSpansIds?.length ? selectedSpansIds : idList,
        [currentTab === 'add' ? 'text_pat' : 'span_text']: searchTerm,
        regex: isRegexSearch
      }
    };

    const res = await api.POST(POST_DATA_EDITS, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        }
      },
      body
    });

    return res?.data;
  };

  const idList = ids?.data?.ids || [];

  const samples = useQuery({
    queryKey: [
      POST_DATA_EDITS,
      {
        searchTerm,
        idList,
        selectedRows,
        selectedSpans,
        isRegexSearch
      }
    ],
    queryFn: handleGetSampleValues,
    enabled: !!idList && enabled
  });

  return { ids, samples };
};
