import { Group } from '@mantine/core';
import React, { useEffect, useState } from 'react';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import MetricsGroupLabel from '@/core/components/atoms/metrics-group-label/metrics-group-label';
import ListCard, {
  ListCardProps
} from '@/core/components/molecules/list-card/list-card';
import { ModalLauncher } from '@/core/components/molecules/modal-launcher/modal-launcher';
import { useProjectPermissions } from '@/core/hooks/use-permissions/use-project-permissions';
import { ConfigureRatingsModal } from '@/core/modals/configure-ratings-modal/configure-ratings-modal';
import { FeedbackType } from '@/core/types/human-feedback.types';

import HumanFeedbackExplanation from '../human-feedback-explanation/human-feedback-explanation';
import HumanFeedbackInput, {
  HumanFeedbackInputProps
} from '../human-feedback-input/human-feedback-input';
import HumanFeedbackLabel from '../human-feedback-label/human-feedback-label';

interface HumanFeedbackCardProps extends Omit<ListCardProps, 'children'> {
  feedbackMetrics: FeedbackTemplate<FeedbackType>[];
  onChange: (
    metric: FeedbackTemplate<FeedbackType>,
    metrics: FeedbackTemplate<FeedbackType>[]
  ) => void;
  canConfigureTemplates?: boolean;
}

export const HumanFeedbackCard = ({
  feedbackMetrics = [],
  onChange,
  canConfigureTemplates = true,
  ...props
}: HumanFeedbackCardProps) => {
  // Local State
  const [cachedMetrics, setCachedMetrics] =
    useState<FeedbackTemplate<FeedbackType>[]>(feedbackMetrics);

  // Permissions
  const { currentProjectPermissions } = useProjectPermissions();
  const { configure_human_feedback, record_human_feedback } =
    currentProjectPermissions;

  // Handlers
  const handleRatingChange = (metric: FeedbackTemplate<FeedbackType>) => {
    const index = cachedMetrics.findIndex((m) => m.name === metric.name);
    const newMetrics = [...cachedMetrics];
    newMetrics[index] = metric;
    setCachedMetrics(newMetrics);
    onChange(metric, newMetrics);
  };

  // Effects
  useEffect(() => {
    setCachedMetrics(feedbackMetrics);
  }, [feedbackMetrics]);

  const title = canConfigureTemplates ? (
    <Group justify='space-between'>
      <MetricsGroupLabel groupId='human_ratings' />
      <ModalLauncher
        color='brand.5'
        modal={ConfigureRatingsModal}
        p={0}
        permissions={configure_human_feedback}
        size='compact-sm'
        style={{ fontWeight: 600 }}
        variant='transparent'
      >
        Configure
      </ModalLauncher>
    </Group>
  ) : null;
  return (
    <ListCard title={title} {...props}>
      {cachedMetrics.map((metric) => {
        return (
          <RatingCardItem
            key={`${metric.getKey()}-${metric.id}`}
            metric={metric}
            permissions={record_human_feedback}
            onChange={handleRatingChange}
          />
        );
      })}
    </ListCard>
  );
};

interface RatingCardItemProps extends HumanFeedbackInputProps {}

const RatingCardItem: React.FC<RatingCardItemProps> = ({
  metric,
  permissions,
  onChange
}) => {
  return (
    <ListCard.Item data-testid={`${metric?.constraints?.feedback_type}-item`}>
      <Group justify='space-between' w='100%'>
        <HumanFeedbackLabel metric={metric} />
        <HumanFeedbackInput
          metric={metric}
          permissions={permissions}
          onChange={onChange}
        />
        <HumanFeedbackExplanation
          metric={metric}
          permissions={permissions}
          onChange={onChange}
        />
      </Group>
    </ListCard.Item>
  );
};
