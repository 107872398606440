import check from 'check-types';
import _snakeCase from 'lodash/snakeCase';
import _unique from 'lodash/uniq';

import {
  PII_VALUES,
  PROMPT_INJECTION_VALUES,
  PROTECT_OPTIONS,
  TONE_VALUES
} from '@/core/constants/filters.constants';
import { components } from '@/core/types/api';
import { EvaluateFilterType } from '@/core/types/filters.types';
import { MetricGroup } from '@/core/types/metric-config.types';
import { MetricStatusType } from '@/core/types/metric-status.types';
import {
  MetricsColumn,
  MetricsGroupedColumns,
  MetricsRow
} from '@/core/types/metrics-table.types';
import { isSuccessMetricStatus } from '@/core/utils/is-metric-status/is-metric-status';
import {
  DataTypes,
  UnitFormatMap
} from '@/core/utils/unit-conversions/unit-format-mapping';
export interface MetricsGroup {
  group_label: string;
  group_name?: string | null | undefined;
  group_description?: string | null | undefined;
  group_icon?: string | undefined;
  columns: string[];
}

export interface EvaluateFilterColumnConfig {
  accessor: string;
  label: string;
  formatter?: (value: any) => string | number | JSX.Element;
  filterType?: EvaluateFilterType;
  values?: string[];
  sortable?: boolean;
  decimals?: number;
  dataType?: DataTypes;
}

const getMetricThresholdConfig = (
  values?: number[],
  labels?: string[],
  inverted?: boolean
) => {
  return {
    inverted: Boolean(inverted),
    neutralLabel: labels?.length === 3 ? labels[1] : labels?.[0],
    high: values?.[1] ?? values?.[0],
    highLabel: labels?.[labels.length - 1],
    low: values?.[0],
    lowLabel: labels?.[0]
  };
};

// Helpers to assemble table from endpoint metrics
export const getMetricsColumns = (
  metrics: components['schemas']['PromptRowColumn'][]
) => {
  let uniqueMetrics = [
    ...new Map(metrics.map((metric) => [metric.name, metric])).values()
  ];

  return uniqueMetrics.map((metric) => {
    // Default to the metric data type
    let format: DataTypes | 'pii' | 'protect_status' | undefined =
      metric.data_type;

    if (metric.name === 'pii') {
      format = 'pii';
    }

    if (metric.name === 'protect_status') {
      format = 'protect_status';
    }

    return {
      alignment: metric.name === 'id' ? 'left' : undefined,
      name: metric.name,
      // @ts-expect-error
      originalName: metric.originalName,
      // @ts-expect-error - TEMPORARY, will come from the API
      improvable: metric.improvable,
      label: metric.label ?? metric.name,
      description: metric.description,
      group_name: metric.group_name,
      format,
      threshold: getMetricThresholdConfig(
        metric?.metric_threshold?.buckets,
        metric?.metric_threshold?.display_value_levels,
        metric?.metric_threshold?.inverted
      ),
      job_status: metric?.job_info?.[0]?.job_status,
      job_type: metric?.job_type,
      job_error_message: metric?.job_info?.[0]?.job_error_message,
      alert: {
        alert_title: metric.alert?.alert_title,
        alert_message: metric.alert?.alert_message
      },
      sortable: metric.sortable
    };
  }) as MetricsColumn[];
};

const categoricalColumns = [
  'input_pii',
  'pii',
  'output_pii',
  'input_tone',
  'output_tone',
  'tone',
  'prompt_injection'
];
const rangeColumns = [
  'average_uncertainty',
  'average_bleu',
  'average_rouge',
  'average_toxicity',
  'average_chunk_utilization',
  'average_completeness_gpt',
  'average_factuality',
  'average_hallucination',
  'groundedness',
  'instruction_adherence',
  'rag_nli_adherence',
  'completeness_gpt',
  'rag_nli_completeness',
  'retriever_utilization',
  'rag_nli_retriever_utilization',
  'rag_nli_retriever_relevance',
  'context_relevance',
  'factuality'
];

const valueTypes: DataTypes[] = [
  'floating_point',
  'integer',
  'milli_seconds',
  'percentage',
  'dollars'
];

const textTypes: DataTypes[] = [
  'label',
  'text',
  'template_label',
  'user_id',
  'uuid'
];

const getColumnsNames = (columns: string[], isSingleRun?: boolean) => {
  return columns.map((column) =>
    isSingleRun ? column.replace('average_', '') : column
  );
};

const parsedColumnName = (
  column: components['schemas']['PromptRunColumn'],
  isSingleRun?: boolean
) =>
  isSingleRun && column.name.startsWith('_')
    ? column.name.substring(1)
    : column.name!;

export const getFilterType = (
  column: components['schemas']['PromptRunColumn'],
  isSingleRun?: boolean
): EvaluateFilterType | undefined => {
  if (
    getColumnsNames(rangeColumns, isSingleRun).includes(
      parsedColumnName(column, isSingleRun)
    )
  ) {
    return 'range';
  }

  if (
    getColumnsNames(categoricalColumns, isSingleRun).includes(
      parsedColumnName(column, isSingleRun)
    )
  ) {
    return 'category';
  }

  if (
    (isSingleRun && column.group_name === 'dataset') ||
    ['generic_parameters', 'rag_parameters'].includes(column.group_name!)
  ) {
    return 'text';
  }

  if (column.group_name === 'custom_metrics') {
    if (column.filterable) {
      if (valueTypes.includes(column.data_type!)) {
        return 'value';
      }
      if (column.data_type === 'text') {
        return 'text';
      }
    }
  }

  if (column.filterable) {
    return textTypes.includes(column.data_type!) ? 'text' : 'value';
  }

  return undefined;
};

const getCategoryValues = (name: string) => {
  if (name.includes('tone')) {
    return TONE_VALUES;
  } else if (name.includes('pii')) {
    return PII_VALUES;
  } else if (name.includes('prompt_injection')) {
    return PROMPT_INJECTION_VALUES;
  } else if (name.includes('protect_status')) {
    return PROTECT_OPTIONS;
  }
  return [];
};

export const getMetricsColumnsConfig = (
  columns: components['schemas']['PromptRunColumn'][],
  isSingleRun?: boolean
): EvaluateFilterColumnConfig[] => {
  return columns
    .map((column) => {
      const filterType = getFilterType(column, isSingleRun);

      const values =
        filterType === 'category'
          ? getCategoryValues(column.name)
          : _unique(
              column.values
                ?.filter((val) => (check.array(val) ? val.length : val != null))
                .flatMap((values) =>
                  check.array(values) ? (values as string[]) : String(values)
                )
            );

      const parsedType =
        column?.data_type === 'dollars'
          ? 'parsed__dollars'
          : column?.data_type!;

      return {
        accessor: column.name,
        label: column.label ?? '',
        formatter: UnitFormatMap[parsedType],
        filterType,
        values,
        decimals: column.data_type === 'integer' ? 0 : 3,
        sortable: column.sortable,
        dataType: column.data_type
      };
    })
    .filter((column) => column.filterType && column.values?.length);
};

const getMetricsWithParsedNames = (
  metrics: components['schemas']['PromptRowColumn'][]
) => {
  // Don't mutate the original metrics as we need them for filtering
  return metrics.map((metric) => {
    const originalName = metric.name;
    let name;
    if (['human_rating', 'custom_metrics'].includes(metric.group_name!)) {
      // the name of a human rating metric is feedback template UUID,
      // so it should not be altered. Same for custom metrics.
      name = metric.name;
    } else {
      name = _snakeCase(metric.name);
    }

    return {
      ...metric,
      name,
      originalName
    };
  });
};

export const getMetricsGroupedColumns = (
  orderedGroups: MetricGroup[],
  _metrics: components['schemas']['PromptRowColumn'][]
) => {
  const metrics = getMetricsWithParsedNames(_metrics);
  //  Get metrics groups
  const groupKeys = [
    ...new Set(
      metrics
        .filter((metric) => !!metric.group_name)
        .map((metric) => metric.group_name)
    )
  ];

  // Get ordered metrics groups
  const filteredOrderedGroups =
    orderedGroups?.filter((orderedGroup) =>
      groupKeys.includes(orderedGroup.name)
    ) ?? [];

  const groups = filteredOrderedGroups.map((filteredOrderedGroup) => {
    const group = metrics.find(
      (metric) => metric.group_name === filteredOrderedGroup.name
    );

    // Get ordered metric group column order
    const groupedColumns = getMetricsColumns(metrics).filter(
      (metric) => metric.group_name === group?.group_name
    );
    const orderedGroupedColumns: MetricsColumn[] = [];

    filteredOrderedGroup?.metricKeys?.forEach((orderedColumn) => {
      groupedColumns.forEach((column) => {
        if (orderedColumn === column.name) orderedGroupedColumns.push(column);
      });
    });

    // Get custom columns
    groupedColumns.forEach((column) => {
      if (!orderedGroupedColumns.includes(column))
        orderedGroupedColumns.push(column);
    });

    return {
      name: group?.group_name,
      label: group?.group_label,
      description: group?.group_description,
      columns: orderedGroupedColumns
    };
  }) as MetricsGroupedColumns[];

  return groups;
};

export const getMetricsUngroupedColumns = (
  _metrics: components['schemas']['PromptRowColumn'][]
) => {
  const metrics = getMetricsWithParsedNames(_metrics);

  const filteredMetrics = metrics.filter((metric) => !metric.group_name);

  return getMetricsColumns(filteredMetrics);
};

export const getMetricsRows = (
  _metrics: components['schemas']['PromptRowColumn'][]
) => {
  const requiredCells = [
    'id',
    'chain_id',
    'chain_root_id',
    'node_id',
    'node_name',
    'node_type',
    'has_children'
  ];

  const metrics = getMetricsWithParsedNames(_metrics);
  const rows = metrics
    .find((metric) => metric.name === 'id')
    ?.metric_infos?.reduce((validRows: MetricsRow[], id, rowIndex) => {
      if (isSuccessMetricStatus(id)) {
        const row: MetricsRow = {
          id: id.value as string
        };

        metrics.forEach((metric) => {
          if (metric.name !== 'id') {
            let metricInfo = metric?.metric_infos?.[rowIndex];

            if (requiredCells.includes(metric.name)) {
              const value = isSuccessMetricStatus(metricInfo)
                ? metricInfo.value
                : undefined;
              row[metric.name] = value;
            } else {
              row[metric.name] = metricInfo;
              row[metric.name].label = metric.label;
              row[metric.name].format = metric.data_type;
              row[metric.name].threshold = getMetricThresholdConfig(
                metric?.metric_threshold?.buckets,
                metric?.metric_threshold?.display_value_levels,
                metric?.metric_threshold?.inverted
              );
            }
          }
        });

        validRows.push(row);
      }
      return validRows;
    }, []) as MetricsRow[];

  return rows;
};

export const getCellValue = (cell: MetricStatusType) =>
  isSuccessMetricStatus(cell) ? (cell.value as any) : undefined;
export const getCellString = (cell: MetricStatusType) =>
  isSuccessMetricStatus(cell) && typeof cell.value === 'string'
    ? (cell.value as string)
    : undefined;
export const getCellNumber = (cell: MetricStatusType) =>
  isSuccessMetricStatus(cell) && typeof cell.value === 'number'
    ? (cell.value as number)
    : undefined;
