import {
  Box,
  BoxProps,
  Overlay,
  OverlayProps,
  Tooltip,
  TooltipProps
} from '@mantine/core';
import omit from 'lodash/omit';
import { PropsWithChildren } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

export interface PermissionOverlayProps extends BoxProps {
  allowed?: boolean;
  message?: string | null;
  overlayProps?: OverlayProps;
  tooltipProps?: TooltipProps;
}

export function PermissionOverlay({
  children,
  message,
  overlayProps,
  tooltipProps,
  ...props
}: PropsWithChildren<PermissionOverlayProps>) {
  const boxProps = omit(props, 'allowed');
  return (
    <Tooltip
      disabled={!message}
      label={message}
      {...tooltipProps}
      multiline
      maw={250}
    >
      <Box {...boxProps} pos='relative'>
        <Overlay
          backgroundOpacity={0.5}
          color='#fff'
          zIndex={Z_INDEX.DROPDOWNS}
          {...overlayProps}
        />
        {children}
      </Box>
    </Tooltip>
  );
}
