import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';
import { InsightsRowsResponse } from '@/fine-tune/types/query.types';
import { updateEditedRows } from '@/fine-tune/utils/update-edited-rows/update-edited-rows';

import { GET_EDIT } from '../use-edit-by-id/use-edit-by-id';
import { GET_EDITS } from '../use-edits/use-edits';
import { useInsightsRowsQueryKey } from '../use-insights-rows/use-insights-rows-query-key';

export const DELETE_EDIT = '/edits/{edit_id}';

/**
 * useEditRemove
 *
 */
export const useEditRemove = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);

  const queryClient = useQueryClient();

  const insightsRowsKey = useInsightsRowsQueryKey();

  const handleRemoveEdit = async ({
    editId
  }: {
    editId: string;
    editSamplesIds: number[];
  }) => {
    const res = await api.DELETE(DELETE_EDIT, {
      params: {
        path: { edit_id: editId }
      }
    });

    return res?.data;
  };

  return useMutation({
    mutationFn: handleRemoveEdit,
    onSuccess: (_, editParams) => {
      const samplesIds = editParams?.editSamplesIds || [];
      queryClient.setQueryData(
        insightsRowsKey,
        (oldData: { pages: InsightsRowsResponse[] }) => {
          return updateEditedRows({ oldData, samplesIds, isDeleting: true });
        }
      );
      queryClient.invalidateQueries({ queryKey: [GET_EDITS] });
      queryClient.invalidateQueries({
        queryKey: [
          GET_EDIT,
          { projectId, runId, split, editId: editParams.editId }
        ]
      });
    },
    onError: (res: Response) => defaultErrorHandler(res, 'Error removing edit')
  });
};
