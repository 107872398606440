import { Text } from '@mantine/core';
import cx from 'classix';
import React, { memo, useEffect, useState } from 'react';

import LabelledWord from '@/fine-tune/components/labelled-word/labelled-word';
import { parseSpanHighlightData } from '@/fine-tune/data-parsers/parse-span-highlight-data/parse-span-highlight-data';
import { useComputedParameters } from '@/fine-tune/stores/parameters-store';
import useStore from '@/fine-tune/stores/store';
import { SpanHighlightData } from '@/fine-tune/types/span-highlight.types';

import LabeledWordPopover from './labeled-word-popover/labeled-word-popover';

interface SpanHighlightProps {
  allowSelection?: boolean;
  columnId?: 'gold' | 'pred';
  maxHeight?: string;
  newLabel?: string;
  size?: string;
  spans?: SpanHighlightData[] | null;
  text?: string;
  truncated?: boolean;
  fromEmbeddings?: boolean;
  rowId?: number;
}

const SpanHighlight = ({
  text = '',
  spans = [],
  columnId,
  size = 'md',
  newLabel,
  truncated = false,
  allowSelection = true,
  maxHeight = '100%',
  fromEmbeddings = false,
  rowId
}: SpanHighlightProps) => {
  const isInferenceNer = useComputedParameters('isInferenceNer');

  const [isTruncated, setIsTruncated] = useState(truncated);

  const {
    colorMap,
    selectedSpans,
    selectedRows,
    setSelectedSpans,
    hiddenSpans
  } = useStore((s) => ({
    colorMap: s.colorMap,
    selectedSpans: s.selectedSpans,
    selectedRows: s.selectedRows,
    setSelectedSpans: s.actions.setSelectedSpans,
    hiddenSpans: s.hiddenSpans
  }));

  const isSelected = (id?: number | null) => {
    if (allowSelection && id) {
      return Boolean(
        selectedSpans?.find((s) => s.id === id) ||
          selectedRows?.includes(rowId as number)
      );
    }
    return false;
  };

  const { spanArray, centeredIdx } = parseSpanHighlightData({
    text,
    // @ts-expect-error - FIXME
    spans,
    columnId,
    centeringValue: isInferenceNer ? 'confidence' : 'data_error_potential',
    hiddenSpans
  });

  const renderSpanHighlight = () => {
    if (spanArray?.length) {
      let renderArray = spanArray;

      if (isTruncated && (centeredIdx !== 0 || fromEmbeddings)) {
        const visibleSpans = spanArray.slice(centeredIdx);
        if (fromEmbeddings) {
          // Embeddings sends the whole text and only the active span data,
          // so we need to manually trim the text to ensure the actual span is displayed
          const firstVisibleSpan = visibleSpans[0];
          // Instead of setting the whole text as leading, we just get the last 2 words
          firstVisibleSpan.leading = firstVisibleSpan?.leading
            ?.split(' ')
            ?.slice(-3)
            ?.join(' ');
        }

        renderArray = [
          {
            leading: '...',
            trailing: null,
            span: null,
            isCenteredSpan: false,
            isActive: false,
            isBordered: false,
            label: null,
            isFilled: false,
            isNotRealSpan: false
          },
          ...visibleSpans
        ];
      }
      return renderArray?.map(
        ({
          leading,
          trailing,
          span,
          label,
          id,
          isBordered,
          isFilled,
          isCenteredSpan,
          isNotRealSpan,
          isActive,
          dep,
          errorType,
          confidence
        }) => (
          <span
            className={cx(isCenteredSpan && 'highest-dep')}
            key={`${label}-${id}`}
          >
            {leading}
            {isNotRealSpan && span}
            {span && !isNotRealSpan && (
              <LabelledWord
                color={label ? colorMap[label]?.text : 'inherit'}
                isActive={Boolean(isActive)}
                isBordered={Boolean(isBordered)}
                isCenteredSpan={Boolean(isCenteredSpan)}
                isFilled={Boolean(isFilled)}
                isSelected={isSelected(id)}
                isTruncated={isTruncated}
                label={newLabel && isActive ? newLabel : label}
                oldLabel={newLabel && isActive ? label : null}
                popoverDropdown={
                  <LabeledWordPopover
                    confidence={confidence || 0}
                    dep={dep || 0}
                    errorType={errorType || ''}
                  />
                }
                size={size}
                spanId={id as number}
                word={span}
                onClick={allowSelection && isActive ? _onClick : undefined}
              />
            )}
            {trailing}
          </span>
        )
      );
    }
  };

  useEffect(() => {
    setIsTruncated(truncated);
  }, [truncated]);

  const _onClick = (id: number) => {
    const span = spans?.find((s) => s.id === id) as SpanHighlightData;
    setSelectedSpans(span);
  };

  return (
    <Text
      component='span'
      data-testid='span-highlight'
      lineClamp={isTruncated ? 1 : undefined}
      size='sm'
      style={{
        maxHeight
      }}
    >
      {renderSpanHighlight()}
    </Text>
  );
};

export default memo(SpanHighlight);
