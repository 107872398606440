import { useEffect } from 'react';

import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { FeatureFlagResponse } from '@/core/stores/feature-flags-store/feature-flags.store.types';

interface FlagsProviderProps {
  children?: React.ReactNode;
  featureFlags?: FeatureFlagResponse;
}

const FlagsProvider = ({ children, featureFlags }: FlagsProviderProps) => {
  const { setFeatureFlags } = useFeatureFlagsStore((state) => state.actions);

  useEffect(() => {
    if (featureFlags) {
      setFeatureFlags(featureFlags);
    }
  }, [featureFlags]);

  return <>{children}</>;
};

export default FlagsProvider;
