import {
  Group,
  NumberInput,
  NumberInputProps,
  Text,
  TextProps
} from '@mantine/core';
import { useEffect, useState } from 'react';

import { STRING_PLACEHOLDER } from '@/core/constants/strings.constants';
import { Permission } from '@/core/types/user-permissions.types';

interface ScoreRaterProps extends NumberInputProps {
  max: number;
  min: number;
  permissions?: Permission;
  suffixProps?: TextProps;
  withSuffix?: boolean;
}

export const ScoreRater = ({
  min = 0,
  max,
  permissions,
  withSuffix = true,
  suffixProps = {},
  value = '',
  onChange,
  ...props
}: ScoreRaterProps) => {
  const [inputValue, setInputValue] = useState(value);
  const inputValueAsNumber =
    typeof inputValue === 'string' ? parseFloat(inputValue) : inputValue;

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Group data-testid='score-rater' gap='xs'>
      {!permissions?.allowed && (
        <Text c='gray.6' size='sm'>
          {inputValue === '' ? STRING_PLACEHOLDER : inputValue}
        </Text>
      )}
      {permissions?.allowed && (
        <NumberInput
          error={
            inputValueAsNumber > max || inputValueAsNumber < min
              ? 'Out of range'
              : undefined
          }
          max={max}
          min={min}
          value={inputValue}
          w={75}
          onBlur={() => onChange?.(inputValue)}
          onChange={setInputValue}
          {...props}
        />
      )}
      {withSuffix && (
        <Text c='gray.6' size='sm' {...suffixProps}>
          out of {max}
        </Text>
      )}
    </Group>
  );
};
