import {
  Avatar as MantineAvatar,
  AvatarProps,
  Box,
  darken as MantineDarken,
  DefaultMantineColor,
  lighten as MantineLighten,
  MantineSize,
  Skeleton,
  useMantineTheme
} from '@mantine/core';
import check from 'check-types';
import React, {
  Dispatch,
  forwardRef,
  KeyboardEvent,
  RefObject,
  SetStateAction
} from 'react';

const darken = 0.5;
const lighten = 0.3;

type Letter =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';

interface ColorData {
  color: DefaultMantineColor;
  darken?: number;
  lighten?: number;
}

const INITIAL_MAP: Record<Letter, ColorData> = {
  A: { color: 'red' },
  B: { color: 'pink' },
  C: { color: 'grape' },
  D: { color: 'violet' },
  E: { color: 'green' },
  F: { color: 'yellow' },
  G: { color: 'cyan' },
  H: { color: 'teal' },
  I: { color: 'green' },
  J: { color: 'lime' },
  K: { color: 'yellow' },
  L: { color: 'orange' },
  //  Darken
  M: { color: 'red', darken },
  N: { color: 'pink', darken },
  O: { color: 'grape', darken },
  P: { color: 'violet', darken },
  Q: { color: 'cyan', darken },
  R: { color: 'yellow', darken },
  S: { color: 'cyan', darken },
  T: { color: 'teal', darken },
  U: { color: 'green', darken },
  V: { color: 'lime', darken },
  W: { color: 'yellow', darken },
  X: { color: 'orange', darken },
  //   Lighten
  Y: { color: 'yellow', lighten },
  Z: { color: 'orange', lighten }
};

const SKELETON_SIZES = {
  xs: 16,
  sm: 26,
  md: 38,
  lg: 56,
  xl: 84
};

interface IAvatarProps extends AvatarProps {
  name?: string;
  onClick?: () => void;
  size?: MantineSize;
  isLoading?: boolean;
  mt?: MantineSize | number;
  fontSize?: number;
}

const Avatar = forwardRef(
  (
    {
      name = '',
      onClick,
      size = 'md',
      isLoading = false,
      fontSize,
      ...props
    }: IAvatarProps,
    ref: React.Ref<HTMLInputElement> | Dispatch<SetStateAction<HTMLElement>>
  ) => {
    const theme = useMantineTheme();
    const heightAndWidth =
      SKELETON_SIZES[size as 'xs' | 'sm' | 'md' | 'lg' | 'xl'];

    // Dashed iteration for pending users
    if (!name) {
      return (
        <Box
          data-testid='blank-avatar'
          h={heightAndWidth}
          style={{
            borderRadius: '100%',
            border: `1px dashed var(--mantine-color-contrast-2)`
          }}
          w={heightAndWidth}
        />
      );
    }

    const firstLetter = name.charAt(0)?.toUpperCase() || '?';

    const {
      color,
      darken = 0,
      lighten = 0
    } = INITIAL_MAP[firstLetter as Letter] || { color: 'gray' };

    if (isLoading) {
      return (
        <Skeleton
          circle
          data-testid='avatar-skeleton'
          height={heightAndWidth}
        />
      );
    }
    const baseColor = theme?.colors?.[color]?.[6] || theme?.colors?.brand[6];

    return (
      <MantineAvatar
        alt={name}
        aria-label='Open menu'
        color={color || 'gray'}
        data-testid='avatar'
        radius={100}
        ref={ref as RefObject<HTMLDivElement>}
        role='button'
        size={size}
        style={{
          alignSelf: 'center',
          cursor: check.function(onClick) ? 'pointer' : undefined
        }}
        styles={{
          placeholder: {
            paddingTop: size === 'md' ? 2 : 0,
            backgroundColor: darken
              ? MantineDarken(baseColor, darken)
              : MantineLighten(baseColor, lighten),
            color: 'white',
            fontSize
          }
        }}
        tabIndex={check.function(onClick) ? 0 : -1}
        onClick={() => {
          onClick?.();
        }}
        onKeyDown={({ key }: KeyboardEvent<HTMLDivElement>) => {
          if (key === ' ' || key === 'Enter') {
            onClick?.();
          }
        }}
        {...props}
      >
        {firstLetter}
      </MantineAvatar>
    );
  }
);

export default Avatar;
