import {
  Box,
  Button,
  Center,
  CopyButton,
  Flex,
  Group,
  Text,
  TextInput
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCheck, IconCopy, IconInfoCircle } from '@tabler/icons-react';
import { ChangeEvent, useState } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useAPIKeys } from '@/core/hooks/query-hooks/use-api-keys/use-api-keys';
import { useModals } from '@/core/hooks/use-modals/use-modals';

const CreateAPIKeyModal = () => {
  const [name, setName] = useState('');
  const oneYearFromNow = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  const [expiresAt, setExpiresAt] = useState<Date | null>(oneYearFromNow);
  const [key, setKey] = useState('');

  const { closeAll } = useModals();

  const { createAPIKey } = useAPIKeys();

  const handleNameUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = () => {
    createAPIKey.mutate(
      { description: name, expiresAt },
      {
        onSuccess(response) {
          setKey(response.data?.api_key || '');
        }
      }
    );
  };

  const tokenCreated = createAPIKey.isSuccess && key;

  return (
    <Box data-testid='create-api-key-modal'>
      <Box p='lg'>
        {tokenCreated ? (
          <>
            <Text c='gray.7' size='sm'>
              Please save this secret key somewhere safe and accessible. For
              security reasons,
              <Text component='span' fw={700} mx={4}>
                you won&apos;t be able to view it again
              </Text>
              through your Galileo account.
            </Text>
            <Flex justify='flex-start' py='xs'>
              <TextInput
                readOnly
                color='gray.7'
                size='sm'
                value={key}
                w='95%'
              />
              <Center ml='sm'>
                <CopyButton value={key}>
                  {({ copied, copy }) =>
                    copied ? (
                      <IconCheck color='green' size={20} />
                    ) : (
                      <IconCopy color='gray' size={20} onClick={copy} />
                    )
                  }
                </CopyButton>
              </Center>
            </Flex>
          </>
        ) : (
          <>
            <TextInput
              label='Name'
              placeholder='Insert name of new key here'
              value={name}
              onChange={handleNameUpdate}
            />
            <DateInput
              label='Expiration Date'
              minDate={new Date()}
              mt={6}
              popoverProps={{
                zIndex: Z_INDEX.TOOLTIPS
              }}
              value={expiresAt}
              onChange={setExpiresAt}
            />
            <Group>
              <Flex justify='flex-start' py='xs'>
                <IconInfoCircle color='#9B98AE' size={24} />
                <Text
                  c='gray.6'
                  component='span'
                  fw={400}
                  ml='xs'
                  mt={4}
                  size='xs'
                >
                  For production monitoring, we recommend generating keys under
                  shared alias accounts (e.g. your-team@your-company.com).
                </Text>
              </Flex>
            </Group>
          </>
        )}
      </Box>
      <Box
        p='md'
        style={{
          background: '#F4F4F6',
          borderTop: `1px solid #E9E8ED`
        }}
      >
        <Group justify='flex-end'>
          {tokenCreated ? (
            <Button
              color='gray'
              radius='md'
              variant='default'
              onClick={() => closeAll()}
            >
              Done
            </Button>
          ) : (
            <Button
              data-testid='create-api-key-btn'
              disabled={!name}
              loading={createAPIKey.isPending}
              radius='md'
              onClick={handleSubmit}
            >
              Create
            </Button>
          )}
        </Group>
      </Box>
    </Box>
  );
};

export default CreateAPIKeyModal;
