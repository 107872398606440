import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { PROJECT_RUN_METRICS_PATH } from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

export const useRunGlobalMetrics = () => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);

  const enabled = Boolean(projectId && runId && split);

  const fetchRunMetrics = async () => {
    const res = await api.GET(PROJECT_RUN_METRICS_PATH, {
      params: {
        path: {
          project_id: projectId!,
          run_id: runId!
        }
      }
    });

    return res.data;
  };

  const response = useQuery({
    queryKey: [PROJECT_RUN_METRICS_PATH, { projectId, runId, split }],
    queryFn: () => fetchRunMetrics(),
    enabled
  });

  const featureImportances =
    response?.data?.find((metric) => metric.key === 'feature_importances')
      ?.extra || {};

  return {
    ...response,
    featureImportances
  };
};
