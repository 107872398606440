import { useInfiniteQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { EVALUATE_COLUMNAR_RUNS_PATH } from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { MetricsGroupedColumns } from '@/core/types/metrics-table.types';
import { useMetricGroups } from '@/evaluate/hooks/query-hooks/use-metric-groups/use-metric-groups';
import { useQueryParameters } from '@/evaluate/hooks/use-query-parameters/use-query-parameters';

import {
  getMetricsGroupedColumns,
  getMetricsRows,
  getMetricsUngroupedColumns
} from '../utils';

/**
 * @deprecated Use useProjectPromptResults instead
 */

export const useMetricsPromptRuns = (
  limit: number = 25,
  refetchInterval: number = 15000
) => {
  // Parameters
  const { projectId } = usePathParameters();

  const { get } = useQueryParameters();

  let sortBy = get('sortBy');
  const sortDirection = get('sortDirection');
  const filters = get('filters');

  if (!sortBy || sortBy === 'data_error_potential') {
    sortBy = 'created_at';
  }

  // Hooks
  const { groups } = useMetricGroups();

  // Dependencies
  const dependencies = [projectId];
  const enabled = dependencies.every(Boolean); // Only enable if all dependencies are truthy

  // Query
  const queryKey = [
    EVALUATE_COLUMNAR_RUNS_PATH,
    { projectId, sortBy, sortDirection, filters }
  ];

  const queryFn = async ({ pageParam = 0 }) => {
    const res = await api.POST(EVALUATE_COLUMNAR_RUNS_PATH, {
      params: {
        query: {
          starting_token: pageParam,
          limit,
          sort_by: sortBy,
          sort_ascending: sortDirection === 'asc'
        },
        path: {
          project_id: projectId!
        }
      },
      body: {
        filters: filters
      }
    });

    return res.data;
  };

  // Response
  const response = useInfiniteQuery({
    queryKey,
    queryFn,
    initialPageParam: 0,
    enabled,
    getNextPageParam: (lastPage) => lastPage?.next_starting_token,
    refetchInterval: refetchInterval <= 0 ? false : refetchInterval
  });

  // Columns
  const columns = response.data?.pages[0]?.columns ?? [];

  const ungroupedColumns = getMetricsUngroupedColumns(columns) ?? [];

  const groupedColumns = getMetricsGroupedColumns(groups ?? [], columns) ?? [];

  const rows =
    response.data?.pages.flatMap((page) =>
      getMetricsRows(page?.columns ?? [])
    ) ?? [];

  // Runs Details
  const runsDetails: MetricsGroupedColumns[] = [];

  const ragParameters = {
    ...groupedColumns?.find((group) => group.name === 'rag_parameters')
  } as MetricsGroupedColumns;

  if (ragParameters) {
    ragParameters.columns = ragParameters?.columns?.filter(
      (column) =>
        column.name !== 'model_alias' && column.name !== 'template_name'
    );
    ragParameters.label = 'RAG Parameters';
    runsDetails.push(ragParameters);
  }

  return {
    ...response,
    ungroupedColumns,
    groupedColumns,
    rows,
    runsDetails
  };
};
