/**
 * This file contains all the routes used in the application.
 * 🛑 IMPORTANT 🛑 If you add any new PUBLIC routes, you must add them to middleware.ts.
 * Otherwise the routes will be auth protected.
 */

// Internal
export enum Paths {
  ROOT = '/',
  CHANGELOG = '/changelog',
  COMPARE_RUNS = '/compare-runs',
  CREATE_NEW_PROJECT = '/create-new-project',
  CREATE_NEW_RUN = '/create-new-run',
  DATASETS = '/datasets',
  FORGOT_PASSWORD = '/forgot-password',
  GET_TOKEN = '/get-token',
  INSIGHTS = '/insights/[projectId]/[runId]',
  INSIGHTS_ROOT = '/insights',
  INTEGRATIONS = '/integrations',
  NEW_PROJECT = '/new-project',
  NOT_FOUND = '/not-found',
  OBSERVE = '/observe/[projectId]',
  OBSERVE_SETTINGS = '/observe/[projectId]/settings',
  PROMPT = '/prompt',
  PROMPT_CHAINS = '/prompt/chains',
  PROMPT_CHAINS_COMPARE = '/prompt/chains/[projectId]/compare',
  PROMPT_CHAINS_PROMPTS = '/prompt/chains/[projectId]/[runId]',
  PROMPT_CHAINS_RUNS = '/prompt/chains/[projectId]',
  PROMPT_COMPARE = '/prompt/[projectId]/compare',
  PROMPT_CREATE_RUN = '/prompt/[projectId]/create-run',
  PROMPT_PROMPTS = '/prompt/[projectId]/[runId]',
  PROMPT_RUNS = '/prompt/[projectId]',
  PROMPT_GET_STARTED = '/prompt/[projectId]/get-started',
  REQUEST_ACCESS = '/request-access/[projectId]',
  REQUEST_INVITE = '/request-invite',
  RESET_PASSWORD = '/reset-password',
  SETTINGS_API_KEYS = '/settings/api-keys',
  SETTINGS_INTEGRATIONS = '/settings/integrations',
  SETTINGS_PERMISSIONS = '/settings/permissions',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  // External
  EXTERNAL_GALILEO_DOCS = 'https://docs.rungalileo.io/galileo/'
}
// Note: This is a list of public URLs that do not require authentication.
export const publicUrls: string[] = [
  Paths.FORGOT_PASSWORD,
  Paths.SIGN_IN,
  Paths.SIGN_UP,
  Paths.RESET_PASSWORD,
  Paths.REQUEST_INVITE,
  Paths.EXTERNAL_GALILEO_DOCS
];
