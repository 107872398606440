import {
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  Paper,
  ScrollArea,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  useMantineTheme
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { IconSearch } from '@tabler/icons-react';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { usePromptExport } from '@/evaluate/hooks/query-hooks/use-prompt-export/use-prompt-export';
import { usePromptStore } from '@/evaluate/stores/prompt-store/prompt.store';

import { usePromptExportModal } from './use-prompt-export-modal';

type CustomContextModalProps = ContextModalProps & {
  innerProps?: {
    name: string;
    isChain: boolean;
  };
};
/**
 *
 *
 * @returns
 */
const PromptExportModal = ({ innerProps }: CustomContextModalProps) => {
  const activeTable = usePromptStore((s) => s.activeTable);

  // Query Hooks
  const exportPrompt = usePromptExport();
  const { filterColumns, groupChecked, groupIndeterminate, handleCheck } =
    usePromptExportModal();

  const [columnSelection, setColumnSelection] = useState<
    'all' | 'none' | 'visible'
  >('all');
  const [columnSearch, setColumnSearch] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    activeTable.activeColumns ?? []
  );
  const [includeChains, setIncludeChains] = useState(innerProps.isChain);
  const [fileName, setFileName] = useState(innerProps.name);
  const [fileType, setFileType] = useState<'csv' | 'jsonl'>('csv');

  // Utility Hooks
  const { colors } = useMantineTheme();
  const { closeAll } = useModals();

  const exportedColumns = selectedColumns
    .map((column) => {
      let id: string | undefined = undefined;

      activeTable.ungroupedColumns.forEach((ungroupedColumn) => {
        if (column === ungroupedColumn.name) id = ungroupedColumn.originalName;
      });
      activeTable.groupedColumns.forEach((group) => {
        group.columns.forEach((groupedColumn) => {
          if (column === groupedColumn.name) id = groupedColumn.originalName;
        });
      });
      return id;
    })
    .filter((column) => column !== undefined);

  const handleExport = () => {
    exportPrompt.mutate(
      {
        name: innerProps.name,
        includeChains,
        fileName,
        fileType,
        columns: exportedColumns
      },
      {
        onSuccess: () => closeAll()
      }
    );
  };

  const hasSearchResults =
    filterColumns(activeTable.ungroupedColumns, columnSearch).length ||
    activeTable.groupedColumns.some(
      (group) => filterColumns(group.columns, columnSearch).length
    );

  const allColumns = [
    ...activeTable.ungroupedColumns.map((column) => column.name),
    ...activeTable.groupedColumns.flatMap((group) =>
      group.columns.map((column) => column.name)
    )
  ];

  const ColumnSelectionButtons: {
    value: 'all' | 'none' | 'visible';
    label: string;
    columns: string[];
  }[] = [
    {
      value: 'all',
      label: 'All',
      columns: allColumns
    },
    {
      value: 'none',
      label: 'None',
      columns: []
    },
    {
      value: 'visible',
      label: 'Visible Columns',
      columns: activeTable.activeColumns
    }
  ];

  useEffect(() => {
    if (!selectedColumns.length) setColumnSelection('none');
    if (isEqual(selectedColumns, activeTable.activeColumns))
      setColumnSelection('visible');
    if (isEqual(selectedColumns, allColumns)) setColumnSelection('all');
  }, [selectedColumns]);

  return (
    <>
      <Paper data-testid='prompt-export-modal' p='md'>
        <Stack gap='xs'>
          <Group justify='space-between'>
            <Text c={colors.contrast[4]}>
              Exporting {selectedColumns.length} column(s)
            </Text>
            <Group gap='xs'>
              <Text c={colors.contrast[4]}>Select:</Text>
              {ColumnSelectionButtons.map((button) => (
                <Button
                  data-testid={button.value}
                  key={button.value}
                  size='xs'
                  variant={
                    columnSelection === button.value ? 'filled' : 'outline'
                  }
                  onClick={() => setSelectedColumns(button.columns)}
                >
                  {button.label}
                </Button>
              ))}
            </Group>
          </Group>
          <TextInput
            leftSection={<IconSearch size='1.25rem' />}
            placeholder='Search columns'
            value={columnSearch}
            onChange={(e) => setColumnSearch(e.target.value)}
          />
          <ScrollArea.Autosize mah='20rem'>
            <Stack mt='md'>
              {!columnSearch && <Checkbox disabled checked={true} label='ID' />}
              {filterColumns(activeTable.ungroupedColumns, columnSearch).map(
                (column) => {
                  if (column) {
                    return (
                      <Checkbox
                        checked={selectedColumns.includes(column.name)}
                        key={column.name}
                        label={column.label}
                        onClick={() =>
                          handleCheck(
                            column.name,
                            selectedColumns,
                            setSelectedColumns
                          )
                        }
                      />
                    );
                  }
                }
              )}
              {activeTable.groupedColumns.map((group) => {
                const filteredColumns = filterColumns(
                  group.columns,
                  columnSearch
                );
                if (filteredColumns.length) {
                  return (
                    <>
                      <Checkbox
                        checked={groupChecked(group, selectedColumns)}
                        indeterminate={
                          groupIndeterminate(group, selectedColumns) &&
                          !groupChecked(group, selectedColumns)
                        }
                        key={group.name}
                        label={group.label}
                        onClick={() => {
                          let updatedColumns: string[] = [];

                          if (groupChecked(group, selectedColumns)) {
                            updatedColumns = [...selectedColumns];
                            group.columns.forEach((column) => {
                              if (updatedColumns.includes(column.name)) {
                                updatedColumns = [
                                  ...updatedColumns.filter(
                                    (updatedColumn) =>
                                      updatedColumn !== column.name
                                  )
                                ];
                              }
                            });
                            setSelectedColumns([...updatedColumns]);
                          } else {
                            group.columns.forEach((column) => {
                              if (!selectedColumns.includes(column.name))
                                updatedColumns.push(column.name);
                            });
                            setSelectedColumns([
                              ...selectedColumns,
                              ...updatedColumns
                            ]);
                          }
                        }}
                      />
                      {filteredColumns.map((column) => {
                        if (column)
                          return (
                            <Checkbox
                              checked={selectedColumns.includes(column.name)}
                              key={column.name}
                              label={column.label}
                              ml='xl'
                              onClick={() =>
                                handleCheck(
                                  column.name,
                                  selectedColumns,
                                  setSelectedColumns
                                )
                              }
                            />
                          );
                      })}
                    </>
                  );
                }
              })}
              {!hasSearchResults && <Text>No results for {columnSearch}</Text>}
            </Stack>
          </ScrollArea.Autosize>
        </Stack>
        <Divider color={colors.contrast[7]} mt='lg' mx='-1rem' />
        <Stack mt='lg'>
          {innerProps.isChain && (
            <Box
              bd={`1px solid ${colors.contrast[5]}`}
              p='xs'
              style={{
                borderRadius: '0.5rem'
              }}
            >
              <Switch
                checked={includeChains}
                label='Include children nodes'
                size='sm'
                onChange={() => setIncludeChains(!includeChains)}
              />
            </Box>
          )}
          <Group align='flex-start'>
            <Box style={{ flexGrow: 1 }}>
              <TextInput
                label='File Name'
                styles={{
                  label: { color: colors.contrast[4] }
                }}
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
              <Text c={colors.contrast[5]} mt='xs'>
                Exported as {fileName}.{fileType}
              </Text>
            </Box>
            <Select
              allowDeselect={false}
              data={['csv', 'jsonl']}
              label='File Type'
              styles={{
                label: { color: colors.contrast[4] },
                dropdown: {
                  zIndex: Z_INDEX.MODALS
                }
              }}
              value={fileType}
              w='7.5rem'
              onChange={(_value, option) =>
                setFileType(option.value as 'csv' | 'jsonl')
              }
            />
          </Group>
        </Stack>
      </Paper>
      <Box
        p='md'
        style={{
          background: 'var(--mantine-color-gray-1)',
          borderTop: `1px solid var(--mantine-color-gray-2)`
        }}
      >
        <Group justify='flex-end'>
          <Button
            data-testid='prompt-export-modal-cancel-btn'
            variant='subtle'
            onClick={() => closeAll()}
          >
            Cancel
          </Button>
          <Button
            data-testid='prompt-export-modal-export-btn'
            loading={exportPrompt.isPending}
            radius='md'
            onClick={handleExport}
          >
            Export
          </Button>
        </Group>
      </Box>
    </>
  );
};

export default PromptExportModal;
