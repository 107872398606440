import { useMutation } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { useMetricsPromptRuns } from '../use-metrics/prompt-runs/use-metrics-prompt-runs';

export const PROMPT_EXPORT =
  '/projects/{project_id}/runs/{run_id}/prompts/export_prompt_dataset';

interface ExportPromptArgs {
  name?: string;
  run_id?: string;
  project_id?: string;
  includeChains?: boolean;
  fileName?: string;
  fileType?: 'csv' | 'jsonl';
  columns?: string[];
}

export const usePromptExport = () => {
  // Path Parameters
  const { runId, projectId } = usePathParameters();

  // Hooks
  const { rows: runs } = useMetricsPromptRuns();

  const fetcher = async ({
    name,
    run_id,
    project_id,
    includeChains,
    fileName,
    fileType = 'csv',
    columns = []
  }: ExportPromptArgs) => {
    // Default to path param if not provided
    run_id = run_id || (runId as string);
    project_id = project_id || (projectId as string);
    // Try to find run name if not provided, default to run_id
    name =
      name ||
      runs?.find((r) => r.id === run_id)?.name?.value ||
      (run_id as string);

    const query = {
      include_chains: includeChains,
      export_format: fileType,
      columns
    };

    const resp = await api.GET(PROMPT_EXPORT, {
      parseAs: 'text',
      params: {
        path: { project_id: project_id, run_id: run_id },
        query
      }
    });

    const blob = new Blob([resp?.data || ''], {
      type: fileType === 'csv' ? 'text/csv' : 'application/json'
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName ?? name}.${fileType}`;
    link.click();
    link.remove();

    return {
      ...resp
    };
  };

  const exportPromptData = useMutation({
    mutationFn: fetcher,
    onError: () => {
      showNotification({
        title: 'Unable to export prompt data',
        type: 'error'
      });
    }
  });

  return exportPromptData;
};
