import { useMutation } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';
import { QueryFilter } from '@/core/types/filters.types';
import { showNotification } from '@/core/utils/show-notification/show-notification';

// API Path
const EXPORT_PATH = '/projects/{project_id}/observe/export';
const DATABRICKS_EXPORT_PATH =
  '/projects/{project_id}/observe/export/databricks';

export type ExportFileType = components['schemas']['LLMExportFormat'];

export const useExport = () => {
  // Parameters
  const { projectId } = usePathParameters();

  const fetcher = async ({
    startTime,
    endTime,
    includeChains,
    testSet,
    fileName,
    fileType,
    columns,
    filters,
    sortSpec,
    isDatabricksExport,
    tableName,
    databaseName,
    clusterId
  }: {
    startTime: string;
    endTime: string;
    includeChains: boolean;
    testSet: boolean;
    fileName: string;
    fileType: ExportFileType;
    columns: string[] | undefined;
    filters: QueryFilter[];
    sortSpec: components['schemas']['SortClause'][];
    databricksTableName?: string;
    isDatabricksExport?: boolean;
    tableName?: string;
    databaseName?: string;
    clusterId?: string;
  }) => {
    if (isDatabricksExport) {
      await api.POST(DATABRICKS_EXPORT_PATH, {
        params: {
          query: {
            start_time: startTime,
            end_time: endTime,
            // Not used, the API always returns the full chain
            chain_id: null,
            test_set: testSet
          },
          path: {
            project_id: projectId!
          }
        },
        body: {
          filters,
          sort_spec: sortSpec,
          columns,
          table_name: tableName!,
          cluster_id: clusterId!,
          database_name: databaseName!
        }
      });

      return;
    }

    const res = await api.POST(EXPORT_PATH, {
      params: {
        query: {
          start_time: startTime,
          end_time: endTime,
          include_chains: includeChains,
          // Not used, the API always returns the full chain
          chain_id: null,
          test_set: testSet,
          export_format: fileType
        },
        path: {
          project_id: projectId!
        }
      },
      body: {
        filters,
        sort_spec: sortSpec,
        columns
      },
      parseAs: 'text'
    });

    const blob = new Blob([res?.data || ''], { type: `text/${fileType}` });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.${fileType}`;
    link.click();
    link.remove();

    return { ...res };
  };

  const exportData = useMutation({
    mutationFn: fetcher,
    onSuccess(_, variables) {
      if (variables.isDatabricksExport) {
        showNotification({
          title: 'Export successful!',
          type: 'success'
        });
      }
    },
    onError: (_, vars) => {
      showNotification({
        title: vars.isDatabricksExport
          ? 'Unable to export to Databricks'
          : 'Unable to export prompt data',
        type: 'error'
      });
    }
  });

  return exportData;
};
