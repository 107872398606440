import { Group } from '@mantine/core';

import { SuccessMetricStatus } from '@/core/types/metric-status.types';
import { UnitFormatMap } from '@/core/utils/unit-conversions/unit-format-mapping';

import MetricExplanationBody from '../metric-explanation-body/metric-explanation-body';
import { MetricPopover } from '../metric-popover/metric-popover';
import { BaseSuccessMetricStatusProps } from '../metric-status/metric-status';
import Metric from '../../atoms/metric/metric';
import ScorerTypePill from '../../atoms/scorer-type-pill/scorer-type-pill';

type BasicMetricProps = BaseSuccessMetricStatusProps<{
  formatValue: (value: SuccessMetricStatus['value']) => JSX.Element;
}>;

const BasicMetric = ({
  cost,
  dataTestId,
  explanation,
  formatValue,
  num_judges,
  model_alias,
  popoverProps,
  rationale,
  scorer_type,
  value,
  ...metricProps
}: BasicMetricProps) => {
  const formattedValue = formatValue(value);

  return (
    <MetricPopover
      body={
        <MetricExplanationBody
          cost={cost}
          explanation={explanation}
          formatCost={UnitFormatMap['parsed__dollars']}
          judges={num_judges}
          model={model_alias}
          rationale={rationale}
        />
      }
      disabled={explanation == null && rationale == null && cost == null}
      header={
        <Group
          gap='md'
          justify='space-between'
          pr='0.5rem'
          w='100%'
          wrap='nowrap'
        >
          <Metric value={formattedValue} variant='subtle' />
          {scorer_type != null && <ScorerTypePill type={scorer_type} />}
        </Group>
      }
      {...popoverProps}
    >
      <Metric
        data-testid={dataTestId ?? 'basic-metric'}
        value={formattedValue}
        {...metricProps}
      />
    </MetricPopover>
  );
};

export default BasicMetric;
