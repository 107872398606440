import { ButtonProps, ModalRootProps, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';

import { Permission } from '@/core/types/user-permissions.types';

import { PermissionedButton } from '../../atoms/permissioned-button/permissioned-button';

interface ModalLauncherProps extends ButtonProps {
  label?: string;
  modal: React.ComponentType<ModalRootProps>;
  permissions?: Permission;
}

export const ModalLauncher = ({
  label,
  modal: ModalComponent,
  permissions,
  ...props
}: ModalLauncherProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <ModalComponent opened={opened} onClose={close} />
      <Tooltip disabled={!label} label={label}>
        <PermissionedButton
          {...props}
          permissions={permissions}
          onClick={open}
        />
      </Tooltip>
    </>
  );
};
