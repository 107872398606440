import { Box, BoxProps } from '@mantine/core';

import { HumanFeedbackInputProps } from '../human-feedback-input/human-feedback-input';
import { TextRater } from '../text-rating/text-rater/text-rater';

interface HumanFeedbackExplanationProps extends HumanFeedbackInputProps {
  boxProps?: BoxProps;
}

const HumanFeedbackExplanation = ({
  metric,
  permissions,
  onChange,
  boxProps
}: HumanFeedbackExplanationProps) => {
  if (!metric.withExplanation) {
    return null;
  }

  // Handlers
  const handleKeydown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation();
    }
  };

  const handleExplanationChange = (value: string | undefined) => {
    if (typeof value === 'string' && value.length === 0) {
      value = undefined;
    }

    // Only call onChange if the values are different
    if (metric.explanation !== value) {
      metric.explanation = value;

      onChange(metric);
    }
  };

  return (
    <Box w='100%' {...boxProps}>
      <TextRater
        disabled={metric?.value == null}
        permissions={permissions}
        placeholder='Annotation explanation'
        value={metric?.explanation as string | undefined}
        onChange={handleExplanationChange}
        onKeyDown={handleKeydown}
      />
    </Box>
  );
};

export default HumanFeedbackExplanation;
