import {
  DefaultOptions,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';

const fifteenMinutes = 15 * 60 * 1000;

interface ReactQueryProviderProps {
  children?: React.ReactNode;
  pageProps: {
    dehydratedState?: object;
  };
}

export const ReactQueryProvider = ({
  children,
  pageProps
}: ReactQueryProviderProps) => {
  const defaultOptions: DefaultOptions = {
    queries: {
      staleTime: Infinity,
      gcTime: fifteenMinutes,
      retry: 1,
      refetchOnWindowFocus: false
    }
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={pageProps.dehydratedState}>
        {children}
      </HydrationBoundary>
      <ReactQueryDevtools initialIsOpen={false} position='bottom' />
    </QueryClientProvider>
  );
};
