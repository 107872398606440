import {
  ThemeIcon,
  ThemeIconProps,
  Tooltip,
  TooltipProps
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

interface InfoTooltipProps extends Omit<TooltipProps, 'children'> {
  iconProps?: Omit<ThemeIconProps, 'children'>;
}
export const InfoTooltip = ({ iconProps, ...props }: InfoTooltipProps) => {
  return (
    <Tooltip
      multiline
      withArrow
      className='pre-wrap'
      maw={300}
      zIndex={Z_INDEX.TOOLTIPS}
      {...props}
    >
      <ThemeIcon
        c='gray.6'
        data-testid='info-tooltip'
        ml={4}
        size='1.25rem'
        variant='transparent'
        {...iconProps}
      >
        <IconInfoCircle />
      </ThemeIcon>
    </Tooltip>
  );
};
