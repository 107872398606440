import { Text } from '@mantine/core';
import check from 'check-types';
import { getReasonPhrase } from 'http-status-codes';
import Router from 'next/router';
import { signOut } from 'next-auth/react';

import {
  GENERIC_ERROR_MESSAGE,
  NO_RESPONSE_ERROR_MESSAGE
} from '@/core/constants/strings.constants';
import { showNotification } from '@/core/utils/show-notification/show-notification';

import { publicUrls } from '../constants/routes.constants';

export const defaultErrorHandler = async (
  res: Response | null | undefined,
  title: string
) => {
  const statusMessage =
    res != null
      ? `HTTP Status ${res.status} (${getReasonPhrase(res.status)})`
      : undefined;
  const errorMessage =
    res != null ? await parseApiErrorMessage(res) : NO_RESPONSE_ERROR_MESSAGE;

  showNotification({
    title,
    message: (
      <div>
        <Text fs='italic'>{statusMessage}</Text>
        <Text>{errorMessage}</Text>
      </div>
    ),
    type: 'error'
  });
};

export const parseApiErrorMessage = async (res: Response) => {
  let error: any;
  try {
    error = await res.json();
  } catch {
    error = { detail: GENERIC_ERROR_MESSAGE };
  }

  const errorMessage = check.string(error?.detail)
    ? error?.detail
    : check.string(error?.detail?.[0].msg)
      ? error?.detail?.[0].msg
      : GENERIC_ERROR_MESSAGE;

  return errorMessage;
};

export async function fetchWithUnauthorizedRedirect(
  input: Request
): Promise<Response> {
  const pathName = Router.pathname;

  const response = await fetch(input);

  if (publicUrls.includes(pathName)) {
    return response;
  }

  if (response.status === 401) {
    window.stop();
    await signOut({ callbackUrl: '/sign-in' });
  }

  return response;
}
