import {
  Box,
  Button,
  Center,
  Divider,
  Paper,
  PasswordInput,
  ScrollArea,
  SegmentedControl,
  Select,
  Spoiler,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { IconInfoCircle } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import TitleWithIcon from '@/core/components/atoms/title-with-icon/title-with-icon';
import {
  INTEGRATION_CARDS,
  IntegrationCard
} from '@/core/constants/integrations.constants';
import { useIntegration } from '@/core/hooks/query-hooks/use-integration/use-integration';
import { useUpdateIntegration } from '@/core/hooks/query-hooks/use-update-integration/use-update-integration';
import { components } from '@/core/types/api';

interface IntegrationModalBodyProps {
  innerProps: { config: IntegrationCard };
}

type AWSBedrockData = components['schemas']['AwsBedrockIntegration'];
type AWSSagemakerData = components['schemas']['AwsSageMakerIntegration'];

export interface UpdateIntegrationMutationBody {
  endpoint?: string;
  url?: string;
  token: string;
  isEnterprise?: boolean;
  organization?: string;
  isDatabricks?: boolean;
  sqlPath?: string;
  catalogName?: string;
  principalId?: string;
  region?: string;
  credentialType?: string;
  aws_access_key_id?: string;
  aws_secret_access_key?: string;
  aws_role_arn?: string;
}

type Model = {
  alias: string;
  endpoint: string;
  prompt: string;
  response: string;
  temperature?: string;
  maxTokens?: string;
  topK?: string;
  topP?: string;
  frequencyPenalty?: string;
  presencePenalty?: string;
};

type APIModel = components['schemas']['Model'];

const toUIFormat = (model: APIModel) => ({
  alias: model.alias,
  endpoint: model.name,
  prompt: model.input_map?.prompt,
  response: model.output_map?.response,
  temperature: model.params_map?.temperature,
  maxTokens: model.params_map?.max_tokens,
  topK: model.params_map?.top_k || '',
  topP: model.params_map?.top_p,
  frequencyPenalty: model.params_map?.frequency_penalty,
  presencePenalty: model.params_map?.presence_penalty
});

const toAPIFormat = (model: Model) => ({
  token_limit: 4000,
  alias: model.alias,
  name: model.endpoint,
  input_map: { prompt: model.prompt },
  output_map: { response: model.response },
  params_map: {
    temperature: model.temperature,
    max_tokens: model.maxTokens,
    top_k: model.topK,
    top_p: model.topP,
    frequency_penalty: model.frequencyPenalty,
    presence_penalty: model.presencePenalty
  }
});

const disableAddModelButton = (models: Model[]) => {
  if (models.length === 3) return true;

  const lastModel = models[models.length - 1];
  return !lastModel?.alias || !lastModel?.endpoint || !lastModel?.prompt;
};

const AWSIntegrationsModal = ({ innerProps }: IntegrationModalBodyProps) => {
  const [currentModelIndex, setCurrentModelIndex] = useState(0);

  const { icon, title, id, showDisclaimer, onSuccessCallback } =
    innerProps?.config || {};

  // Hooks
  const { data } = useIntegration(id);
  const updateIntegration = useUpdateIntegration(id, onSuccessCallback);

  // Form
  const form = useForm({
    initialValues: {
      credentialType: 'key_secret',
      region: 'us-west-2',
      aws_access_key_id: '',
      aws_secret_access_key: '',
      aws_role_arn: '',
      models: [{ alias: 'my_model_1', endpoint: '', prompt: '', response: '' }],
      isConsented: false
    }
  });

  useEffect(() => {
    if (data) {
      const typedData = data as AWSBedrockData | AWSSagemakerData;
      const formData = {
        credentialType: typedData?.credential_type,
        region: typedData?.region,
        // @ts-expect-error - API needs to update
        aws_access_key_id: typedData.token?.aws_access_key_id!,
        // @ts-expect-error - API needs to update
        aws_secret_access_key: typedData.token?.aws_secret_access_key,
        // @ts-expect-error - API needs to update
        aws_role_arn: typedData.token?.aws_role_arn,
        ...((typedData as AWSSagemakerData)?.models?.length && {
          models: (typedData as AWSSagemakerData)?.models?.map(toUIFormat)
        })
      };
      // @ts-expect-error - API needs to update
      form.setValues(formData);
    }
  }, [data]);

  // Handlers
  const handleUpdateIntegration = (values: typeof form.values) => {
    const body = { ...values, models: values.models.map(toAPIFormat) };
    updateIntegration.mutate(body, { onSuccess: () => closeAllModals() });
  };

  const handleModelChange = (value: string) => {
    const models = form.values.models as Model[];
    if (value === '+') {
      const newModel = {
        alias: `my_model_${models.length + 1}`,
        endpoint: '',
        prompt: '',
        response: ''
      };
      form.setFieldValue('models', [...models, newModel]);
      setCurrentModelIndex(models.length);
    } else {
      const index = models.findIndex((m) => m.alias === value);
      setCurrentModelIndex(index);
    }
  };

  const modelControlData = [
    ...form.values.models.map((m) => ({
      value: m.alias,
      label: m.alias
    })),
    {
      label: '+',
      value: '+',
      disabled: disableAddModelButton(form.values.models)
    }
  ];

  const isSagemaker = id === 'aws_sagemaker';
  const integrationName = INTEGRATION_CARDS.find((int) => int.id === id)?.name;
  const currentModel = form.values.models[currentModelIndex] || {};

  const { aws_access_key_id, aws_role_arn, aws_secret_access_key, region } =
    form.values;
  let enableButton = Boolean(
    ((aws_access_key_id && aws_secret_access_key) || aws_role_arn) && region
  );

  if (isSagemaker) {
    const lastModel = form.values.models[form.values.models.length - 1];
    enableButton =
      enableButton &&
      Boolean(
        lastModel.alias &&
          lastModel.endpoint &&
          lastModel.prompt &&
          lastModel.response
      );
  }

  if (showDisclaimer) {
    enableButton = enableButton && form.values.isConsented;
  }

  const chooseTemplate = (value: string | null) => {
    switch (value) {
      case 'Huggingface':
        form.setFieldValue(`models.${currentModelIndex}.prompt`, 'inputs');
        form.setFieldValue(
          `models.${currentModelIndex}.response`,
          '0/generated_text'
        );
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <form
        data-testid='aws-integrations-modal'
        onSubmit={form.onSubmit(handleUpdateIntegration)}
      >
        <Box p='md'>
          <TitleWithIcon icon={icon} title={title} />

          <SegmentedControl
            data={[
              { value: 'key_secret', label: 'Access Key' },
              { value: 'assumed_role', label: 'Assumed Roles' }
            ]}
            mt='xs'
            radius='md'
            size='sm'
            {...form.getInputProps('credentialType')}
          />
          {form.values.credentialType === 'key_secret' ? (
            <>
              <TextInput
                required
                label='AWS Access Key'
                mb='xs'
                mt='xs'
                placeholder='Insert access key here'
                {...form.getInputProps('aws_access_key_id')}
              />
              <PasswordInput
                required
                label='Access Secret auth'
                mb='xs'
                mt='xs'
                placeholder='Insert access secret auth here'
                {...form.getInputProps('aws_secret_access_key')}
              />
            </>
          ) : (
            <TextInput
              required
              label='AWS Role ARN'
              mb='xs'
              mt='xs'
              placeholder='AWS Role ARN'
              {...form.getInputProps('aws_role_arn')}
            />
          )}
          <TextInput
            required
            label='Region'
            mt='xs'
            placeholder='Insert region here'
            {...form.getInputProps('region')}
          />
        </Box>
        {isSagemaker && (
          <>
            <Divider color='#E9E8ED' />
            <Box p='md'>
              <SegmentedControl
                data={modelControlData}
                radius='md'
                size='sm'
                value={currentModel.alias}
                onChange={handleModelChange}
              />
              <ScrollArea.Autosize mah='30vh' mt={0}>
                <TextInput
                  required
                  label='Model Alias'
                  mb='xs'
                  mt='sm'
                  placeholder='Insert model alias here'
                  {...form.getInputProps(`models.${currentModelIndex}.alias`)}
                />
                <TextInput
                  required
                  label='Endpoint'
                  mb='xs'
                  mt='sm'
                  placeholder='Insert endpoint here'
                  {...form.getInputProps(
                    `models.${currentModelIndex}.endpoint`
                  )}
                />
                <Paper withBorder mt='sm' p='md'>
                  <Title mb='xs' order={3} size='sm'>
                    Schema mapping
                  </Title>
                  <Center inline mb='xs'>
                    <Tooltip
                      multiline
                      label='For example the output of a ChatGPT response would be mapped as: prompt: "choices/0/message/content"'
                    >
                      <IconInfoCircle color='#9B98AE' size={15} />
                    </Tooltip>
                    <Text c='gray.7' ml={4} size='xs'>
                      Enter mapping in path notation (slash-separated)
                    </Text>
                  </Center>
                  <Select
                    comboboxProps={{ withinPortal: false }}
                    data={[{ value: 'Huggingface', label: 'Huggingface' }]}
                    label='Template'
                    mb='xs'
                    placeholder='Choose from template'
                    onChange={chooseTemplate}
                  />
                  <Spoiler
                    hideLabel='Hide advanced settings'
                    maxHeight={140}
                    showLabel='Show advanced settings'
                    styles={{
                      control: {
                        color: '#4A2FF9',
                        fontSize: 12,
                        fontWeight: 600
                      }
                    }}
                  >
                    <TextInput
                      required
                      label='Prompt'
                      mb='xs'
                      placeholder='/prompt'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.prompt`
                      )}
                    />
                    <TextInput
                      required
                      label='Response'
                      mb='xs'
                      mt='sm'
                      placeholder='/response'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.response`
                      )}
                    />
                    <TextInput
                      label='Temperature'
                      mb='xs'
                      mt='sm'
                      placeholder='/temperature'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.temperature`
                      )}
                    />
                    <TextInput
                      label='Max Tokens'
                      mb='xs'
                      mt='sm'
                      placeholder='/maxTokens'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.maxTokens`
                      )}
                    />
                    <TextInput
                      label='Top K'
                      mb='xs'
                      mt='sm'
                      placeholder='/topK'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.topK`
                      )}
                    />
                    <TextInput
                      label='Top P'
                      mb='xs'
                      mt='sm'
                      placeholder='/topP'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.topP`
                      )}
                    />
                    <TextInput
                      label='Frequency Penalty'
                      mb='xs'
                      mt='sm'
                      placeholder='/frequencyPenalty'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.frequencyPenalty`
                      )}
                    />
                    <TextInput
                      label='Presence Penalty'
                      mb='xs'
                      mt='sm'
                      placeholder='/presencePenalty'
                      {...form.getInputProps(
                        `models.${currentModelIndex}.presencePenalty`
                      )}
                    />
                  </Spoiler>
                </Paper>
              </ScrollArea.Autosize>
            </Box>
          </>
        )}

        {showDisclaimer && (
          <Switch
            label={`I acknowledge that by using this feature, I am sending my data to ${integrationName} through its APIs.`}
            px='md'
            py='xs'
            {...form.getInputProps('isConsented', { type: 'checkbox' })}
          />
        )}

        <Box
          bg='gray.1'
          style={{
            borderTop: '1px solid #E9E8ED',
            textAlign: 'right',
            width: '100%'
          }}
        >
          <Button
            bg='white'
            color='gray'
            mr='xs'
            my='sm'
            radius='md'
            variant='outline'
            onClick={() => closeAllModals()}
          >
            Cancel
          </Button>
          <Button
            data-testid='save-integrations-button'
            disabled={!enableButton}
            mx='sm'
            my='auto'
            radius='md'
            type='submit'
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AWSIntegrationsModal;
