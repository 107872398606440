import { useMutation, useQueryClient } from '@tanstack/react-query';
import Gleap from 'gleap';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import {
  EVALUATE_COLUMNAR_RUN_PATH,
  EVALUATE_COLUMNAR_RUNS_PATH,
  HUMAN_FEEDBACK_TEMPLATES_PATH
} from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';

import FeedbackTemplate from '../../../classes/feedback-template/feedback-template';

export type CreateFeedbackTemplateBody =
  components['schemas']['FeedbackTemplateCreate'];

export const useCreateFeedbackTemplate = () => {
  // Path Params
  const { projectId } = usePathParameters();

  // Query Client
  const queryClient = useQueryClient();

  // Action
  const createTemplate = async (body: CreateFeedbackTemplateBody) => {
    if (body == null) {
      return Promise.reject(`Invalid body: ${JSON.stringify(body)}`);
    }

    const res = await api
      .POST(HUMAN_FEEDBACK_TEMPLATES_PATH, {
        params: {
          path: {
            project_id: projectId!
          }
        },
        body: body
      })
      ?.then((res) => {
        queryClient.invalidateQueries({
          queryKey: [HUMAN_FEEDBACK_TEMPLATES_PATH]
        });
        queryClient.invalidateQueries({
          queryKey: [EVALUATE_COLUMNAR_RUN_PATH]
        });
        queryClient.invalidateQueries({
          queryKey: [EVALUATE_COLUMNAR_RUNS_PATH]
        });
        return res?.data;
      })
      ?.catch((error) => {
        defaultErrorHandler(error, `Unable to create annotation configuration`);
      })
      .finally(() => {
        Gleap.trackEvent('configured feedback template');
      });

    return res;
  };

  return useMutation({
    mutationFn: createTemplate
  });
};

export const templateToPostBody = (
  template: FeedbackTemplate<any>
): CreateFeedbackTemplateBody => {
  if (template.name == null || template.constraints == null) {
    throw new Error('Invalid template: name and constraints are required');
  }

  const criteria =
    typeof template.criteria === 'string' && template.criteria.length > 0
      ? template.criteria
      : undefined;

  return {
    constraints: template.constraints,
    criteria,
    include_explanation: Boolean(template.withExplanation),
    name: template.name
  };
};
