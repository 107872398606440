import { persist } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

import { COLUMN_ID } from '@/observe/types/observe-store.types';

const STORAGE_VERSION: number = 2;

// NOTE: if you change the store shape, you must update `STORAGE_VERSION` too
interface PersistedObserveStoreState {
  hiddenColumns: string[];

  actions: {
    // Nothing within `actions` is persisted since JSON.stringify ignores functions
    setHiddenColumns: (nextHiddenColumns: string[]) => void;
  };
}

/** NOTE: This store is persisted for all observe projects per user's local machine */
export const usePersistedObserveStore =
  createWithEqualityFn<PersistedObserveStoreState>()(
    persist<PersistedObserveStoreState>(
      (set) => ({
        // NOTE: if you change anything in this function, you must update `STORAGE_VERSION` too
        hiddenColumns: [
          COLUMN_ID.INPUT_TOKENS,
          COLUMN_ID.MODEL,
          COLUMN_ID.OUTPUT_TOKENS,
          COLUMN_ID.SYSTEM_VERSION,
          COLUMN_ID.TEMPERATURE
        ],
        actions: {
          setHiddenColumns: (nextHiddenColumns) =>
            set({ hiddenColumns: nextHiddenColumns })
        }
      }),
      {
        name: `persisted-prompt-monitoring-storage`,
        version: STORAGE_VERSION,
        merge: (
          persistedState: any,
          currentState: PersistedObserveStoreState
        ) => {
          // NOTE: Overwrite current state with any available persisted state.
          // NOTE: Force usage of current state actions since JSON.stringify does not store functions
          return {
            ...currentState,
            ...persistedState,
            actions: currentState.actions
          };
        }
      }
    )
  );
