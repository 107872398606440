import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { FeatureFlagsStoreState } from './feature-flags.store.types';

export const useFeatureFlagsStore =
  createWithEqualityFn<FeatureFlagsStoreState>()(
    (set, get) => ({
      airgapped: false,
      featureFlags: {
        chainpoll_continuous_learning: false,
        csv_training: false,
        gleap: false,
        hide_credentials_login: false,
        hide_request_access: false,
        image_classification: false,
        label_co_occurrence: false,
        monitor_alerts: false,
        object_detection: false,
        observe_human_ratings: false,
        posthog: false,
        prompt_eval: false,
        prompt_level_uncertainty: false,
        prompt_monitor: false,
        prompt_scorers_default_all: false
      },

      actions: {
        setFeatureFlags: (flags) =>
          set(() => ({
            featureFlags: flags?.feature_flags,
            airgapped: flags?.airgapped
          }))
      },

      computed: {
        getChainpollContinuousLearningFlag: () =>
          Boolean(get()?.featureFlags?.chainpoll_continuous_learning),

        getCoOccurrenceFlag: () =>
          Boolean(get()?.featureFlags?.label_co_occurrence),

        getCSVTrainingFlag: () => Boolean(get()?.featureFlags?.csv_training),

        getGleapFlag: () => Boolean(get()?.featureFlags?.gleap),

        getHideCredentialsLoginFlag: () =>
          Boolean(get()?.featureFlags?.hide_credentials_login),

        getHideRequestAccessFlag: () =>
          Boolean(get()?.featureFlags?.hide_request_access),

        getObjectDetectionFlag: () =>
          Boolean(get()?.featureFlags?.object_detection),

        getObserveAlertsFlag: () =>
          Boolean(get()?.featureFlags?.monitor_alerts),

        getObserveHumanRatingsFlag: () =>
          Boolean(get()?.featureFlags?.observe_human_ratings),

        getObserveFlag: () => Boolean(get()?.featureFlags?.prompt_monitor),

        getPosthogFlag: () => Boolean(get()?.featureFlags?.posthog),

        getPromptFlag: () => Boolean(get()?.featureFlags?.prompt_eval),

        getPromptLevelUncertaintyFlag: () =>
          Boolean(get()?.featureFlags?.prompt_level_uncertainty),

        getPromptScorersDefaultAllFlag: () =>
          Boolean(get()?.featureFlags?.prompt_scorers_default_all)
      }
    }),
    shallow
  );
