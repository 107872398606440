import { Box, Center, Text, useMantineTheme } from '@mantine/core';
import { IconThumbDownFilled, IconThumbUpFilled } from '@tabler/icons-react';

import DataNotAvailable from '@/core/components/atoms/accessibility/data-not-available/data-not-available';

import { MetricPopover } from '../metric-popover/metric-popover';
import Metric from '../../atoms/metric/metric';

interface ThumbRatingProps {
  explanation?: string;
  highlightResult?: boolean;
  rating?: 1 | 0 | undefined | boolean | 'like' | 'dislike';
  ratio?: number | null;
  title?: string;
}

export const ThumbRating = ({
  explanation,
  highlightResult,
  rating,
  ratio,
  title
}: ThumbRatingProps) => {
  // Hooks
  const { colors } = useMantineTheme();

  // Computed
  const color = highlightResult ? colors.red[6] : colors.gray[6];
  let icon = (
    <Text c={color} data-testid='thumb-neutral' size='sm'>
      N/A
    </Text>
  );
  let ratioPercentage = <DataNotAvailable />;

  if (typeof ratio === 'number') {
    if (isNaN(ratio)) {
      ratio = 0;
    }

    ratioPercentage = <Text size='sm'>{`${(ratio * 100).toFixed(0)}%`}</Text>;
  }

  if (rating === 1 || rating === 'like' || rating === true) {
    icon = (
      <IconThumbUpFilled
        aria-label='thumb up icon'
        data-testid='thumb-up'
        size='1.25rem'
      />
    );
  }

  if (rating === 0 || rating === 'dislike' || rating === false) {
    icon = (
      <IconThumbDownFilled
        aria-label='thumb down icon'
        data-testid='thumb-down'
        size='1.25rem'
      />
    );
  }

  return (
    <MetricPopover
      body={
        explanation && (
          <Box p='sm'>
            <Text c='gray.2' fw={600} pb='sm' size='sm'>
              {title ?? 'Thumb Annotation'}
            </Text>
            <Text c='gray.2' size='sm'>
              {explanation}
            </Text>
          </Box>
        )
      }
    >
      <Metric
        color={highlightResult ? 'red' : 'gray'}
        data-testid='thumb-rating'
        value={icon}
        variant='subtle'
      >
        <Center>{typeof ratio === 'number' && ratioPercentage}</Center>
      </Metric>
    </MetricPopover>
  );
};
