import { Group, MantineSize, Text, Tooltip } from '@mantine/core';
import React from 'react';

import { useUserById } from '@/core/hooks/query-hooks/use-user-by-id/use-user-by-id';

import Avatar from '../avatar/avatar';
import SkeletonLoader from '../skeleton-loader/skeleton-loader';

interface UserNameProps {
  id: string;
  size?: MantineSize;
  spacing?: string | number;
  fontSize?: number;
  showFullName?: boolean;
  tooltipText?: string;
}

const UserName = ({
  id,
  size = 'sm',
  spacing = 'xs',
  fontSize,
  showFullName = true,
  tooltipText
}: UserNameProps) => {
  const { data, isPending } = useUserById(id);

  const { first_name, last_name, email } = data || {};

  const name = first_name && last_name ? `${first_name} ${last_name}` : email;

  if (isPending) {
    return (
      <Group gap={spacing} wrap='nowrap'>
        <SkeletonLoader
          height={size || 14}
          length={1}
          mb={0}
          variant='circle'
          width={size || 14}
        />
        {showFullName && <SkeletonLoader height={14} length={1} width={75} />}
      </Group>
    );
  }

  return (
    <Group gap={spacing} wrap='nowrap'>
      <Tooltip
        disabled={!tooltipText}
        label={`${tooltipText} ${name}`}
        position='bottom'
      >
        <Avatar
          alt={undefined}
          fontSize={fontSize}
          isLoading={isPending}
          name={name || ''}
          size={size}
        />
      </Tooltip>

      {showFullName && (
        <Text component='span' size='sm' style={{ whiteSpace: 'nowrap' }}>
          {name}
        </Text>
      )}
    </Group>
  );
};

export default UserName;
