import {
  Box,
  Button,
  Divider,
  Group,
  Text,
  Textarea,
  UnstyledButton
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconExternalLink } from '@tabler/icons-react';
import { useState } from 'react';

import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useChainpollContinuousLearningStore } from '@/core/stores/chainpoll-continuous-learning-store/chainpoll-continuous-learning.store';

type CustomContextModalProps = ContextModalProps & {
  innerProps?: {
    metricData: {
      name: string;
      value: number;
      label: string;
      rationale: string;
      formattedValue: string;
      color: string;
      inverted?: boolean;
      rowId: string;
    };
  };
};

const ChainpollFeedbackModal = (props: CustomContextModalProps) => {
  // Path parameters
  const { runId } = usePathParameters();
  // Local state
  const [feedback, setFeedback] = useState('');

  // Store actions
  const addCritique = useChainpollContinuousLearningStore(
    (s) => s.actions.addCritique
  );

  // Computed
  const {
    name,
    rationale,
    label,
    color,
    inverted,
    value,
    formattedValue,
    rowId
  } = props.innerProps.metricData || {};

  const grade = inverted
    ? color === 'red'
      ? 'High'
      : 'Low'
    : color === 'green'
      ? 'High'
      : 'Low';

  // Handlers
  const onSubmit = () => {
    const intendedValue = inverted ? value >= 0.5 : value < 0.5;

    addCritique(name!, {
      runId: runId!,
      rowId,
      critique: feedback,
      intendedValue
    });

    notifications.show({
      position: 'top-right',
      type: 'success',
      title: '',
      color: 'green',
      icon: <IconCheck size={24} />,
      message: (
        <Text c='gray.8' fw={600} size='sm'>
          Feedback registered to auto-improve {label}. Please check number in
          column.
        </Text>
      )
    });

    props.context.closeAll();
  };

  return (
    <Box data-testid='chainpoll-feedback-modal'>
      <Box px='lg' py='md'>
        <Group justify='space-between'>
          <Text c='dark' fw={600} size='lg'>
            {label}{' '}
            <Text c={color} component='span' fw={700}>
              {grade} ({formattedValue})
            </Text>
          </Text>
        </Group>
        <Text c='gray.6' fw={400} size='xs'>
          Help us customize {label} for your use case. Tell us what went wrong
          with this metric and we&apos;ll use your feedback to automatically
          generate an updated version of {label} for you.
        </Text>
        <Text c='gray.6' mb={4} mt='md'>
          Explanation
        </Text>
        <Text
          bg='gray.1'
          c='gray.7'
          fw={400}
          mah={140}
          p='sm'
          style={{ overflow: 'auto', borderRadius: 8 }}
        >
          {rationale}
        </Text>
      </Box>
      <Divider variant='dashed' />
      <Box p='lg'>
        <Textarea
          label='Feedback for auto-improvement'
          placeholder='What was wrong with the original explanation? Please be as specific as possible in your arguments.'
          styles={{
            input: {
              height: 120
            }
          }}
          value={feedback}
          onChange={(e) => setFeedback(e.currentTarget.value)}
        />
        <UnstyledButton color='brand' mt='sm'>
          <Group gap={4}>
            <IconExternalLink color='#644DF9' size={16} />
            <Text c='brand.6' fw={600}>
              See examples of feedback for best results
            </Text>
          </Group>
        </UnstyledButton>
      </Box>
      <Box
        bg='gray.0'
        p='md'
        style={{
          borderTop: '1px solid #E9E8ED'
        }}
      >
        <Group justify='flex-end'>
          <Button
            style={{ borderColor: 'var(--mantine-color-gray-3)' }}
            variant='default'
            onClick={props.context.closeAll}
          >
            Cancel
          </Button>
          <Button color='brand' ml='sm' onClick={onSubmit}>
            Submit for auto-learning
          </Button>
        </Group>
      </Box>
    </Box>
  );
};

export default ChainpollFeedbackModal;
