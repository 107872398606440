import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { useParametersStore } from '@/fine-tune/stores/parameters-store';

export const GET_EDITS_CONTENT =
  '/projects/{project_id}/runs/{run_id}/split/{split}/edits/content';

export const useEditsContent = ({
  searchQuery,
  isRegexSearch
}: {
  searchQuery?: string;
  isRegexSearch?: boolean;
}) => {
  const { runId, projectId } = usePathParameters();
  const split = useParametersStore((state) => state.split);
  const inferenceName = useParametersStore((state) => state.inferenceName);

  const enabled = Boolean(projectId && runId && split);

  const fetchEditsContent = async () => {
    const res = await api.GET(GET_EDITS_CONTENT, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string,
          split
        },
        query: {
          text_pat: searchQuery,
          regex: isRegexSearch
        }
      }
    });

    return res.data;
  };

  const response = useQuery({
    queryKey: [
      GET_EDITS_CONTENT,
      { projectId, runId, split, searchQuery, isRegexSearch, inferenceName }
    ],
    queryFn: fetchEditsContent,
    enabled
  });

  return response;
};
