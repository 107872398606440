import {
  GroupMembersPermissionBlock,
  GroupMembersPermissions
} from '@/core/types/user-permissions.types';

import { useFeatureFlags } from '../use-feature-flags/use-feature-flags';

export const defaults: GroupMembersPermissions = {
  update_role: {
    allowed: false,
    message: ''
  },
  delete: {
    allowed: false,
    message: ''
  }
};

export function useUserGroupMembersPermissions() {
  // Feature Flags
  const { groupMembersPermissions } = useFeatureFlags();
  const isEnabled = groupMembersPermissions.isEnabled;
  const flagPermissions = groupMembersPermissions.values;

  function getUserGroupMembersPermissions(
    permissions: GroupMembersPermissionBlock[]
  ): GroupMembersPermissions {
    if (isEnabled) {
      return flagPermissions;
    }

    const permissionBlock = Object.fromEntries(
      permissions.map(({ action, ...permission }) => [action, permission])
    ) as GroupMembersPermissions;

    return {
      update_role: permissionBlock.update_role || defaults.update_role,
      delete: permissionBlock.delete || defaults.delete
    };
  }

  return {
    getUserGroupMembersPermissions
  };
}
