import {
  Box,
  Button,
  Center,
  Checkbox,
  Group,
  Paper,
  ScrollArea,
  Switch,
  Text
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useListState } from '@mantine/hooks';
import { ContextModalProps } from '@mantine/modals';
import cx from 'classix';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import EditableCheckbox from '@/core/components/atoms/editable-checkbox/editable-checkbox';
import RadioWithIcon from '@/core/components/atoms/radio-with-icon/radio-with-icon';
import LiveSearchBar from '@/core/components/organisms/no-projects/live-search-bar/live-search-bar';
import { useCurrentProject } from '@/core/hooks/query-hooks/use-current-project/use-current-project';
import { useCurrentRun } from '@/core/hooks/query-hooks/use-current-run/use-current-run';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import { useModals } from '@/core/hooks/use-modals/use-modals';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { caseInsensitiveMatch } from '@/core/utils/case-insensitive-match/case-insensitive-match';
import { showNotification } from '@/core/utils/show-notification/show-notification';
import { toHumanReadableNumber } from '@/core/utils/to-human-readable-number/to-human-readable-number';
import {
  SAMPLE_ID_ACCESSOR,
  TEXT_ACCESSOR
} from '@/fine-tune/constants/dataframe-accessors.constants';
import { EXPORT_DESTINATIONS } from '@/fine-tune/constants/export-options.constants';
import { SCROLLBAR_SIZE } from '@/fine-tune/constants/misc.constants';
import { useEdits } from '@/fine-tune/hooks/query-hooks/use-edits/use-edits';
import { useExportTo } from '@/fine-tune/hooks/query-hooks/use-export-to/use-export-to';
import { RemoteExportConfig } from '@/fine-tune/hooks/query-hooks/use-export-to/use-export-to.types';
import { useDataframeColumns } from '@/fine-tune/hooks/use-dataframe-column/use-dataframe-columns';
import { useSelectedSamplesAmount } from '@/fine-tune/hooks/use-selected-samples-amount/use-selected-samples-amount';
import {
  useComputedParameters,
  useParametersStore
} from '@/fine-tune/stores/parameters-store';
import { ExportDestinations, FileType } from '@/fine-tune/types/export.types';

import ExportModalForm from './export-modal-form';

type CustomContextModalProps = ContextModalProps & {
  innerProps?: {
    fromEditsCart?: boolean;
    rowId?: number;
  };
};

const fixedNerColumns = [TEXT_ACCESSOR, SAMPLE_ID_ACCESSOR, 'spans'];

/**
 *
 *
 * @returns
 */
const ExportModal = ({ innerProps }: CustomContextModalProps) => {
  const { projectId, runId } = usePathParameters();
  const split = useParametersStore((s) => s.split);
  const taskType = useParametersStore((s) => s.taskType);

  const { isNer, isOd, isInference } = useComputedParameters();

  // Query Hooks
  const exportTo = useExportTo(innerProps?.rowId);
  const run = useCurrentRun();
  const project = useCurrentProject();
  const { count: editsSampleCount, reviewedCount } = useEdits();

  // Utility Hooks
  const { listStateColumns } = useDataframeColumns();
  const { isGalileoUser } = useFeatureFlags();
  const searchRef = useRef<HTMLInputElement>(null);
  const { closeAll } = useModals();

  // Local State
  const [searchTerm, setSearchTerm] = useState('');
  const [destination, setDestination] = useState<ExportDestinations>('local');
  const [columnRows, columnRowHandlers] = useListState(listStateColumns);

  const { getSelectedCount, getTotalCount } = useSelectedSamplesAmount(
    innerProps?.rowId
  );

  const projectName = project?.data?.name ?? projectId;
  const runName = run?.data?.name ?? runId;

  const defaultObjectName = `${projectName}_${runName}_${split}_data`;
  const isEditsExport = innerProps?.fromEditsCart;

  const modalityExportDestinations = EXPORT_DESTINATIONS.filter(
    ({ hidden }) => !hidden?.includes(taskType)
  );

  const form = useForm({
    initialValues: {
      bucketName: '',
      tableName: '',
      objectName: defaultObjectName,
      fileType: isOd ? 'zip' : 'csv',
      labelFormat: 'yolo',
      isHuggingFaceFormat: false,
      onlyExportEditedRows: false,
      onlyExportReviewedEdits: false,
      huggingFaceTaggingSchema: 'BIO',
      includeMeta: false
    },
    validate: {
      tableName: (value) =>
        value.indexOf(' ') >= 0 ? "Table name can't include whitespace" : null
    }
  });

  useEffect(() => {
    form.setFieldValue('objectName', defaultObjectName);
  }, [defaultObjectName]);

  const areAllChecked = columnRows.every(({ checked }) => checked);

  const isExportToS3 = destination === 's3';
  const isExportToDatabricks = destination === 'databricks';
  const isExportDataframe = destination === 'dataframe';
  const isTestGCSExport = destination === 'gcs';

  let isDisabled = false;

  if (isExportToS3) {
    isDisabled = Boolean(!form.values.bucketName || !form.values.objectName);
  }

  if (isExportToDatabricks) {
    isDisabled = Boolean(!form.values.tableName);
  }

  const exportDestinations = isGalileoUser.isEnabled
    ? modalityExportDestinations
    : modalityExportDestinations.filter(
        ({ id }) => id !== 'snowflake' && id !== 'gcs'
      );

  const handleCheckboxChange = (id: string, checked: boolean, text: string) => {
    columnRowHandlers.applyWhere(
      (row) => row.accessor === id,
      (row) => ({
        ...row,
        checked,
        renamedValue: text !== row.label ? text : ''
      })
    );
  };

  // Utility Functions
  const toggleAllRows = (e: ChangeEvent<HTMLInputElement>) => {
    columnRowHandlers.apply((row) => ({ ...row, checked: e.target.checked }));
  };

  const handleExport = () => {
    // TODO - Just for a demo, remove after
    if (isTestGCSExport) {
      showNotification({
        title: 'Export successful!'
      });
      return closeAll();
    }
    const colMapping = columnRows.reduce(
      (
        obj: Record<string, string>,
        curr: { accessor: string; renamedValue: string }
      ) => {
        if (curr.renamedValue) {
          obj[curr.accessor] = curr.renamedValue;
        }
        return obj;
      },
      {}
    );

    const includeCols = columnRows
      .filter(({ checked }) => checked)
      // Replace gold or pred with spans for NER
      .map(({ accessor }) =>
        isNer && ['gold', 'pred'].includes(accessor) ? 'spans' : accessor
      );

    if (form.values.includeMeta) {
      includeCols.push('*');
    }

    const {
      bucketName,
      objectName,
      fileType,
      huggingFaceTaggingSchema,
      isHuggingFaceFormat
    } = form.values;

    const fileName = `${objectName}.${fileType}`;

    const schema = isHuggingFaceFormat ? huggingFaceTaggingSchema : '';

    const exportConfig: RemoteExportConfig = {
      ...form.values,
      includeCols,
      colMapping,
      fileName,
      fileType: fileType as FileType,
      huggingFaceTaggingSchema: schema,
      bucketName,
      objectName
    };

    exportTo.mutate(
      {
        exportConfig,
        isEditsExport,
        remote: isExportToS3 ? 's3' : undefined,
        isExportToDatabricks
      },
      {
        onSuccess: () => closeAll()
      }
    );
  };

  const filteredRows = columnRows?.filter(({ label, renamedValue }) =>
    caseInsensitiveMatch([label as string, renamedValue], searchTerm)
  );

  const totalCount = getTotalCount() || 0;
  const filteredCount = getSelectedCount() || 0;
  const onlyExportEditedRows = form.getInputProps('onlyExportEditedRows').value;
  const onlyExportReviewedEdits = form.getInputProps(
    'onlyExportReviewedEdits'
  ).value;

  const handleSampleCount = () => {
    if (onlyExportEditedRows) {
      return onlyExportReviewedEdits ? reviewedCount : editsSampleCount;
    }

    if (isEditsExport && !isInference) {
      return totalCount;
    }

    return filteredCount;
  };

  const countUnits = isNer ? 'spans' : 'samples';

  const exportQuantity = innerProps?.rowId
    ? 1
    : toHumanReadableNumber(handleSampleCount());

  useEffect(() => {
    if (form.values.isHuggingFaceFormat) {
      form.setFieldValue('fileType', 'parquet');
    } else if (isOd) {
      form.setFieldValue('fileType', 'zip');
    } else {
      form.setFieldValue('fileType', 'csv');
    }
  }, [form.values.isHuggingFaceFormat]);

  useEffect(() => {
    form.validateField('tableName');
  }, [form.values.tableName]);

  const handleDestinationClick = (value: string) => {
    setDestination(value as ExportDestinations);
  };

  const selectedAmount = columnRows.filter(({ checked }) => checked)?.length;

  const disableButton =
    isDisabled || isExportDataframe || (!selectedAmount && !areAllChecked);

  useEffect(() => {
    const timer = setTimeout(() => {
      searchRef?.current?.focus();
    }, 250);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Paper data-testid='export-modal' p='md'>
        {!isOd && (
          <>
            <Group justify='space-between' mb={4}>
              <Text c='gray.6' fw={500} size='sm'>
                Select the columns with the data you want to export
              </Text>
            </Group>
            <LiveSearchBar
              autoFocus
              placeholder='Search columns'
              ref={searchRef}
              size='sm'
              onChange={(value) => setSearchTerm(value.toLowerCase())}
            />
            <Checkbox
              checked={areAllChecked}
              disabled={form.values.isHuggingFaceFormat || isExportDataframe}
              indeterminate={columnRows?.some(
                ({ checked }) =>
                  !checked && !columnRows?.every(({ checked }) => !checked)
              )}
              label={
                areAllChecked ? 'Select all' : `Selected (${selectedAmount})`
              }
              mt='xs'
              onChange={toggleAllRows}
            />
            <ScrollArea.Autosize
              mah='15vh'
              mt='xs'
              scrollbarSize={SCROLLBAR_SIZE}
            >
              {filteredRows.map(
                ({ accessor, label, checked, renamedValue }, i) => (
                  <EditableCheckbox
                    checked={checked}
                    disabled={
                      form.values.isHuggingFaceFormat ||
                      isExportDataframe ||
                      (isNer && fixedNerColumns.includes(accessor))
                    }
                    id={accessor}
                    key={`${accessor}-${i}`}
                    label={renamedValue || (label as string)}
                    secondaryText={
                      <Text
                        c='dimmed'
                        className={cx(
                          'italic',
                          (!renamedValue || renamedValue === label) &&
                            'display-none'
                        )}
                        size='xs'
                      >
                        Renamed from {label}
                      </Text>
                    }
                    onCheckboxChange={handleCheckboxChange}
                  />
                )
              )}

              {!filteredRows.length && (
                <Center>
                  <Text c='dimmed'>No columns found for that search.</Text>
                </Center>
              )}
            </ScrollArea.Autosize>
          </>
        )}
        <Checkbox
          disabled={form.values.isHuggingFaceFormat || isExportDataframe}
          label='Include metadata columns'
          mt='xs'
          {...form.getInputProps('includeMeta', {
            type: 'checkbox'
          })}
        />
        {isEditsExport && (
          <>
            <Switch
              label='Only export edited rows'
              mt='sm'
              size='md'
              {...form.getInputProps('onlyExportEditedRows', {
                type: 'checkbox'
              })}
            />
            <Switch
              label='Only export reviewed edits'
              mt='xs'
              size='md'
              {...form.getInputProps('onlyExportReviewedEdits', {
                type: 'checkbox'
              })}
            />
          </>
        )}
        <Text c='gray.6' fw={500} mb='sm' mt='lg' size='sm'>
          Choose export destination
        </Text>
        <Group>
          {exportDestinations.map(({ label, icon, id }) => (
            <RadioWithIcon
              checked={destination === id}
              icon={icon}
              id={id}
              key={label}
              label={label}
              onRadioClick={(value) => handleDestinationClick(value)}
            />
          ))}
        </Group>

        <ExportModalForm
          destination={destination}
          form={form}
          fromEditsCart={!!innerProps?.fromEditsCart}
        />
      </Paper>
      <Box
        p='md'
        style={{
          background: 'var(--mantine-color-gray-1)',
          borderTop: `1px solid var(--mantine-color-gray-2)`
        }}
      >
        <Group justify='flex-end'>
          <Button
            data-testid='export-modal-cancel-btn'
            variant='subtle'
            onClick={() => closeAll()}
          >
            Cancel
          </Button>
          <Button
            data-testid='export-modal-export-btn'
            disabled={disableButton}
            loading={exportTo.isPending}
            radius='md'
            onClick={handleExport}
          >
            Export ({exportQuantity} {countUnits})
          </Button>
        </Group>
        {exportTo.isPending &&
          (form.values.isHuggingFaceFormat || isExportToDatabricks) && (
            <Text c='dimmed' mt='xs' size='xs' ta='right'>
              Hang tight! This might take some time.
            </Text>
          )}
      </Box>
    </>
  );
};

export default ExportModal;
