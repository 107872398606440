import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import { HUMAN_FEEDBACK_TEMPLATES_PATH } from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';

import FeedbackTemplate, {
  FeedbackTemplateInputs
} from '../../../classes/feedback-template/feedback-template';

type GetFeedbackTemplateResp = components['schemas']['FeedbackTemplateDB'];

export const useGetFeedbackTemplates = (runId?: string) => {
  // Path Params
  const { projectId } = usePathParameters();

  // Options
  const enabled = Boolean(projectId);

  // Action
  const getTemplates = async () => {
    if (!projectId) {
      return Promise.reject(`Invalid projectId: ${projectId}`);
    }

    return api
      .GET(HUMAN_FEEDBACK_TEMPLATES_PATH, {
        params: {
          path: {
            project_id: projectId
          }
        }
      })
      .then((res) => {
        return {
          ...res.data,
          templates:
            res?.data
              ?.map((template) => {
                const inputs = toFeedbackTemplateInputs(template);
                const feedbackTemplate = new FeedbackTemplate(inputs);
                if (runId) {
                  feedbackTemplate.setKey(runId);
                }
                return feedbackTemplate;
              })
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              ) ?? []
        };
      })
      .catch((err) =>
        defaultErrorHandler(err, `Unable to get feedback templates`)
      );
  };

  return useQuery({
    queryKey: [HUMAN_FEEDBACK_TEMPLATES_PATH, { projectId, runId }],
    queryFn: getTemplates,
    enabled
  });
};

export const toFeedbackTemplateInputs = (
  input: GetFeedbackTemplateResp | undefined
): FeedbackTemplateInputs<any> => {
  if (input == null) {
    throw new Error('Invalid input: null');
  }

  return {
    name: input.name,
    withExplanation: input.include_explanation,
    criteria: input.criteria ?? undefined,
    constraints: input.constraints,
    id: input.id,
    createdAt: input.created_at,
    createdBy: input.created_by ?? undefined
  };
};
