import { useSessionStorage } from '@mantine/hooks';

import { FlagTags } from '@/core/types/feature-flags.types';

export const useABFlags = () => {
  const [bulkHumanFeedbackFlag, setBulkHumanFeedbackFlag] = useSessionStorage({
    key: 'bulk_human_feedback_flag',
    defaultValue: false
  });
  const [customMetricsFlag, setCustomMetricsFlag] = useSessionStorage({
    key: 'custom_metrics',
    defaultValue: false
  });
  const [datasetsFlag, setDatasetsFlag] = useSessionStorage({
    key: 'datasets_flag',
    defaultValue: false
  });

  return {
    bulkHumanFeedback: {
      description: 'Toggle Bulk Annotations.',
      isEnabled: bulkHumanFeedbackFlag,
      tag: [FlagTags.Feature],
      title: 'Bulk Annotations',
      toggle: () => setBulkHumanFeedbackFlag(!bulkHumanFeedbackFlag)
    },
    customMetrics: {
      description: 'Toggle Custom Metrics.',
      isEnabled: customMetricsFlag,
      tag: [FlagTags.Feature],
      title: 'Custom Metrics',
      toggle: () => setCustomMetricsFlag(!customMetricsFlag)
    },
    datasets: {
      description: 'Toggle Datasets.',
      isEnabled: datasetsFlag,
      tag: [FlagTags.Feature],
      title: 'Datasets',
      toggle: () => setDatasetsFlag(!datasetsFlag)
    }
  };
};
