import { Group, GroupProps, Radio, RadioProps } from '@mantine/core';
import { PropsWithChildren } from 'react';

import classes from './radio-card.module.css';

interface RadioCardProps extends GroupProps {
  radioProps?: RadioProps;
  variant?: 'default';
  onRadioClick?: (val: string) => void;
}

export const RadioCard = ({
  children,
  radioProps,
  variant = 'default',
  onRadioClick,
  ...groupProps
}: PropsWithChildren<RadioCardProps>) => {
  const variantMap = {
    default: classes.radioCard
  };

  let extra_styles = {
    ...radioProps?.styles
  };
  if (radioProps?.checked && radioProps?.disabled) {
    extra_styles = {
      label: { color: 'var(--mantine-color-gray-9)', opacity: 1 }
    };
  }

  return (
    <Group
      aria-selected={radioProps?.checked}
      className={variantMap[variant]}
      justify='apart'
      onClick={() => onRadioClick && onRadioClick(radioProps?.value as string)}
      {...groupProps}
    >
      <Radio {...radioProps} styles={extra_styles} />
      {children}
    </Group>
  );
};
