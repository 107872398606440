import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import { useEffect } from 'react';

import { useCurrentUser } from '@/core/hooks/query-hooks/use-current-user/use-current-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';

const PostHogProvider = ({
  children,
  posthogApiKey
}: {
  children: React.ReactNode;
  posthogApiKey?: string;
}) => {
  const { posthog: posthogFlag } = useFeatureFlags();
  const router = useRouter();
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (
      posthogFlag.isEnabled &&
      posthogApiKey &&
      typeof window !== 'undefined'
    ) {
      posthog.init(posthogApiKey, {
        api_host: 'https://us.i.posthog.com'
      });
    }
  }, [posthogFlag.isEnabled, posthogApiKey]);

  useEffect(() => {
    if (posthogFlag.isEnabled) {
      if (user?.id) {
        if (!posthog?._isIdentified()) {
          posthog?.identify(user.id);
          // Track page views
          const handleRouteChange = () => posthog?.capture('$pageview');
          router.events.on('routeChangeComplete', handleRouteChange);

          return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
          };
        }
      } else {
        posthog?.reset();
      }
    }
  }, [posthog, user?.id, posthogFlag.isEnabled]);

  return <PHProvider client={posthog}>{children}</PHProvider>;
};

export default PostHogProvider;
