import { Group, Text } from '@mantine/core';

import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import { FeedbackType } from '@/core/types/human-feedback.types';

import { InfoTooltip } from '../../atoms/info-tooltip/info-tooltip';

const HumanFeedbackLabel = ({
  metric
}: {
  metric: FeedbackTemplate<FeedbackType>;
}) => {
  return (
    <Group align='center' data-testid='human-feedback-label' gap={0}>
      <Text
        c='gray.6'
        size='sm'
        style={{
          fontWeight: '600'
        }}
      >
        {metric.name}
      </Text>
      {metric?.criteria && <InfoTooltip label={metric?.criteria} />}
    </Group>
  );
};

export default HumanFeedbackLabel;
