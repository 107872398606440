import { Box, Button, Divider, Group, Text } from '@mantine/core';
import {
  IconChartDots3,
  IconCurrencyDollar,
  IconGavel,
  IconSparkles,
  IconWand
} from '@tabler/icons-react';

import { Modals, useModals } from '@/core/hooks/use-modals/use-modals';

import CopyBox from '../copy-box/copy-box';

const MetricExplanationBody = ({
  explanation,
  improvable,
  message,
  rationale,
  rationaleLabel = 'Generated Reasoning',
  cost,
  model,
  metricData,
  judges,
  formatCost
}: {
  message?: string | null;
  explanation: string | null | undefined;
  rationale: string | null | undefined;
  rationaleLabel?: string;
  cost: number | null | undefined;
  model?: string | null;
  judges?: number | null;
  metricData?: {
    name: string;
    value: number;
    formattedValue?: string | number | JSX.Element;
    label: string;
    rationale: string;
    color: string;
    inverted?: boolean;
    rowId: string;
  };
  improvable?: boolean;
  formatCost?: (cost: number) => string | number | JSX.Element;
}) => {
  const { openModal } = useModals();
  return (
    <>
      {message && (
        <>
          <CopyBox
            copyText={message}
            data-testid='metric-message'
            mah='150px'
            px='md'
            py='sm'
            style={{ overflowY: 'auto' }}
          >
            <Text size='sm'>{message}</Text>
          </CopyBox>
        </>
      )}
      {explanation && (
        <CopyBox
          copyText={explanation}
          data-testid='metric-explanation'
          mah='150px'
          px='md'
          py='sm'
          style={{ overflowY: 'auto' }}
          variant='dark'
        >
          <Text size='sm'>{explanation}</Text>
        </CopyBox>
      )}
      {rationale && (
        <>
          <Divider />
          <CopyBox
            copyText={rationale}
            data-testid='metric-rationale'
            mah='150px'
            style={{ overflowY: 'auto' }}
          >
            <Group gap='0.5rem' px='md' py='sm'>
              <IconWand size='1rem' />
              <Text size='sm'>{rationaleLabel}</Text>
            </Group>
            <Text pb='sm' px='md' size='sm'>
              {rationale}
            </Text>
          </CopyBox>
        </>
      )}
      {improvable && (
        <Box p='sm'>
          <Text
            bg='gray.9'
            c='white'
            fw={400}
            px='xs'
            py={6}
            size='xs'
            style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            If the metric seems incorrect, submit your feedback and Galileo will
            auto-improve the metric.
          </Text>
          <Button
            fullWidth
            color='brand.5'
            leftSection={<IconSparkles color='white' size={18} />}
            radius={0}
            style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
            variant='filled'
            onClick={() => openModal(Modals.CHAINPOLL_FEEDBACK, { metricData })}
          >
            Auto-improve Metric
          </Button>
        </Box>
      )}
      {(cost || model || judges) && (
        <>
          <Group gap='0.25rem' px='md' py='xs'>
            {model && (
              <Group data-testid='metric-model' gap='0.25rem' wrap='nowrap'>
                <IconChartDots3 size='1rem' />
                <Text truncate maw='15rem' size='sm'>
                  {model}
                </Text>
              </Group>
            )}
            {cost && (
              <Group data-testid='metric-cost' gap='0.5rem'>
                <IconCurrencyDollar size='1rem' />
                <Text size='sm'>
                  {formatCost != null ? formatCost(cost) : cost}
                </Text>
              </Group>
            )}

            {judges && (
              <Group data-testid='metric-judges' gap='0.5rem'>
                <IconGavel size='1rem' />
                <Text size='sm'>
                  {judges} {`Judge${+judges > 1 ? 's' : ''}`}
                </Text>
              </Group>
            )}
          </Group>
        </>
      )}
    </>
  );
};

export default MetricExplanationBody;
