import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';
import { PROJECT_RUN_PATH } from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';

export const useCurrentRun = () => {
  const { runId, projectId } = usePathParameters();

  const fetchRun = async () => {
    const res = await api.GET(PROJECT_RUN_PATH, {
      params: {
        path: {
          project_id: projectId as string,
          run_id: runId as string
        }
      }
    });

    return res?.data;
  };

  const response = useQuery({
    queryKey: [PROJECT_RUN_PATH, { runId }],
    queryFn: () =>
      fetchRun().then((data) => {
        return data;
      }),
    enabled: Boolean(projectId && runId)
  });

  return {
    ...response,
    runHasTrainingSplit: response.data?.logged_splits?.includes('training')
  };
};
