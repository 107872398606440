import { useQuery } from '@tanstack/react-query';

import api from '@/core/api';

import { useIntegration } from '../use-integration/use-integration';

export const GET_DATABRICKS_DATABASES = '/integrations/databricks/databases';

export const useDatabricksDatabases = (clusterId?: string) => {
  const { data } = useIntegration('databricks');

  const fetchClusters = async () => {
    const resp = await api.GET(GET_DATABRICKS_DATABASES, {
      params: {
        query: {
          cluster_id: clusterId!
        }
      }
    });

    return resp?.data;
  };

  const response = useQuery({
    queryKey: [GET_DATABRICKS_DATABASES, clusterId],
    queryFn: fetchClusters,
    enabled: !!data?.name && !!clusterId
  });

  return response;
};
