import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '@/core/api';
import { defaultErrorHandler } from '@/core/api/utils';
import FeedbackTemplate from '@/core/classes/feedback-template/feedback-template';
import {
  EVALUATE_COLUMNAR_RUN_PATH,
  EVALUATE_COLUMNAR_RUNS_PATH,
  HUMAN_FEEDBACK_TEMPLATE_PATH,
  HUMAN_FEEDBACK_TEMPLATES_PATH
} from '@/core/constants/api.constants';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { FeedbackType } from '@/core/types/human-feedback.types';

type EditFeedbackTemplateBody = {
  templateId: string;
  name: string;
  criteria: string | null;
};

export const useEditFeedbackTemplate = () => {
  // Path Params
  const { projectId } = usePathParameters();

  // Query Client
  const queryClient = useQueryClient();

  // Action
  const editTemplate = async ({
    templateId,
    name,
    criteria
  }: EditFeedbackTemplateBody) => {
    if (name == null) {
      return Promise.reject('Invalid body');
    }

    const res = await api
      .PATCH(HUMAN_FEEDBACK_TEMPLATE_PATH, {
        params: {
          path: {
            project_id: projectId!,
            template_id: templateId
          }
        },
        body: {
          name,
          criteria
        }
      })
      ?.then((res) => {
        queryClient.invalidateQueries({
          queryKey: [HUMAN_FEEDBACK_TEMPLATES_PATH]
        });
        queryClient.invalidateQueries({
          queryKey: [EVALUATE_COLUMNAR_RUN_PATH]
        });
        queryClient.invalidateQueries({
          queryKey: [EVALUATE_COLUMNAR_RUNS_PATH]
        });
        return res?.data;
      })
      ?.catch((error) => {
        defaultErrorHandler(error, `Unable to update annotation configuration`);
      });

    return res;
  };

  return useMutation({
    mutationFn: editTemplate
  });
};

export const templateToPatchBody = (
  template: FeedbackTemplate<FeedbackType>
) => {
  return {
    templateId: template.id as string,
    name: template.name ?? undefined,
    criteria:
      typeof template.criteria === 'string' && template.criteria.length > 0
        ? template.criteria
        : null
  };
};
